/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: NCI RequestsView
 *
 * Purpose: NCI View of User Requests
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Nov2023 sasjxa file created
 * 18Dec2023 craig  changed url
 * 21Dec2023 craig  Added update to user session on change
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 14Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 21May2024 sasjxa correct name in hyperlink
 * 02Jul2024 craig  removed decode (moved to api)
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 01Oct2024 sasjxa remove table border
 * 04Oct2024 craig  fixed bug, url was not right to contribution
 * 01Nov2024 craig  cleaned up warnings
 * 02Nov2024 craig  Merged with main
 * 17Dec2024 sasjxa fix column alignment issue
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Button, Col, Container, Spinner, Table} from "react-bootstrap";
// @ts-ignore
import styled from 'styled-components';

import {ContentPage, PageWrapper, PDS_H5} from "../../components/styled/StyledComponents";
import "../forms/forms.css";
import {FormList, NCI_ACTIONS, NCI_NOT_SUBMITTED} from "../../data/formList";
import {Link, useNavigate} from "react-router-dom";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {
    INciDataRequestTrialNumbers,
    INciRequestCart,
    INciRequestData,
    INciRequestFullCart
} from "../../model/user/IUser";
import {useRequest} from "../../helper/useRequest";
import {updateNciCart} from "../../context/context";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {usePDSContext} from "../../context/PDSContext";


const CurrentItemsTable = styled(Table)`
    border: 0;

    th, td {
        font-size: ${FONT_SIZES.px14};
        vertical-align: top;
        border-bottom: 1px solid ${COLORS.light_grey};
        padding: 1% 0;
    }

    th {
        padding-left: 0;
    }
    
    th, td.first {
        width: 20%;
    }
    
    td.clinTrial {
        padding-left: 4%;
    }
`;

const FinalizeButton = styled(Button)`
    background-color: ${COLORS.turtle_green};
    border-radius: 0 !important;

`;

const RemoveButton = styled(Button)`
    background-color: ${COLORS.remove_red} !important;
    border-radius: 0 !important;
    color: white !important;

    &:hover {
        background-color: ${COLORS.hover_remove_red};
    !important;
    }
;
`;

const ContinueButton = styled(Button)`
    background-color: ${COLORS.turtle_green} !important;
    border-radius: 0 !important;
`;

export const NCIRequestsView: React.FC = () => {
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const {setSessionUser} = usePDSContext();

    const [userCart, setUserCart] = useState<INciRequestFullCart[]>([]);
    const [userRequests, setUserRequests] = useState<INciRequestData[]>([])
    const [selectedDonationId, setSelectedDonationId] = useState<number>(-1);
    const [removeUrl, setRemoveUrl] = useState<string>("");

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // item removed from user's cart
    const handleRemoveSuccess = (nciRequestCart: INciRequestFullCart[]) => {
        if (nciRequestCart !== null) {
            setSessionUser(updateNciCart(nciRequestCart as INciRequestCart[]));
            setUserCart(nciRequestCart);
        }
    }

    // get data
    const [requestActionState, getNciRequests] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/nci/requests/user/items",
        method: "get",
        withCredentials: true,
        initialIsLoading: true
    });
    const {isLoading, data, error} = requestActionState;

    /**
     * const for request
     */
    const [removeState, setRemoveAction] = useRequest({
        url: removeUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleRemoveSuccess
    });


    // get user cart items upon page load
    useMemo(() => {
        getNciRequests();
    }, []);

    useMemo(() => {
        if (data !== null) {
            setUserCart(data.nciRequestCart);
            setUserRequests(data.nciDataRequest);
        }
    }, [data])


    // memo call if url is changed
    useMemo(() => {
        if (removeUrl !== "") {
            setRemoveAction();
        }
    }, [removeUrl]);


    // memo call if donation id is changed
    useMemo(() => {
        if (selectedDonationId > 0) {
            setRemoveUrl(process.env.REACT_APP_API_URL + "/api/nci/requests/removeItem/" + selectedDonationId);
        }
    }, [selectedDonationId]);

    // returns requests that have been submitted or are in progress
    const getInProgressRequests = (requestStatus: number) => {
        if (userRequests != null && userRequests.length > 0) {
            return userRequests.filter(item => item.requestStatus === requestStatus
                && !item.submitted);

        } else {
            return new Array<INciRequestData>();
        }
    }

    const getSubmittedRequests = () => {
        if (userRequests != null && userRequests.length > 0) {
            return userRequests.filter(item => item.submitted);

        } else {
            return new Array<INciRequestData>();
        }
    }

    // return nct id
    const getNCTId = (nctId: INciDataRequestTrialNumbers) => {
        return (
            <div className="ps-0">{nctId.nctTrialNumber}</div>
        )
    }

    // format status field
    const getNCIStatus = (status: number) => {
        let item: FormList | undefined = NCI_ACTIONS.find(s => s.value === status.toString());
        if (item !== undefined) {
            return item?.label;
        }
    }

    const buildCurrentRequests = () => {
        if (userCart.length > 0) {
            return (
                <>
                    <Col className="">
                        <PDS_H5 className="mt-2 mb-1">Current Items </PDS_H5>
                        <CurrentItemsTable>
                            <thead>
                            <tr>
                                <th className="first">ClinicalTrial.gov ID</th>
                                <th className="second">Description</th>
                                <th>Number of Clinical Trials</th>
                                <th className="last">Remove
                                </th>
                            </tr>
                            </thead>
                            {userCart.map(c => (
                                <tbody>
                                <tr>
                                    <td className="first"><a href={BASE_PATH + "content/" + c.donationId}
                                                             title="Go To Study"
                                                             className="study-contents  left result-info-link">{c.nctTrialNumber}</a>
                                    </td>
                                    <td className="second">{c.description}</td>
                                    <td className="clinTrial">{c.numDatasets}</td>
                                    <td className="last">
                                        <RemoveButton variant={"success"} className="" onClick={() => {
                                            setSelectedDonationId(c.donationId)
                                        }}>Remove</RemoveButton>
                                    </td>
                                </tr>
                                </tbody>
                            ))}
                        </CurrentItemsTable>
                        <Link to={BASE_PATH + "nciRequest"}>
                            <FinalizeButton variant="success" size="lg" className=" mb-4">Finalize
                                Request</FinalizeButton>
                        </Link>

                    </Col>

                </>
            )
        } else {
            return (
                <>
                    <Col className="">
                        <PDS_H5 className="mt-2 mb-1">Current Items </PDS_H5>
                        <p>You have not added any datasets to your request. Go to the
                            <a href={BASE_PATH + "access"}> Access Data</a> page to review
                            available datasets.</p>
                    </Col>
                </>
            )
        }
    }

    const buildInProgressRequests = () => {
        let items: INciRequestData[] = getInProgressRequests(NCI_NOT_SUBMITTED);
        if (items != null && items.length > 0) {
            return (
                <>
                    <Col className="">
                        <PDS_H5 className="mt-5 mb-1 ">Requests In Progress </PDS_H5>
                        <span className="">Below are the data requests that you started, but have not yet submitted. You can return to an incomplete data request using the button adjacent to the request.</span>
                        <CurrentItemsTable>
                            <thead>
                            <tr>
                                <th className="first">Date</th>
                                <th className="second">Research Title</th>
                                <th>Requested Dataset(s)</th>
                                <th className="last">Complete Request
                                </th>
                            </tr>
                            </thead>

                            {items.map(request => (
                                <tbody>
                                <tr>
                                    <td className="first">
                                        {new Date(request.createdAt).toLocaleDateString('en-us', {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric"
                                        })
                                        }
                                    </td>
                                    <td className="second">{request?.researchPlanTitle}
                                    </td>
                                    <td>
                                        <Col>
                                            {
                                                request?.trialNumbers?.map((nctId: INciDataRequestTrialNumbers) => {
                                                    return (
                                                        getNCTId(nctId)
                                                    );
                                                })
                                            }
                                        </Col></td>
                                    <td className="last"><ContinueButton variant={"success"}
                                                                         className=""
                                                                         onClick={() => {
                                                                             navigate(BASE_PATH + "nciRequest/" + request.id)
                                                                         }}
                                    >Continue</ContinueButton></td>

                                </tr>
                                </tbody>
                            ))}
                        </CurrentItemsTable>

                    </Col>

                </>
            )
        }
    }

    const buildSubmittedRequests = () => {
        let items: INciRequestData[] = getSubmittedRequests();
        if (items != null && items.length > 0) {
            return (
                <>
                    <Col className="">
                        <PDS_H5 className="mt-5 mb-1 ">Submitted Requests </PDS_H5>
                        <CurrentItemsTable>
                            <thead>
                            <tr>
                                <th className="first">Date</th>
                                <th className="second">Research Title</th>
                                <th className="requested">Requested Dataset(s)</th>
                                <th className="last">Status
                                </th>
                            </tr>
                            </thead>
                            {items.map(request => (
                                <tbody>
                                <tr>
                                    <td className="first">
                                        {new Date(request.createdAt).toLocaleDateString('en-us', {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric"
                                        })
                                        }
                                    </td>
                                    <td className="second">{request?.researchPlanTitle}
                                    </td>
                                    <td className="requested">
                                        <Col>
                                            {
                                                request?.trialNumbers?.map((nctId: INciDataRequestTrialNumbers) => {
                                                    return (
                                                        getNCTId(nctId)
                                                    );
                                                })
                                            }
                                        </Col></td>
                                    <td className="last">{getNCIStatus(request.requestStatus)}</td>
                                </tr>
                                </tbody>
                            ))}
                        </CurrentItemsTable>

                    </Col>

                </>
            )
        }
    }

    const buildAll = () => {
        return (
            <>
                {buildCurrentRequests()}
                {buildInProgressRequests()}
                {buildSubmittedRequests()}
            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col>
                        {isLoading &&
                            <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {data && buildAll()}
                    </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
