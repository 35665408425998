/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Login
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 21Mar2023 craig  file created
 * 18Jul2023 craig  use the LoginForm
 * 19Dec2023 sasjxa change message
 * 20Mar2024 sasjxa eliminate show/hide error (MPM-5414)
 * 15Jan2025 craig  Changed from old axios to new API call
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Container} from "react-bootstrap";
// @ts-ignore
import {LoginForm} from "../../../components/forms/LoginForm";
import {useSearchParams} from "react-router-dom";
import {ContentPage, FormLayoutColumn, PageWrapper} from "../../../components/styled/StyledComponents";

export const Login: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loginMsg, setLoginMsg] = useState<string | undefined>("");

    /*
       useMemo - check if error is in search params and true and then set error msg
                 This is because the user will be redirected from the login dialog to here
     */
    useMemo(() => {
        if (searchParams.get("error") === 'true') {
            setLoginMsg("You have entered an incorrect username/password.");
        } else if (searchParams.get("internalerror") === 'true') {
            setLoginMsg("There was an error authenticating your account, PDS has been notified and someone will respond shortly.");
        }
    }, [])

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"registrationColumn"}>
                        <div className={"form-error-msg"}>{loginMsg}</div>
                        <LoginForm/>
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );
}