/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ContributionContents
 *
 * Purpose: Display study results for individual donation
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 26Feb2024 sasjxa file created
 * 04Apr2024 craig  switched to react-error-boundary
 * 11Jun2024 craig  removed user prop to AccessDataDetailsAndFiles
 * 28Jun2024 sasjxa removed showBoundary
 * 21Aug2024 craig  cleaned up
 * 03Jan2025 craig  Added useMemo for sessionUser to catch login
 * 15Jan2025 craig  switched to new PDS API calls
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {Col, Container} from "react-bootstrap";
import {ContentPage, PageWrapper,} from "../../components/styled/StyledComponents";

import {AccessDataAside} from "../../components/AccessDataAside";
import {useParams} from "react-router-dom";
import {IAccessData} from "../../model/donations/IAccessData";
import {ContributionContentStudies} from "../../components/access/ContributionContentsStudies";
import {usePDSContext} from "../../context/PDSContext";
import {getPDSData} from "../../utilities/axiosClient/axiosClientUtility";
import {AxiosError} from "axios";
import {IPDSErrorResponse} from "../../model/error/IPDSErrorResponse";


export const ContributionContents: React.FC = () => {
    const {donationId} = useParams();
    const {sessionUser} = usePDSContext();
    const [donation, setDonation] = useState<IAccessData | null>(null);
    const donationURL = process.env.REACT_APP_API_URL + "/api/accessdata/results/" + donationId;
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<AxiosError<IPDSErrorResponse> | null>(null);


    /**
     * called when the session user changes so we can get download links
     */
    useMemo(() => {
        const getAccessData = async () => {
            const {data, error} = await getPDSData<IAccessData>(donationURL);
            if (error) {
                setError(error);
            } else {
                setError(null);
                setLoading(false);
                setDonation(data);
            }
        }
        getAccessData();
    }, [sessionUser]);

    /**
     * build the results
     */
    const buildResults = () => {
        return (
            <>
                <Col className="" md={4} lg={2}>
                </Col>

                {donation !== undefined && donation !== null && (
                    <Col className="mt-4 me-4" md={6} lg={8} name={"registrationColumn"}>
                        <ContributionContentStudies donation={donation}/>

                    </Col>
                )}

                <Col md={4} lg={2}>
                    <AccessDataAside/>
                </Col>
            </>
        )
    }

    /**
     * return the html for the page
     */
    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    {loading && <p>Loading ...</p>}
                    {error && <p>Error - {error?.response?.data?.detail}</p>}
                    {donation && buildResults()}
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}