/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: context
 *
 * Purpose: context for the app
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 06Feb2023 craig File created.
 * 19Dec2023 craig Added NCI cart/requests update methods
 * 04Jan2024 craig Added empty short username to guest
 * 23Jan2024 craig Added expired and expiredKey to guest
 * 16Aug2024 craig removed jwt from IUser guest
 * 21Aug2024 craig cleaned up
 * 20Dec2024 sasjxa added nciPendingDataRequests
 \******************************************************************************/
import {INciRequestCart, INciRequestData, IUser, PDSGroup} from "../model/user/IUser";

export const sessionName = "pds-session-user";

/**
 * set the session cookie
 * @param session
 */
export const setSessionCookie = (session: any): void => {
    sessionStorage.clear();
    sessionStorage.setItem(sessionName, JSON.stringify(session));
};

/**
 * get the session cookie
 */
export const getSessionCookie: any = () => {
    const sessionCookie = sessionStorage.getItem(sessionName);
    if (sessionCookie === 'undefined' || sessionCookie === null) {
        //create a sesson on the backend
        return getGuestCookie();
    } else {
        //verify that the session cookie is valid
        return JSON.parse(sessionCookie);
    }
};

export const getGuestCookie: any = () => {
    let oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    const guestUser: IUser = {
        userID: -1,
        name: "Guest",
        username: "foo@foo.foo",
        shortUsername: "",
        phone: "123456789",
        pdsGroup: PDSGroup.Guest,
        passwordExpires: oneYearFromNow,
        userExpiration: {
            expirationDt: oneYearFromNow,
            randomKey: "",
            expired: false,
            reactivated: false,
            daysToExpired: 90
        },
        expiredKey: "",
        attested: false,
        pdsMember: false,
        serviceAccount: false,
        nciRequestCart: [],
        nciDataRequests: [],
        nciPendingDataRequests:[]
    }
    setSessionCookie(guestUser);
    return guestUser;
}

/**
 * update the users NCI cart
 *
 * @param nciRequestCart
 */
export const updateNciCart = (nciRequestCart: INciRequestCart[]) => {
    let tmpCart: INciRequestCart[] = [];
    nciRequestCart.map((c) => (
        tmpCart.push({userId: c.userId, donationId: c.donationId, nctTrialNumber: c.nctTrialNumber})
    ));
    let sessionUser = getSessionCookie();
    sessionUser.nciRequestCart = tmpCart;
    setSessionCookie(sessionUser);
    return sessionUser;
}

/**
 * update the users nci requests
 *
 * @param NCIRequests
 */
export const updateNciRequests = (NCIRequests: INciRequestData[]) => {
    let tmpRequests: INciRequestData[] = [];
    NCIRequests.map((r: INciRequestData) => {
        tmpRequests.push({
            id: r.id,
            researchPlanTitle: r.researchPlanTitle,
            submitted: r.submitted,
            requestStatus: r.requestStatus,
            createdAt: r.createdAt,
            trialNumbers: r.trialNumbers
        });
    });
    let sessionUser = getSessionCookie();
    sessionUser.nciDataRequests = tmpRequests;
    setSessionCookie(sessionUser);
    return sessionUser;
}

