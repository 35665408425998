/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: TicketEventsAndContactUs
 *
 * Purpose: displays ticket events and contact us
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 18Apr2024 sasjxa file created
 * 23May2024 sasjxa add decoding for contact us comment
 * 02Jul2024 craig  removed decode (moved to api)
 * 10Aug2024 craig  added parse to comments
 * 30Sep2024 sasjxa remove table border
 * 23Jan2025 craig  added key to row
 \******************************************************************************/
import React from "react";

import {Row, Table} from "react-bootstrap";
import styled from "styled-components";
import {ITicket, ITicketEvent} from "../../model/tickets/ITickets";
import {AdminTableHeader} from "../styled/StyledComponents";
import {getTicketStatus, getTicketSubject} from "../../constants/CommonFunctions";
import {FONT_SIZES} from "../styled/StyleConstants";
import parse from "html-react-parser";

const AdminTicketActionTable = styled(Table)`
  border: 0;  
  th, td {
    font-size: ${FONT_SIZES.px14};
    vertical-align: top;
    padding: .5% 0;
  }

  th {
    padding-bottom: 0;
  }

  th.normal, td.normal {
    width: 25%;
    padding-left: 1%;
  }

  th.small, td.small {
    width: 20%;
    padding-left: 1%;
  }
`;


interface TicketEventsAndContactUsProps {
    ticket: ITicket | undefined,
}

export const TicketEventsAndContactUs: React.FC<TicketEventsAndContactUsProps> = ({
                                                                                ticket

                                                                            }: TicketEventsAndContactUsProps) => {


    // return user first and last name
    const getUserName = (ticket: ITicket, ticketEvent: ITicketEvent) => {
        if (ticketEvent?.user != null) {
            return ticketEvent?.user?.userDetails?.first + " " + ticketEvent.user?.userDetails?.last;

        } else if (ticket?.contactUs != null) {
            return ticket?.contactUs?.first + " " + ticket?.contactUs?.last;
        }
    }

    const buildEvents = () => {
        return (
            <>
                <Row><AdminTableHeader className="ms-3">Previous Events</AdminTableHeader></Row>
                <AdminTicketActionTable borderless className="w-100 ms-3">
                    <thead>
                    <tr>
                        <th className="w-25">Status</th>
                        <th className="w-25">Name</th>
                        <th className="small">Date</th>
                        <th className="">Comments</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ticket?.techSupportEvents?.map((ticketEvent: ITicketEvent) => (
                        <tr key={ticketEvent.id}>
                            <td className="w-25">{getTicketStatus(ticketEvent.event)}</td>
                            <td className="w-25"> {getUserName(ticket, ticketEvent)}</td>
                            <td className="small"> {new Date(ticketEvent.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}</td>
                            <td className="">{ticketEvent.comment === null ? "" : parse(ticketEvent.comment)}</td>
                        </tr>
                    ))}
                    </tbody>
                </AdminTicketActionTable>
            </>
        )

    }

    const buildContactUs = () => {
        return (
            <>
                <Row><AdminTableHeader className="mt-5 ms-3">Contact Us</AdminTableHeader></Row>
                <AdminTicketActionTable borderless className="w-100 ms-3">
                    <thead>
                    <tr>
                        <th className="w-25">Name</th>
                        <th className="w-25">Email</th>
                        <th className="small">Subject</th>
                        <th className="">Message</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className="w-25">{ticket?.contactUs?.first + " " + ticket?.contactUs?.last}</td>
                        <td className="w-25">{ticket?.contactUs?.email}</td>
                        <td className="small">{getTicketSubject(ticket)}</td>
                        <td className="">{ticket?.contactUs?.userComment == null ? "" : ticket?.contactUs?.userComment}</td>
                    </tr>
                    </tbody>
                </AdminTicketActionTable>
            </>
        )
    }

    return (
        <>
            {buildEvents()}
            {buildContactUs()}

        </>

    );

}
