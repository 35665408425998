/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Resources
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig  file created.
 * 02Jun2023 sasjxa move styled components out of FC
 * 04Aug2023 sasjxa layout changes
 * 04Dec2024 sasjxa add additional resources
 * 28Dec2024 sasjxa change url to use public controller method
 \******************************************************************************/
import React, {Fragment, useMemo, useState} from "react";
import {ContentPage, PageWrapper, PDS_H5, PDS_P} from "../../components/styled/StyledComponents";
import {COLORS} from "../../components/styled/StyleConstants";
// @ts-ignore
import styled from 'styled-components';
import {
    CYTEL_VIDEO_YOU_TUBE,
    DATA_PROVIDERS,
    EXTERNAL_LINK_TEXT,
    GENERAL_RESOURCES,
    IResourceLink,
    SAS_TOOLS
} from "../../data/resources/resources";

import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {Col, Row, Spinner} from "react-bootstrap";
import {Aside} from "../../components/Aside";
import {useRequest} from "../../helper/useRequest";
import {IAdminAdditionalResource} from "../../model/announcements/IAdminAdditionalResource";

const ResourcesContainer = styled.div`
    display: flex;
    flex-direction: column;

`;

const ResourcesSection = styled.div`
    padding-right: 0px;
    margin-top: 2%;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 5%;
    }

    p.externalLinks {
        margin-top: 0;
    }

    @media (min-width: 1024px) {
        p {
            margin-top: 0;
        }

        &:not(:last-child) {
            margin-bottom: 1%;
        }
    }
`;

const ResourcesRow = styled.div`
    margin-bottom: 1%;

    @media (min-width: 768px) {
        p {
            margin-top: 0;
        }
    }
`;

const ResourcesLink = styled(Link)`
    font-size: 1rem;
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
`;

const VideoContainer = styled.div`
    p:first-of-type {
        margin-top: 0;
    }

    p:last-of-type {
        margin-bottom: 1%;
    }
`;

const InnerVideoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2%;

    @media (min-width: 1024px) {
        margin-bottom: 2%;
    }
`;

const ResourceURLRow = styled(Row)`
    a {
        padding: 0;
    }
`;


export const Resources: React.FC = () => {
    const [additionalResources, setAdditionalResources] = useState<IAdminAdditionalResource[]>([]);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        console.log(error);
    }

    // get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/public/announcements/getAdditionalResources";
    const [requestState, getAdditionalResources] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;


    useMemo(() => {
        getAdditionalResources();
    }, [])

    useMemo(() => {
        if (data != null) {
            setAdditionalResources(data);
        }
    }, [data])

    const buildSection = () => {
        return (
            <>
                <ResourcesContainer className={"resources-container"}>
                    <ResourcesSection className={"resources-section"}>
                        <PDS_H5>General Resources</PDS_H5>
                        {
                            GENERAL_RESOURCES.map((resourceLink: IResourceLink, index: number) => {
                                return <ResourcesRow key={index}>
                                    <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                        {parse(resourceLink.title)} </ResourcesLink>
                                </ResourcesRow>

                            })
                        }

                    </ResourcesSection>
                    <ResourcesSection className={"resources-section"}>
                        <PDS_H5>SAS&reg; Tools</PDS_H5>
                        {
                            SAS_TOOLS.map((resourceLink: IResourceLink, index: number) => {
                                return <ResourcesRow key={index}>
                                    <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                        {parse(resourceLink.title)} </ResourcesLink>
                                </ResourcesRow>

                            })
                        }

                    </ResourcesSection>
                    <ResourcesSection className={"resources-section"}>
                        <PDS_H5>Data Providers</PDS_H5>
                        {
                            DATA_PROVIDERS.map((resourceLink: IResourceLink, index: number) => {
                                return <ResourcesRow key={index}>
                                    <ResourcesLink to={resourceLink.link} target={"_blank"} rel={"noopener noreferrer"}>
                                        {parse(resourceLink.title)} </ResourcesLink>
                                </ResourcesRow>

                            })
                        }

                    </ResourcesSection>
                    <ResourcesSection className={"resources-section"}>
                        <PDS_H5>External Links</PDS_H5>
                        <PDS_P className={"externalLinks"}>
                            {EXTERNAL_LINK_TEXT}
                        </PDS_P>
                    </ResourcesSection>
                    <VideoContainer className={"video-container"}>
                        <PDS_P>{parse(CYTEL_VIDEO_YOU_TUBE.videoText)}</PDS_P>
                        <PDS_P>Read more from the published article, {parse(CYTEL_VIDEO_YOU_TUBE.videoLink)}
                        </PDS_P>
                        <InnerVideoContainer className={"inner-video-container"}>
                            <iframe allowFullScreen={true}
                                    src="https://www.youtube.com/embed/I0FewQzLzkg" width="550px"
                                    frameBorder="0" id="cytel_video"
                                    title="Synthetic Controls in Clinical Trials" className="video"
                                    height="250px"></iframe>

                        </InnerVideoContainer>
                    </VideoContainer>
                    {additionalResources?.map((additionalResource: IAdminAdditionalResource) => (
                        <Col className={"justify-content-start"}>
                            <ResourceURLRow className="">
                                <a href={additionalResource.url}
                                                      target={"_blank"}>{additionalResource.title}</a>
                            </ResourceURLRow>
                            <Row className="mb-3 ">
                                {additionalResource.description}
                            </Row>
                        </Col>

                    ))}
                </ResourcesContainer>

                <Col md={4} lg={3}>
                    <Aside aboutResources={true}/>
                </Col>
            </>
        )
    }


    return (

            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    {isLoading &&
                        <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    {error && <p>Error</p>}
                    {data && buildSection()}

                </PageWrapper>
            </ContentPage>

    )
}