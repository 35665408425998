/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDataFieldEdit
 *
 * Purpose: Edit dataset fields
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 03Oct2023 sasjxa  file created.
 * 04Apr2024 craig   switched to react-error-boundary
 * 09Jul2024 sasjxa  remove references to showBoundary
 * 05Dec2024 sasjxa  rework to new dto'
 * 09Jan2025 craig   added axios error
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {
    AdminEditableRow,
    AdminEditDisplayCol,
    AdminEditRow,
    ContentPage,
    PageWrapper,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row} from "react-bootstrap";


// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";

import * as Yup from "yup";
import {PDSErrorResponse, useRequest} from "../../helper/useRequest";
import {AdminDataFieldEditButtonContainer} from "./AdminDataFieldEditButtonContainer";
import {AxiosError} from "axios";


interface AdminDataFieldProps {
    label: string,
    fieldName: string,
    initialValue: any,
    donationId: any,
    adminEditForm: boolean

};


export const AdminDataFieldEdit: React.FC<AdminDataFieldProps> = ({
                                                                      label, fieldName, donationId,
                                                                      initialValue, adminEditForm
                                                                  }: AdminDataFieldProps) => {


    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [adminEditable, setAdminEditable] = useState<boolean>(adminEditForm);
    const [commentsCount, setCommentsCount] = useState(0);
    const [comments, setComments] = useState<string>("");
    const [singleValue, setSingleValue] = useState(initialValue);
    const maxCommentCount = 1000;

    const handleSuccess = () => {
        setShowEditForm(false);
    }

    const handleError = (error: AxiosError<PDSErrorResponse>) => {
        console.log(error);
    }

    // create the file log
    const createLogEntry = () => {
        setLogAction(JSON.stringify({
            fieldName: fieldName,
            donationId: donationId,
            oldValue: initialValue,
            newValue: singleValue,
            comments: comments
        }));
    }

    // save field value
    const saveAdminEditURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/updateFieldValue/" + donationId ;
    const [requestActionState, setAdminEdit] = useRequest({
        url: saveAdminEditURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess:  createLogEntry
    })

    // save log
    const logURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/logEntry";
    const [logActionState, setLogAction] = useRequest({
        url: logURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    const toggleShowEditForm = () => {
        setShowEditForm(!showEditForm);
    }

    // returns a string with the multiple values and the value of the other field if present
    const getNonEditableDisplay = () => {
        if (singleValue) {
            return (singleValue);
        }  else {
            return initialValue;
        }
    }

    useMemo(() => {
        setAdminEditable(adminEditForm);
    }, [adminEditForm])

    const schema = Yup.object().shape({
        comments: Yup.string().required('Please enter comments'),
        singleValue: Yup.string().required('Please enter a value'),

    });

    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => {
                    setAdminEdit(JSON.stringify({
                        fieldName: fieldName,
                        singleValue: singleValue,
                        comments: comments,
                    }));
                }}
                initialValues={{
                    fieldName:  fieldName,
                    singleValue: initialValue,
                    comments: "",
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <Row className="mt-3 mb-3  ">
                            {showEditForm && adminEditable && (
                                <>
                                    <AdminEditableRow className="w-100 ps-3 pe-3">
                                        <Form.Group as={Col} lg={8} controlId="">
                                            <Form.Label className={"required"}
                                                        column="sm">{label} </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"singleValue"}
                                                          type="text"
                                                          value={singleValue}
                                                          isValid={touched.singleValue && !errors.singleValue}
                                                          onChange={e => {
                                                              setSingleValue(e.target.value);
                                                              handleChange(e);
                                                          }}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <ErrorMessage name="singleValue"
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </AdminEditableRow>
                                    <AdminEditableRow className=" pb-3 ps-3 pe-3">
                                        <Form.Group as={Col} controlId="">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>
                                            </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comments"}
                                                          type="text"
                                                          value={comments}
                                                          isValid={touched.comments && !errors.comments}
                                                          onChange={e => {
                                                              setComments(e.target.value);
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <ErrorMessage name={"comments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </AdminEditableRow>
                                    <AdminDataFieldEditButtonContainer toggleMethod={toggleShowEditForm}/>
                                </>
                            )}
                            {!showEditForm && (
                                <AdminEditDisplayCol className=" mb-4">
                                    <AdminEditRow>
                                        <span className="bold">{label}</span>
                                        {adminEditable && (
                                            <button onClick={toggleShowEditForm}
                                                    className="btn btn-link select-files-link">
                                                edit
                                            </button>
                                        )}
                                    </AdminEditRow>
                                    <AdminEditRow>
                                        <span>{getNonEditableDisplay()}</span>
                                    </AdminEditRow>
                                </AdminEditDisplayCol>

                            )}
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <>
            <Container className="form-container" fluid>
                <ContentPage name={"content-page"}>
                    <PageWrapper name={"page-wrapper"}>
                        <Col md={10} lg={12} name={"shareDataColumn"}>
                            {buildForm()}
                        </Col>
                    </PageWrapper>
                </ContentPage>
            </Container>
        </>
    );

}
