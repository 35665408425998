/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AccessDataDetailsAndFiles
 *
 * Purpose: component to show access data studies
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Sep2023 sasjxa file created.
 * 19Nov2023 craig  add secure file download code
 * 03Jan2024 sasjxa display Check NCI Status when NCI and clin trial id matches
 * 06Feb2024 sasjxa parse text to handle highlighted text
 * 12Feb2024 sasjxa build linkage data for MEPS and Data Challenge
 * 20Feb2024 sasjxa add support for displaying file icons according to file type (MPM-5394)
 * 21Feb2024 sasjxa add missing provider info fields (MPM-5424)
 * 29Feb2024 sasjxa refactor to display individual study info (MPM-5392)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 04Apr2024 craig  switched to react-error-boundary
 * 09Apr2024 sasjxa support for displaying publications (MPM-5452)
 * 14Apr2024 craig  cleaned up some errors that show up in the console
 * 11Jun2024 craig  Fixed add/remove bug and fix link to requests page
 * 19Jun2024 sasjxa encode/decode special chars (MPM-5389)
 * 24Jun2024 craig  switch to getDecodedValue and added zip data files
 * 01Jul2024 craig  Refactored to zipfiles
 * 24Jun2024 craig  add ability to display contents of zipped data files
 * 27Jun2024 sasjxa add support for proc contents link
 * 28Jun2024 sasjxa separate data summary from data
 * 02Jul2024 craig  moved decode to api
 * 05Aug2024 craig  removed parse
 * 20Aug2024 sasjxa fix link for go to study for linked data
 * 21Aug2024 craig  moved to useContext(PDSContext) for user session
 * 26Sep2024 sasjxa fixed donation id value for linked dataset
 * 01Oct2024 sasjxa add check for logged in user for displaying Contents link
 * 01Oct2024 sasjxa add parse to provider description
 * 03Oct2024 craig  fixed NCI message if already in approved request and fixed add/remove bug
 * 14Nov2024 sasjxa use NOT_APPLICABLE constant
 * 02Dec2024 sasjxa add space to Publications dropdown
 * 20Dec2024 sasjx  add check for pending nci requests
 \*****************************************************************************************/
import React, {useEffect, useMemo, useState} from "react";

import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../styled/StyleConstants";
import {Link, useNavigate} from "react-router-dom";
import {IAccessData, IFile, IFileGroup, ILinkage, IPublicationDatasets} from "../../model/donations/IAccessData";
import Collapse from 'react-bootstrap/Collapse'
import {INciDataRequestTrialNumbers, INciRequestCart, PDSGroup} from "../../model/user/IUser";
import {NCIRequestDialog} from "../dialogs/NCIRequestDialog";
import {useRequest} from "../../helper/useRequest";
import {SecureDownload} from "./SecureDownload";
import {setSessionCookie, updateNciCart} from "../../context/context";
import {ICON_MISSING, NOT_APPLICABLE} from "../../data/formList";
import {BASE_PATH} from "../../constants";
import {getFileImage, hasClinGovId, isSASFile} from "../../constants/CommonFunctions";
import {ProcContents} from "./ProcContents";
import {DataZip} from "./DataZip";
import parse from "html-react-parser";
import {usePDSContext} from "../../context/PDSContext";
import axios, {AxiosError} from "axios";

const AccessDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 95%;
    margin: 0 auto;
    /*  max-width: 1440px; */

    a:hover {
        cursor: pointer;
    }
`;

const DonationContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;

    span {
        font-size: ${FONT_SIZES.px14};
        font-weight: bold;
    }

    span.fileType {
        font-size: ${FONT_SIZES.px14};
        font-weight: 400;
    }

    /*  max-width: 1440px; */
`;

const AccessDatItemSpaceBetweenRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span.access-title {
        font-size: ${FONT_SIZES.px14};
    }

    span.title {
        font-size: ${FONT_SIZES.px16};
    }
`;

const DonationRow = styled(AccessDatItemSpaceBetweenRow)`
    padding: 0;
    margin: 0;
    background-color: ${COLORS.filter_gray};


    span {
        display: inline;
        color: ${COLORS.dark_grey_blue};
    }

    span.access-title {
        font-size: ${FONT_SIZES.px13};
    }


    a {
        display: inline;
        font-size: ${FONT_SIZES.px13};
    }

`;

const LinkageRow = styled(Row)`
    padding: 0;
    margin: 0;
    background-color: ${COLORS.filter_gray};


    span {
        display: inline;
        color: ${COLORS.dark_grey_blue};
        width: 35%;
    }


    a {
        display: inline;
        font-size: ${FONT_SIZES.px13};
    }

`;

const StudyDetailsContainer = styled(Col)`
    padding-bottom: 3rem;
    padding: 0;
    margin: 0;
    border-top: 1px solid black;
    background-color: ${COLORS.filter_gray};

    span {
        display: block;
        color: ${COLORS.dark_grey_blue};
    }

    span.pubTitle {
        display: inline;
        font-weight: bold;
    }

    span.pubEntry {
        display: inline;
        font-weight: normal;
    }

    a.publications {
        color: ${COLORS.dark_grey_blue};
    }
`;

const DetailsRow = styled.div`
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    align-items: center;

`;

const MainTitle = styled.span`
    display: block;
    font-size: ${FONT_SIZES.px14};
    font-weight: bold;
`;

const SectionTitle = styled.span`
    display: block;
    font-size: ${FONT_SIZES.px13} !important;
    font-weight: normal !important;
`;

const SectionText = styled.span`
    display: block;
    font-size: ${FONT_SIZES.px13} !important;
    font-weight: normal;
`;

interface AccessDataDetailsAndFilesProps {
    donation: IAccessData,
    isAccessData: boolean
}

export const AccessDataDetailsAndFiles: React.FC<AccessDataDetailsAndFilesProps> = ({
                                                                                        donation, isAccessData
                                                                                    }: AccessDataDetailsAndFilesProps) => {
    const navigate = useNavigate();
    const {sessionUser, setSessionUser} = usePDSContext();
    const [checkNCIStatus, setCheckNCIStatus] = useState<boolean>(false);
    const [showStudyDetails, setShowStudyDetails] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    const [showPublications, setShowPublications] = useState(false);
    const [showStudyDetailsArrow, setStudyDetailsArrow] = useState<string>("/images/arrow_down.png");
    const [showFilesArrow, setFilesArrow] = useState<string>("/images/arrow_down.png");
    const [showPublicationsArrow, setPublicationsArrow] = useState<string>("/images/arrow_down.png");
    const [showNCIRequest, setShowNCIRequest] = useState<boolean>(false);
    const [nciText, setNciText] = useState<string>("");
    const [nciMouseEnterText, setNciMouseEnterText] = useState<string>("");
    const [nciMouseLeaveText, setNciMouseLeaveText] = useState<string>("");
    const [nciRequestUrl, setNciRequestUrl] = useState<string>("");
    const [messageType, setMessageType] = useState<string>("ADD");
    const [rebuildRecord, setRebuildRecord] = useState<boolean>(false);

    // check for nct present in cart or completed requests
    const hasNCTId = () => {
        let donationClinTrials: string[] = donation.subHeader.clinicalGovId;
        //let isFound = false;
        if (sessionUser.nciRequestCart !== null && sessionUser.nciDataRequests.length > 0) {
            for (const item of sessionUser.nciDataRequests) {
                let requestNCTIds: INciDataRequestTrialNumbers[] = item.trialNumbers;
                if (requestNCTIds !== null && requestNCTIds.length > 0) {
                    for (const nctId of requestNCTIds) {
                        if (donationClinTrials.includes(nctId.nctTrialNumber)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    // check for nct present in pending requests
    const hasNCTIdPendingRequests = () => {
        let donationClinTrials: string[] = donation.subHeader.clinicalGovId;
        //let isFound = false;
        if ( sessionUser.nciPendingDataRequests?.length ) {
            for (const item of sessionUser.nciPendingDataRequests) {
                let requestNCTIds: INciDataRequestTrialNumbers[] = item.trialNumbers;
                if (requestNCTIds !== null && requestNCTIds.length > 0) {
                    for (const nctId of requestNCTIds) {
                        if (donationClinTrials.includes(nctId.nctTrialNumber)) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }


    /**
     * build the nci request link
     */
    const buildNciRequestLink = () => {
        if (sessionUser.nciRequestCart.find(c => c.donationId === donation.donationId)) {
            setNciText("Added To NCI Data Request");
            setNciMouseEnterText("Remove from NCI Data Request");
            setNciMouseLeaveText("Added To NCI Data Request");
            setMessageType("ADD");
            setNciRequestUrl("/api/nci/requests/remove/" + donation.donationId);
        } else if (hasNCTIdPendingRequests()) {
            setNciText("Added To Pending NCI Data Request");
            setNciMouseEnterText("Added To Pending NCI Data Request");
            setNciMouseLeaveText("Added To Pending NCI Data Request");
            setMessageType("ADD");
            setNciRequestUrl("");
        } else if (donation.isNci && hasNCTId()) {
            setNciText("Check NCI Data Request Status");
            setNciMouseEnterText("Check NCI Data Request Status");
            setNciMouseLeaveText("Check NCI Data Request Status");
            setMessageType("CHECK");
            //look for ct.gov id
        } else {
            setNciText("Request NCI Data Access");
            setNciMouseEnterText("Request NCI Data Access");
            setNciMouseLeaveText("Request NCI Data Access");
            setMessageType("REMOVE");
            setNciRequestUrl("/api/nci/requests/add/" + donation.donationId + "/" + donation.subHeader.clinicalGovId[0]);
        }
    }

    /**
     * useMemo - setup the NCI info if user is not guest and donation is NCI
     */
    useMemo(() => {
        if (donation.isNci && sessionUser.pdsGroup !== PDSGroup.Guest) {
            buildNciRequestLink();
        }
    }, []);

    useMemo(() => {
        if (donation.isNci && sessionUser.pdsGroup !== PDSGroup.Guest && rebuildRecord) {
            setSessionCookie(sessionUser);
            buildNciRequestLink();
            setRebuildRecord(false);
        }
    }, [sessionUser]);

    /**
     * handle successful call to API
     *
     * @param nciRequestCart - request cart
     */
    const handleSuccess = (nciRequestCart: INciRequestCart[]) => {
        setSessionUser(updateNciCart(nciRequestCart as INciRequestCart[]));
        setRebuildRecord(true);
        toggleNCIDialog();
    }


    /**
     * handle an error to API
     *
     * @param error - error object
     */
    const handleError = (error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            console.log("could not add to cart - " + error.response.data.detail);
        } else {
            console.log("could not add to cart");
        }
    }

    /**
     * const for request
     */
    const [requestActionState, setRequestAction] = useRequest({
        url: nciRequestUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    });

    useEffect(() => {
        if (checkNCIStatus) {
            navigate(BASE_PATH + "nciRequestsView");
        }
    }, [checkNCIStatus]);

    /**
     * doNciRequestEvent - do the nci request event
     */
    const doNciRequestEvent = () => {
        //const tmpSessionUser: IUser = getSessionCookie();
        //check how many items are in the cart, can't be more than 5
        if (messageType === "CHECK") {
            setCheckNCIStatus(true);
        } else if (messageType === "ADD" || sessionUser.nciRequestCart.length < 5) {
            //not too many, so trigger the action
            setRequestAction();
        } else {
            //cannot add, so display dialog
            setMessageType("TO_MANY");
            toggleNCIDialog();
        }
    }


    /**
     * canRequestNCI - can the user request NCI
     */
    const canRequestNCI = () => {
        if (donation.isNci && sessionUser.pdsGroup !== PDSGroup.Guest) {
            return true;
        }
        return false;
    }

    /**
     * does the donations have publications
     */
    const hasPublications = (donation: IAccessData) => {
        if (donation.publicationDatasets != null && donation.publicationDatasets.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    // toggle NCIDialog
    const toggleNCIDialog = () => {
        setShowNCIRequest(!showNCIRequest);
    }

    // toggle StudyDetails
    const toggleStudyDetails = () => {
        setShowStudyDetails(!showStudyDetails);
        setStudyDetailsArrow(showStudyDetails ? "/images/arrow_down.png" : "/images/arrow_up.png")
    }

    // toggleFiles
    const toggleFiles = () => {
        setShowFiles(!showFiles);
        setFilesArrow(showFiles ? "/images/arrow_down.png" : "/images/arrow_up.png")
    }

    /**
     * togglePublications - Toggle the publications section
     */
    const togglePublications = () => {
        setShowPublications(!showPublications);
        setPublicationsArrow(showPublications ? "/images/arrow_down.png" : "/images/arrow_up.png")
    }

    /**
     * buildArraySection - build the section list
     *
     * @param title - title for the list
     * @param values - values for display
     * @param colSize - column size
     */
    const buildArraySection = (title: string, values: string[], colSize: number) => {

        return <Col lg={colSize}>
            <SectionTitle>{title}</SectionTitle>
            {
                values?.map((value: string, index: number) => {
                    return <SectionText key={"section-text-" + index}>{value}</SectionText>
                })
            }
        </Col>
    }

    /**
     * buildSection - build the section
     *
     * @param title - title of section
     * @param value - value to display
     */
    const buildSection = (title: string, value: string) => {
        return <>
            <Col lg={4}>
                <>
                    <SectionTitle>{title}</SectionTitle>
                    <SectionText>{value}</SectionText>
                </>
            </Col>
        </>
    }

    // returns the doi value
    const getDoi = (doi: string) => {
        return (doi != null ? doi : "");
    }

    // return arm size
    const getArmSize = (armSize: string) => {
        if (armSize != null && armSize.length > 0) {
            return armSize;
        } else {
            return NOT_APPLICABLE;
        }
    }

    /**
     * buildStudyDetails - build the study details section
     *
     * @param donation - donation to build
     */
    const buildStudyDetails = (donation: IAccessData) => {
        return (
            <div key={"study-details-" + donation.donationId}>
                <StudyDetailsContainer className={"pe-2 ps-3 pt-2 pb-2"} lg={12}>
                    <MainTitle className="pt-2 pb-2 ">Provider Information</MainTitle>
                    <SectionTitle className="pt-2 ">Provider Data Description </SectionTitle>
                    <SectionText>{parse(donation.providerInfo?.providerDescription)}</SectionText>

                    <Row className="pt-4 pb-2 w-100">
                        <Col lg={4}>
                            <SectionTitle>Sponsor</SectionTitle>
                            <SectionText>{donation.providerInfo?.sponsor}</SectionText>
                        </Col>
                        <Col lg={4}>
                            <SectionTitle>Data Provider</SectionTitle>
                            <SectionText>{donation.providerInfo?.dataProvider}</SectionText>
                        </Col>
                        {!donation.isNci && (
                            <>

                                <Col lg={4}>
                                    <SectionTitle>Partial set or subset</SectionTitle>
                                    <SectionText>{donation.providerInfo?.studyContent}</SectionText>
                                </Col>
                            </>
                        )}
                    </Row>
                    {!donation.isNci && (
                        <>
                            <Row className="pt-2 pb-2 w-100">
                                <Col lg={4}>
                                    <SectionTitle>Patients in Dataset</SectionTitle>
                                    <SectionText>{donation.providerInfo?.numPatients}</SectionText>
                                </Col>
                                <Col lg={4}>
                                    <SectionTitle># of Patients Control</SectionTitle>
                                    <SectionText>{getArmSize(donation.providerInfo?.comparatorPatients)}</SectionText>
                                </Col>
                                <Col lg={4}>
                                    <SectionTitle># of Patients Experimental</SectionTitle>
                                    <SectionText>{getArmSize(donation.providerInfo?.experimentalPatients)}</SectionText>
                                </Col>
                            </Row>
                            <Row className="pt-2 pb-2 w-100">
                                <Col lg={4}>
                                    <SectionTitle>DOI</SectionTitle>
                                    <SectionText>{getDoi(donation.providerInfo?.doi)}</SectionText>
                                </Col>
                                <Col lg={4}>
                                    <SectionTitle>CDISC Standard</SectionTitle>
                                    <SectionText>{donation.providerInfo?.dataStandard}</SectionText>
                                </Col>
                                <Col lg={4}>
                                    <SectionTitle>Study Arms</SectionTitle>
                                    <SectionText>{donation.providerInfo?.studyArm}</SectionText>
                                </Col>
                            </Row>
                        </>
                    )}


                </StudyDetailsContainer>

                {hasClinGovId(donation) && (
                    buildClinTrialsInfo(donation)
                )}

            </div>
        )
    }


    /**
     * build the file section
     *
     * @param donation - donation of the files
     * @param file - specific file
     */
    const buildFile = (donation: IAccessData, file: IFile) => {
        if (file !== null) {
            return (
                <>
                    <DetailsRow key={file.id} className={"ps-4 pe-4 pb-4 "}>
                        <Col lg={1}>
                            <img className=""
                                 src={getFileImage(file.filename)}
                                 alt="Waiting icon"
                                 width="48" height="48"/>
                        </Col>

                        <Col>
                            <span>{file.fileType}</span>
                            <span className={"fileType"}>{file.filename}</span>
                        </Col>
                        {isSASFile(file.filename) && (sessionUser?.userID > 0) && (
                            <ProcContents donation={donation} file={file}/>
                        )}
                        <SecureDownload donation={donation} file={file}/>
                    </DetailsRow>
                </>
            )
        }
    }

    /**
     * build the file section
     *
     * @param donation - donation of the files
     * @param file - specific file
     */
    const buildMissingFile = (fileType: string) => {

        return (
            <DetailsRow className={"ps-4 pe-4 pb-4"}>
                <Col lg={1}>
                    <img className=""
                         src={ICON_MISSING}
                         alt="Waiting icon"
                         width="48" height="48"/>
                </Col>

                <Col>
                    <span>{fileType}</span>
                    <span className={"fileType"}>{"Not Provided"}</span>
                </Col>

            </DetailsRow>
        )
    }

    const hasMissingProtocolOrCRF = (donation: IAccessData, file: IFile) => {
        if (!donation.isNci && file == null)
            return true;
        else {
            return false;
        }
    }

    const hasZipDataFile = (donation: IAccessData, file: IFile): boolean => {
        if (donation.isNci || file.zipFiles === null) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * buildFiles - build all the files for given donation
     *
     * @param donation - donation of the files
     */
    const buildFiles = (donation: IAccessData) => {
        return (
            <div key={"file-" + donation.donationId}>
                <StudyDetailsContainer className={"pe-2 ps-3 pt-2 pb-2"} lg={12}>
                    {
                        donation?.fileGroups?.map((fileGroup: IFileGroup, index: number) => {
                            return <DonationContainer key={"file-group-" + index} className={" "}>
                                <>
                                    <span className={"  mb-2"}>{fileGroup.groupName}</span>
                                    {buildFile(donation, fileGroup?.allZipFile)}
                                    {buildFile(donation, fileGroup?.experimentalZipFile)}
                                    {hasMissingProtocolOrCRF(donation, fileGroup?.protocol) && (
                                        buildMissingFile("PROTOCOL")
                                    )}
                                    {!hasMissingProtocolOrCRF(donation, fileGroup?.protocol) && (
                                        buildFile(donation, fileGroup?.protocol)
                                    )}
                                    {hasMissingProtocolOrCRF(donation, fileGroup?.crf) && (
                                        buildMissingFile("CRF")
                                    )}
                                    {!hasMissingProtocolOrCRF(donation, fileGroup?.crf) && (
                                        buildFile(donation, fileGroup?.crf)
                                    )}
                                    {buildFile(donation, fileGroup?.dataDictionary)}

                                    {
                                        fileGroup?.dataSummaries?.map((file: IFile) => {
                                            return (
                                                <>
                                                    {buildFile(donation, file)}
                                                </>
                                            );
                                        })
                                    }

                                    {
                                        fileGroup?.files?.map((file: IFile) => {
                                            return (
                                                <div key={"donation-file-" + file.id}>
                                                    {!hasZipDataFile(donation, file) && (
                                                        buildFile(donation, file)
                                                    )}
                                                    {hasZipDataFile(donation, file) && (
                                                        <DataZip donation={donation} file={file}/>
                                                    )}
                                                </div>
                                            );
                                        })
                                    }

                                </>
                            </DonationContainer>
                        })
                    }
                </StudyDetailsContainer>
            </div>
        )
    }

    /**
     * buildClinTrialsInfo - build the ct.gov section for given donation
     *
     * @param donation - donation to build
     */
    const buildClinTrialsInfo = (donation: IAccessData) => {
        return (
            <>
                <StudyDetailsContainer className={"pe-2 ps-3 pt-2 pb-2"} lg={12}>
                    <MainTitle className="pt-2 pb-2 ">Clinical Trials.gov Information</MainTitle>
                    <SectionTitle className="pt-2 pb-1 ">Brief Summary </SectionTitle>
                    <SectionText>{donation.clinicalTrialsInfo?.briefSummary}</SectionText>
                    <Row className="pt-4 pb-1 ">
                        <>
                            {buildArraySection("Conditions", donation.clinicalTrialsInfo?.conditions, 4)}
                            {buildArraySection("Intervention Type", donation.clinicalTrialsInfo?.interventions, 4)}
                            {buildArraySection("Drug(s)", donation.clinicalTrialsInfo?.drugs, 4)}
                        </>
                    </Row>
                    <Row className="pt-4 pb-1 ">
                        <>
                            {buildSection("Total Enrolled", donation.clinicalTrialsInfo?.enrollmentCount)}
                            {buildSection("Randomization", donation.clinicalTrialsInfo?.randomization)}
                            {buildSection("Blinding Method", donation.clinicalTrialsInfo?.blindingMethod)}
                        </>
                    </Row>
                    <Row className="pt-4 pb-1 ">
                        <>
                            {buildArraySection("Arms Intervention", donation.clinicalTrialsInfo?.armsInterventions, 12)}
                        </>
                    </Row>
                    <Row className="pt-4 pb-1 ">
                        <>
                            {buildSection("Secondary ID", donation.clinicalTrialsInfo?.secondaryId)}
                            {buildArraySection("PubMed (PMID)", donation.clinicalTrialsInfo?.pubMeds, 4)}
                            {buildArraySection("Collaborator(s)", donation.clinicalTrialsInfo?.collaborators, 4)}
                        </>
                    </Row>
                    <Row className="pt-4 pb-1 ">
                        <>
                            {buildArraySection("Region(s)", donation.clinicalTrialsInfo?.locations, 4)}
                            {buildSection("Age Range", donation.clinicalTrialsInfo?.ageRange)}
                        </>
                    </Row>
                </StudyDetailsContainer>
            </>
        )

    }

    const formatPublicationDate = (publication: IPublicationDatasets) => {
        return (
            new Date(publication?.publicationDate).toLocaleDateString('en-us', {
                month: "2-digit",
                day: "2-digit",
                year: "numeric"
            })
        )
    }

    const getRelatedNCTs = (publication: IPublicationDatasets) => {
        return (publication.relatedNCTIds != null ? publication.relatedNCTIds : "")
    }


    /**
     * buildPublications - build the publications section
     *
     * @param donation - donation to build
     */
    const buildPublications = (donation: IAccessData) => {
        return (
            <div key={"donation-pub-" + donation.donationId}>
                <StudyDetailsContainer className={"pe-2 ps-3 pt-2 pb-2"} lg={12}>
                    <MainTitle className="pt-2  ">Publications</MainTitle>
                    {
                        donation?.publicationDatasets?.map((publication: IPublicationDatasets) => {
                            return <div key={"publication-" + publication.id}>
                                <Row className="pt-4 pb-2 w-100">
                                <span>
                            <a className="publications" href={publication.url} target="_blank"
                               rel={"noopener noreferrer"}>{publication.title}</a>
                                    </span>
                                </Row>
                                <Row className=" w-100">
                                    <Col lg={10}>
                                        <span className="pubTitle">Journal: </span>
                                        <span className="pubEntry">{publication.journal} </span>
                                    </Col>
                                    <Col lg={2}>
                                        <span className="pubTitle">Date: </span>
                                        <span className="pubEntry">{formatPublicationDate(publication)} </span>
                                    </Col>
                                </Row>
                                <Row className="w-100">
                                    <Col>
                                        <span className="pubTitle">Authors: </span>
                                        <span className="pubEntry">{publication.authors} </span>
                                    </Col>

                                </Row>
                                <Row className="w-100">
                                    <Col>
                                        <span className="pubTitle">Other data available within PDS used in this publication: </span>
                                        <span className="pubEntry">{getRelatedNCTs(publication)} </span>
                                    </Col>

                                </Row>
                            </div>
                        })

                    }
                </StudyDetailsContainer>
            </div>
        )
    }


    /**
     * buildDropdownsRow - build header row that has the files/ct.gov and metadata sections
     *
     * @param donation
     */
    const buildDropdownsRow = (donation: IAccessData) => {
        return (
            <>
                <DonationRow className="ps-3 pe-3 pt-2 pb-2 ">
                            <span className=" ">
                            <a href={BASE_PATH + "content/" + donation.donationId} title="Go To Study"
                               className="study-contents  left result-info-link">{parse(donation.uniqueDatasetId)}</a>
                            </span>
                    <DonationRow className="ps-3 pe-2 pt-0 pb-2 ">
                        {canRequestNCI() && (
                            <DetailsRow className="pe-5">
                                <Link to={""} onMouseEnter={() => setNciText(nciMouseEnterText)}
                                      onMouseLeave={() => setNciText(nciMouseLeaveText)}
                                      onClick={() => doNciRequestEvent()}>
                                    {nciText}
                                </Link>
                            </DetailsRow>
                        )}
                        <DetailsRow className="pe-5">
                            <a className="" onClick={() => toggleStudyDetails()}>Study Details</a>
                            <span className="">
                                        <img
                                            onClick={() => toggleStudyDetails()}
                                            src={showStudyDetailsArrow}
                                            className="text-center"></img>
                                             </span>
                        </DetailsRow>
                        <DetailsRow className="pe-1">
                            <a onClick={() => toggleFiles()} className="">Files</a>
                            <span className="">
                                        <img
                                            onClick={() => toggleFiles()}
                                            src={showFilesArrow}
                                            className=""></img></span>
                        </DetailsRow>
                        {hasPublications(donation) && (
                            <DetailsRow className="ps-5">
                                <a className="" onClick={() => togglePublications()}>Publications</a>
                                <span className="">
                                        <img alt={"Show Publications"}
                                             onClick={() => togglePublications()}
                                             src={showPublicationsArrow}
                                             className="text-center"></img>
                                             </span>
                            </DetailsRow>
                        )}
                    </DonationRow>
                </DonationRow>
                <DonationRow className="ps-3 pe-2  pb-2  ">
                            <span className=" access-title">
                           {parse(donation.donationTitle)}
                            </span>
                </DonationRow>
                {buildLinkages(donation)}
                <Collapse in={showStudyDetails}>
                    <div>
                        {buildStudyDetails(donation)}
                    </div>
                </Collapse>
                <Collapse in={showFiles}>
                    <div>
                        {buildFiles(donation)}
                    </div>
                </Collapse>
                {hasPublications(donation) && (
                    <Collapse in={showPublications}>
                        <div>
                            {buildPublications(donation)}
                        </div>
                    </Collapse>
                )}
            </>
        )
    }

    /**
     * buildDropdownsRow - build header row that has the files/ct.gov and metadata sections
     *
     * @param donation
     */
    const buildContributionContentsRow = (donation: IAccessData) => {
        return (
            <>
                <DonationRow className="ps-3 pe-3 pt-2 pb-2 ">
                    <span className="study-contents  left result "> {donation.uniqueDatasetId}</span>
                    <DonationRow className="ps-3 pe-2 pt-0 pb-2 ">
                        {canRequestNCI() && (
                            <DetailsRow className="pe-5">
                                <Link to={""} onMouseEnter={() => setNciText(nciMouseEnterText)}
                                      onMouseLeave={() => setNciText(nciMouseLeaveText)}
                                      onClick={() => doNciRequestEvent()}>
                                    {nciText}
                                </Link>
                            </DetailsRow>
                        )}
                    </DonationRow>
                </DonationRow>
                <DonationRow className="ps-3 pe-2  pb-2  ">
                            <span className=" access-title">
                           {donation.donationTitle}
                            </span>
                </DonationRow>
                {buildStudyDetails(donation)}
                {buildFiles(donation)}
                {hasPublications(donation) && (
                    buildPublications(donation)
                )}
            </>
        )
    }

    /**
     * buildLinkages - build the section
     *
     * @param donation
     */
    const buildLinkages = (donation: IAccessData) => {
        return (
            donation?.linkages?.map((linkage: ILinkage) => {
                return <div key={"linkage-" + linkage.id}>
                    <LinkageRow className=" pt-3 pb-2  ">
                            <span className="ps-5 inline">
                            <a href={BASE_PATH + "content/" + linkage.donationLinkId} title="Go To Study"
                               className="study-contents  left result-info-link">{linkage.uniqueDatasetId}</a>
                            </span>

                        <span className="ps-5  ">
                            <a href={BASE_PATH + "content/" + linkage.donationLinkId} title=""
                               className="study-contents   result-info-link">Go To Study</a>
                            </span>
                    </LinkageRow>

                    <DonationRow className="ps-5 pe-2  pb-2 inline ">
                            <span className=" access-title">
                           {linkage.donationTitle}
                            </span>
                    </DonationRow>
                </div>
            })
        )

    }


    /**
     * return the component
     */
    return (
        <>
            <Container className="form-container" fluid>
                <NCIRequestDialog
                    show={showNCIRequest} showHide={toggleNCIDialog} messageType={messageType}/>
                <AccessDataContainer key={1} className=" mb-4 w-100">
                    <DonationContainer className={"mb-3"}>
                        {isAccessData && (
                            buildDropdownsRow(donation)
                        )}
                        {!isAccessData && (
                            buildContributionContentsRow(donation)
                        )}
                    </DonationContainer>
                </AccessDataContainer>
            </Container>
        </>
    );

}
