/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClinicalDrugKaplanMeierCurve
 *
 * Purpose: special PDS project
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Aug2024 sasjxa file created
 * 25Aug2024 sasjxa use new component for therapy selectors
 * 27Aug2024 sasjxa add check for multiple files before initial form display
 * 29Aug2024 sasjxa add support for deleting files
 * 08Oct2024 sasjxa remove correlation since not used in calculations
 * 20Nov2024 sasjxa restore previous work from main branch
 \******************************************************************************/
import React, {useEffect, useRef} from "react";
import * as d3 from "d3";
// @ts-ignore
import styled from 'styled-components';
import {IClinicalDrugProjectRecords} from "../../model/clinicalDrug/IClinicalDrugProjectRecords";
import {Col, Row} from "react-bootstrap";
import {ScaleLinear} from "d3";

const KaplanMeierRow = styled.div`
    height: 500px;
    width: 625px;
    //border: 1px solid #ddd;
    //background-color: #eee;
    display: flex;

    span.align {
        //color: #fff;
        font-size: 24px;
        text-align: center;
        //transform: rotate(-90deg);
        //white-space: nowrap;

    }
`;

const KaplanMeierSurvivalTitle = styled.div`
   //background-color: #1976d2;
    flex: 0 0 15px;
    min-width: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
        //color: #fff;
        font-size: 24px;
        transform: rotate(-90deg);
        white-space: nowrap; 
        
    }
 
`;

const SVGCol = styled.div`
    flex: 1;   
`;




interface ClinicalDrugAdditivityKaplanMeierProps {
    experimentalClean:IClinicalDrugProjectRecords[],
    controlClean:IClinicalDrugProjectRecords[],

    combinationPredictedAdd: IClinicalDrugProjectRecords[],
    combinationPredictedInd:IClinicalDrugProjectRecords[],



}

export const ClinicalDrugKaplanMeierCurve: React.FC<ClinicalDrugAdditivityKaplanMeierProps> = ({  experimentalClean,
                                                                                                   combinationPredictedAdd,
                                                                                                   combinationPredictedInd,
                                                                                                   controlClean
                                                                                               }: ClinicalDrugAdditivityKaplanMeierProps) => {


    const svgRef = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (experimentalClean?.length && controlClean?.length) {

            //const svg = d3.select(svgRef.current);

            // Set up margins and dimensions
            const margin = { top: 20, right: 1, bottom: 30, left: 40 };

            // const width = 600 - margin.left - margin.right;
            // const height = 400 - margin.top - margin.bottom;

            const width = 550 ;
            const height = 400;

            const svg =  d3.select(svgRef.current)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");


            const yScale = d3.scaleLinear()
                .domain([0, 1])
                .range([height, 0]);

            //call the y-axis in a group tag
            svg.append("g")
                .attr("class", "y axis")
                .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

            const xScale = d3.scaleLinear()
                .domain([d3.min(experimentalClean, d => d.time)!,
                    d3.max(experimentalClean, d => d.time)!])
                .range([0, width]);


            // Create the line generator
            const line = d3.line<IClinicalDrugProjectRecords>()
                .x(d => xScale(d.time))
                .y(d => yScale(d.survival))
                .curve(d3.curveStepAfter);



            //call the x-axis in a group tag
            svg.append("g")
                .attr("class", "x axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(xScale)); // Create an axis component with d3.axisBottom

            //call the y-axis in a group tag
            svg.append("g")
                .attr("class", "y axis")
                .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

            // experimental clean
            svg.append("path")
                .datum(experimentalClean) // 10. Binds data to the line
                // .attr("style", "fill: none; stroke: #000000; stroke-width: 3;") // Assign styling
                .attr("d", line)
                .attr('stroke', 'green')
                .attr('fill', 'none')

            // control clean
            svg.append("path")
                .datum(controlClean) // 10. Binds data to the line
                // .attr("style", "fill: none; stroke: #000000; stroke-width: 3;") // Assign styling
                .attr("d", line)
                .attr('stroke', 'orange')
                .attr('fill', 'none')

            // combination additivity
            svg.append("path")
                .datum(combinationPredictedAdd) // 10. Binds data to the line
                // .attr("style", "fill: none; stroke: #000000; stroke-width: 3;") // Assign styling
                .attr("d", line)
                .attr('stroke', 'red')
                .attr('fill', 'none')

            // combination hsa
            svg.append("path")
                .datum(combinationPredictedInd) // 10. Binds data to the line
                // .attr("style", "fill: none; stroke: #000000; stroke-width: 3;") // Assign styling
                .attr("d", line)
                .attr('stroke', 'blue')
                .attr('fill', 'none')
        }
    }, [experimentalClean]);


   


    return (
        <KaplanMeierRow>
            <KaplanMeierSurvivalTitle>
                <span>Survival rate</span>
            </KaplanMeierSurvivalTitle>
            <SVGCol>
                <Row>

                <svg ref={svgRef} width={600} height={400}></svg>

                </Row>
                <Row>
                    <span className="align">Time (weeks)</span>
                </Row>
            </SVGCol>

        </KaplanMeierRow>
    );


}