/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDashboard
 *
 * Purpose: admin dashboard for PDS
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Jun2023 craig  file created
 * 08Aug2023 sasjxa add function entries
 * 09Aug2023 sasjxa add links for DSA
 * 27Nov2023 sasjxa correct links for tickets
 * 05Jan2024 craig  added first pass at getting counts
 * 08Jan2024 sasjxa complete counts for dashboard entries
 * 09Feb2024 sasjxa fix for dsa counts
 * 16Feb2024 sasjxa change admin ticket count to include RMI
 * 21Feb2024 sasjxa remove visitors on site since can't be supported (MPM-5401)
 * 08Mar2024 craig  Added basepath
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 16Apr2024 craig  Display logged in users
 * 23Apr2024 sasjxa show dialog with active accounts info (MPM-5470)
 * 25Apr2024 sasjxa create dashboard toolbar (MPM-5471)
 * 14Jun2024 sasjxa add request new RMI token to account counts
 * 09Jul2024 sasjxa fix nci count
 * 12Jul2024 sasjxa add link for nci users (MPM-5566)
 * 18Aug2024 sasjxa fix count for new requests to include CRMI and RMI New Token
 * 25Aug2024 craig  Changed href to NavMenuItem
 * 04Dec2024 sasjxa add link for additional resources
 * 07Dec2024 sasjxa add link for site messages
 * 12Dec2024 sasjxa add link for platform announcements
 \**********************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {AdminTableHeader, ContentPage, GridRow, PageWrapper} from "../../components/styled/StyledComponents";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {IAdminDashboardCounts, IStatusCounts} from "../../model/admin/dashboard/IAdminDashboardCounts";
import {
    APPROVED_MIXED,
    CLOSED,
    CRMI,
    DECLINED_MIXED,
    IN_PROGRESS,
    NEW_MIXED,
    OPEN,
    PENDING_VERIFICATION,
    RMI_NEW_TOKEN
} from "../../data/formList";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {ActiveUsersDialog} from "../../components/dialogs/ActiveUsersDialog";
import {NavLink} from "react-router-dom";


const AdminDashboardHeader = styled(AdminTableHeader)`
    font-weight: bold;   
`;

const AdminNavBar = styled.div`
    width: 100%;
    padding: 1% 0;
    border-bottom: 1px solid ${COLORS.dark_grey_blue};    
`;

const Spacer = styled.p`
    height: 10px;
    border-top: 10px solid ${COLORS.light_grey};
    margin-right: 2%;
`;

const NavMenuItem = styled(NavLink)`
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    font-size: ${FONT_SIZES.px16};
    font-weight: 400;
    color: ${COLORS.cerulean};   
`;


export const AdminDashboard: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const [dashboardCounts, setDashboardCounts] = useState<IAdminDashboardCounts>();
    const [showDialog, setShowDialog] = useState<boolean>(false);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    //get data
    const countsURL: string = process.env.REACT_APP_API_URL + "/api/admin/dashboard/counts";
    const [requestState, getCounts] = useRequest({
        url: countsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getCounts();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDashboardCounts(data);
        }
    }, [data])

    // show/hide  dialog
    const toggleDialog = (e: any) => {
        e.preventDefault();
        setShowDialog(!showDialog);
    }

    const getSupportTicketCount = (status: string) => {
        let item: IStatusCounts | undefined = dashboardCounts?.techSupportStatusCounts?.find(s => s.status === status);
        if (item !== undefined) {
            return item?.count;
        } else {
            return 0;
        }
    }

    const getAccountTicketCount = (status: string) => {
        if (status === NEW_MIXED || status === CRMI || status === RMI_NEW_TOKEN) {
            let item: IStatusCounts[] | undefined = dashboardCounts?.accountStatusCounts?.filter(s => s.status === CRMI
                || s.status === NEW_MIXED || s.status === RMI_NEW_TOKEN) ;
            if (item !== undefined && item.length > 0) {
                let finalCount: number = 0;
                for (let i = 0; i < item.length; i++) {
                    finalCount = finalCount + item[i].count;
                }
                return finalCount;
            } else {
                return 0;
            }
        } else {
            let item: IStatusCounts | undefined = dashboardCounts?.accountStatusCounts?.find(s => s.status === status);
            if (item !== undefined) {
                return item?.count;
            } else {
                return 0;
            }
        }
    }


    const getDatasetsCount = (status: string) => {
        let item: IStatusCounts | undefined = dashboardCounts?.datasetsStatusCounts?.find(s => s.status === status);
        if (item !== undefined) {
            return item?.count;
        } else {
            return 0;
        }
    }

    const getDSACount = (status: string) => {
        let item: IStatusCounts | undefined = dashboardCounts?.dsaStatusCounts?.find(s => s.status === status);
        if (item !== undefined) {
            return item?.count;
        } else {
            return 0;
        }
    }

    const getNCIRequestsCount = () => {
        return dashboardCounts?.nciRequestsCounts;
    }


    const buildNavBar = () => {
        return (
            <AdminNavBar>
                <Container>
                    <NavMenuItem to={BASE_PATH + "admin/reporting"}>Reporting</NavMenuItem>
                    <NavMenuItem to={BASE_PATH + "admin/system"}>System</NavMenuItem>
                    <NavMenuItem to={BASE_PATH + "admin/announcements"}>Announcements</NavMenuItem>
                    <NavMenuItem to={BASE_PATH + "admin/siteMessages/ACTIVE"}>Site Messages</NavMenuItem>
                    <NavMenuItem to={BASE_PATH + "admin/additionalResources/ACTIVE"}>Additional Resources</NavMenuItem>
                    <NavMenuItem to={BASE_PATH + "admin/platformAnnouncement"}>Platform Announcements</NavMenuItem>
                </Container>
            </AdminNavBar>
        )
    }

    const buildDashboard = () => {
        return (
            <>
                <Col className="">
                    {buildCurrentActivity()}
                    <Spacer/>
                    {buildSupportTicketsOverview()}
                    <Spacer/>
                    {buildAccountsOverview()}
                    <Spacer/>
                    {buildDatasetsOverview()}
                    <Spacer/>
                    {buildNCIOverview()}
                    <Spacer/>
                    {buildDSAOverview()}
                </Col>
            </>
        )
    }

    const buildCurrentActivity = () => {
        return (
            <Col>
                <GridRow className={"w-100"}>
                    <AdminDashboardHeader className="mt-3 mb-3">Current Activity</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100"}>
                    <Col className="mt-2 mb-3" lg={8}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={1}>
                                <span id="dashboardCircle">{dashboardCounts?.activeUsers.length}</span>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin">Users Logged in </span>
                                <a href="" onClick={(e) => toggleDialog(e)}>View Accounts</a>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )
    }

    const buildSupportTicketsOverview = () => {
        return (
            <Col>
                <GridRow className={"w-100  "}>
                    <AdminDashboardHeader className="mt-2 mb-3">Support Tickets Overview</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100 "}>
                    <Col className="mt-2 mb-3 " lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/partial_clock_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="justify-content-center">
                                <span className="dashboard-admin">{getSupportTicketCount(OPEN)} Tickets Awaiting </span>
                                <NavMenuItem to={BASE_PATH + "admin/tickets/OPEN"}>View Tickets</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>


                    <Col className="mt-2 mb-3 " lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="b" lg={2}>

                                <img className={"text-center"} src="/images/icons/incomplete_yellow_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="justify-content-center">
                                <span className="dashboard-admin">{getSupportTicketCount(IN_PROGRESS)} Tickets In Progress </span>
                                <NavMenuItem to={BASE_PATH + "admin/tickets/IN_PROGRESS"}>View Tickets</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>

                    <Col className="mt-2 mb-3 " lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/complete_check_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="justify-content-center">
                                <span className="dashboard-admin">{getSupportTicketCount(CLOSED)} Tickets Closed </span>
                                <NavMenuItem to={BASE_PATH + "admin/tickets/CLOSED"}>View Tickets</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )

    }

    const buildAccountsOverview = () => {
        return (
            <Col>
                <GridRow className={"w-100  "}>
                    <AdminDashboardHeader className="mt-2 mb-3">Account Overview</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100"}>
                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/partial_clock_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span
                                    className="dashboard-admin">{getAccountTicketCount(NEW_MIXED)} Requests Awaiting </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/users/NEW"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>

                                <img className={"text-center"} src="/images/icons/complete_check_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin"> {getAccountTicketCount(APPROVED_MIXED)} Active Accounts </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/users/APPROVED"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>

                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center mb-2">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/incomplete_X_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin"> {getAccountTicketCount(DECLINED_MIXED)} Requests Declined </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/users/DECLINED"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )

    }

    const buildDatasetsOverview = () => {
        return (
            <Col>

                <GridRow className={"w-100  "}>
                    <AdminDashboardHeader className="mt-2 mb-3">Dataset Overview</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100 "}>
                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/partial_clock_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin">{getDatasetsCount(PENDING_VERIFICATION)} Requests Awaiting </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/datasets/PENDING"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>

                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>

                                <img className={"text-center"} src="/images/icons/complete_check_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span
                                    className="dashboard-admin"> {getDatasetsCount(APPROVED_MIXED)} Datasets Completed </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/datasets/APPROVED"}>View Datasets</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>

                    <Col className="mt-1 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/incomplete_X_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span
                                    className="dashboard-admin"> {getDatasetsCount(DECLINED_MIXED)} Datasets Declined </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/datasets/DECLINED"}>View Datasets</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )

    }

    const buildNCIOverview = () => {
        return (
            <Col>

                <GridRow className={"w-100  "}>
                    <AdminDashboardHeader className="mt-1 mb-3">NCI Requests Overview</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100 "}>
                    <Col className="mt-2 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>
                                <img className={"text-center"} src="/images/icons/partial_clock_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin">{getNCIRequestsCount()} NCI Requests </span>
                                <NavMenuItem to={BASE_PATH + "admin/nci/getRequestsByUser"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )

    }

    const buildDSAOverview = () => {
        return (
            <Col>
                <GridRow className={"w-100  "}>
                    <AdminDashboardHeader className="mt-1 mb-3">Data Share Agreement Overview</AdminDashboardHeader>
                </GridRow>
                <GridRow className={"w-100 "}>
                    <Col className="mt-2 mb-3" lg={4}>
                        <GridRow className="align-items-center ">
                            <Col className="" lg={2}>

                                <img className={"text-center"} src="/images/icons/partial_clock_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span
                                    className="dashboard-admin"> {getDSACount(PENDING_VERIFICATION)} Requests Awaiting </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/dsa/NEW"}>View Requests</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                    <Col className="mt-2 mb-3" lg={8}>
                        <GridRow className="align-items-center mb-2">
                            <Col className="" lg={1}>

                                <img className={"text-center"} src="/images/icons/complete_check_48.png"
                                     alt="Waiting icon"
                                     width="48" height="48"/>
                            </Col>
                            <Col className="">
                                <span className="dashboard-admin"> {getDSACount(APPROVED_MIXED)} Data Share Agreements Completed </span>
                                <NavMenuItem to={BASE_PATH + "admin/request/dsa/APPROVED"}>View Data Share Agreements</NavMenuItem>
                            </Col>
                        </GridRow>
                    </Col>
                </GridRow>
            </Col>
        )

    }



    return (

        <Container className="w-100 " fluid>
            <ActiveUsersDialog activeUsers={dashboardCounts?.activeUsers}
                               show={showDialog} showHide={toggleDialog}/>

            <ContentPage name={"content-page"}>
                {buildNavBar()}
                <PageWrapper name={"page-wrapper "}>

                    {isLoading &&
                        <Spinner className="spinner-center" animation={"border"} variant={"primary"}  role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    {error && <p>Error</p>}
                    {dashboardCounts && buildDashboard()}
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}