/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ServiceTicketsAction
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code) *
 * 18Apr2024 sasjxa file created
 * 23May2024 sasjxa navigate to user's service tickets page after successful commit
 * 06Jun2024 craig  encode the comments
 * 23Jan2025 craig  change to new axios API
 \*********************************************************************************/
import React, {useState} from "react";
import {useRequest} from "../../../helper/useRequest";
import {useErrorBoundary} from "react-error-boundary";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {
    ButtonRow,
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDSSubmitButton,
    TextAreaCountRow
} from "../../../components/styled/StyledComponents";
import {Formik} from "formik";
import * as Yup from "yup";
import {ADMIN_TICKETS_ACTIONS_CLOSED, CLOSED, FormList, SERVICE_TICKETS_OPEN, SUBMIT} from "../../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ITicket, ITicketEvent} from "../../../model/tickets/ITickets";
import {TicketEventsAndContactUs} from "../../../components/forms/TicketEventsAndContactUs";
import {BASE_PATH} from "../../../constants";
import {getEncodedValue} from "../../../constants/CommonFunctions";
import {PdsApi} from "../../../hooks/axiosClient/pdsApi";
import {postPDSData} from "../../../utilities/axiosClient/axiosClientUtility";
import {ClearAndSubmitButtonContainer} from "../../../components/buttonContainer/ClearAndSubmitButtonContainer";


export const ServiceTicketAction: React.FC = () => {
    const {ticketId, requestType} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const [commentCount, setCommentCount] = useState(0);
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const maxCommentCount = 8000;

    const requestsURL = process.env.REACT_APP_API_URL + "/api/user/tickets/processTicket/" + ticketId;
    const {data, loading, error} = PdsApi<ITicket>(requestsURL);

    const schema = Yup.object().shape({
        action: Yup.string()
            .required("Please select an action"),

    });

    const buildSelectList = () => {
        let listType: FormList[] = new Array();
         if (requestType != CLOSED) {
            listType = SERVICE_TICKETS_OPEN;
        }
        else  {
            listType = ADMIN_TICKETS_ACTIONS_CLOSED;
        }
        return listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
    }

    const buildForm = () => {
        return (
            <>
                <Formik
                        // validationSchema={schema}
                        onSubmit={async (values) => {
                            setIsWaitingForResponse(true);
                            const saveTicketEventURL = process.env.REACT_APP_API_URL + "/api/user/tickets/ticketEvent/" + ticketId;
                            const {data, error} = await postPDSData<ITicketEvent, string>
                            (saveTicketEventURL,
                                JSON.stringify({
                                    event: values.action, comment: getEncodedValue(values.comment)
                                }));
                            if (data) {
                                navigate(BASE_PATH + 'serviceTickets/' );
                            } else if (error) {
                                setErrorMsg(error.response?.data?.detail);
                            }
                            setIsWaitingForResponse(false);
                        }}
                        initialValues={{
                            action: '',
                            comment: ""
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn className="mx-auto" lg={12}>
                                <FormLayoutColumn className="me-5 " lg={8}>
                                    <Row className=" mt-3 mb-3 w-100">
                                        <Form.Group as={Col} lg={6}
                                                    controlId="formAction">
                                            <Form.Label className="required" column="sm">Actions </Form.Label>
                                            <Form.Select size="sm"
                                                         name={"action"}
                                                         onChange={e => {
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList()}
                                            </Form.Select>

                                        </Form.Group>
                                    </Row>
                                    <Row className="mt-3 mb-5 ">
                                        <Form.Group as={Col} lg={8}
                                                    controlId="formComments">
                                            <TextAreaCountRow>
                                            <Form.Label  column="sm">Comments</Form.Label>
                                                <span>{commentCount} of {maxCommentCount} characters</span>

                                        </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comment"}
                                                          type="text"
                                                          maxLength={maxCommentCount}
                                                          onChange={e => {
                                                              setCommentCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                        </Form.Group>
                                    </Row>
                                </FormLayoutColumn>
                            </FormLayoutColumn>
                            <ClearAndSubmitButtonContainer enableClear={false} submitButtonText={SUBMIT}
                                                           isSubmitting={isWaitingForResponse}/>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    const buildSection = () => {
        return (
            <>
                {buildForm()}
                <Col>
                <TicketEventsAndContactUs  ticket={data ?? undefined}/>
                </Col>
            </>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {loading &&
                            <Spinner  className ="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>{error.response?.data.detail || errorMsg}</p>}
                        {data && buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}