/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminSiteMessage
 *
 * Purpose: display site messages
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 07Dec2024 sasjxa file created
 * 19Dec2024 sasjxa call public controller method to get messages
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink, useParams} from "react-router-dom";

import {SITE_MESSAGES_HEADERS,} from "../../data/formList";
import {
    AnnouncementsMenuItem,
    GridRow,
    NewAnnouncementButtonRow,
    PDS_P_BOLD,
} from "../../components/styled/StyledComponents";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {IAdminSiteMessage} from "../../model/announcements/IAdminSiteMessage";
import parse from "html-react-parser";


export const AdminSiteMessage: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const {messageType} = useParams();
    const [siteMessages, setSiteMessages] = useState<IAdminSiteMessage[]>([]);
    const PAST = "PAST";

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/public/announcements/getSiteMessages/" + messageType;
    const [requestState, getMessages] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;


    useMemo(() => {
        getMessages();
    }, [])

    useMemo(() => {
        if (data != null) {
            setSiteMessages(data);
        }
    }, [data])


    // build site messages header
    const buildSiteMessageHeaders = () => {
        return (
            <>
                <GridRow className="mt-3">
                    <Col className="grid-header grid-entry space-1 ">Start Date</Col>
                    <Col className="grid-header grid-entry space-1 ">End Date</Col>
                    <Col className="grid-header grid-entry  space-6">Message</Col>
                    {messageType !=PAST && (
                        <Col className="grid-header grid-entry  space-2">Edit</Col>
                    )}
                </GridRow>
            </>
        )
    }



    // build site messages
    const buildSiteMessages = () => {
        return (
            <>
                {siteMessages?.map((siteMessage: IAdminSiteMessage) => (
                    <GridRow className="mb-3">
                        <Col className="grid-entry space-1 ">
                            {new Date(siteMessage.startDate).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                        <Col className="grid-entry space-1 ">
                            {new Date(siteMessage.endDate).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                        <Col className="grid-entry space-6">{parse(siteMessage.message)}</Col>

                        {messageType !== PAST && (
                            <Col className="grid-entry space-2">
                                <NavLink to={BASE_PATH + "admin/siteMessages/action/" + siteMessage.id} className={"linkstyle "}>Edit</NavLink>
                            </Col>

                        )}
                    </GridRow>
                ))}
            </>
        )
    }

    const buildSection = () => {
        if (siteMessages?.length) {
            return (
                <>
                    <Col className="mb-3">
                        {buildSiteMessageHeaders()}
                        {buildSiteMessages()}
                    </Col>
                </>
            )
        } else {
            return (
                <PDS_P_BOLD>No {messageType?.toLocaleLowerCase()} site messages at this time</PDS_P_BOLD>
            )
        }
    }


    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={SITE_MESSAGES_HEADERS} requestType={messageType}
                               countsUrl={"/api/admin/dashboard/counts/siteMessage"}/>
            <NewAnnouncementButtonRow className={"mt-3  "}>
                <Col className="mt-2" md={8} lg={5}>
                    <AnnouncementsMenuItem to={BASE_PATH + "admin/siteMessages/action"}>Create New Site
                        Message</AnnouncementsMenuItem>
                </Col>
            </NewAnnouncementButtonRow>
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}

        </Container>
    )
}