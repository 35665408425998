/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClearAndSubmitButtonContainer
 *
 * Purpose: Button Container for Clear and Submit Buttons
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Jun2023 sasjxa  file created.
 * 21Jul2023 sasjxa  add method for clearing form
 * 11Aug2023 sasjxa  add height dimension to container
 * 06Dec2024 sasjxa  adjust space between buttons
 \******************************************************************************/
import {Col,  Row} from "react-bootstrap";
import React from "react";
import {
     BasicSubmitButton,
     FormLayoutColumn,
    PDSSubmitButton,

} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {COLORS, SIZES} from "../styled/StyleConstants";


const ButtonContainerWrapper = styled.div`
   width: 100%;
   margin-left: 0;
   margin-right: 0;    
   padding-top: 0;
   background-color: ${COLORS.light_grey};  
        
   @media (min-width: 768px){         
     width: 100%;    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 75px;
   
    padding-top: 2%;
    
   } 

`;

const ButtonContainerBody = styled.div`
  max-width:  ${SIZES.MAX_WIDTH}; 
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 1%; 
`;

 const EditLayoutColumn = styled(Col)` 
    margin: 0 auto; 
    background-color: ${COLORS.light_grey};
`;

const CancelButton = styled(BasicSubmitButton)`
    color: ${COLORS.dark_grey_blue}; 
    background-color: ${COLORS.white};
   

    :hover {
      background-color: ${COLORS.white};
      color: ${COLORS.dark_grey_blue};
    }
`;

const FormRow = styled(FormLayoutColumn)`
    background-color: ${COLORS.light_grey};
    margin: 0 auto;
`;

const AdminEditButtonRow = styled.div`
      display:flex;           
      justify-content: space-between;
      padding-bottom: 3%;     
      padding-top: 0;
      padding-right: 6%; 
      width: 85%;
`;

interface AdminDataFieldEditButtonContainerProps {
    toggleMethod: any
}

export const AdminDataFieldEditButtonContainer: React.FC<AdminDataFieldEditButtonContainerProps> = ({
                                                                      toggleMethod,

                                                                  }: AdminDataFieldEditButtonContainerProps) => {
    return (
        <EditLayoutColumn lg={12} >
            <EditLayoutColumn className=" mb-5 " lg={8}>
                <Row className="w-100">
                    <FormRow className="mx-auto">
                        <ButtonContainerWrapper className={" "}>
                            <ButtonContainerBody className={""}>
                                <AdminEditButtonRow className={""}>
                                    <CancelButton onClick={toggleMethod} variant="outline-light"
                                                  className="btn-submit  " type="button">
                                        Cancel
                                    </CancelButton>

                                    <PDSSubmitButton variant="success" className=" pe-5 btn-submit"
                                                     type="submit">
                                        Submit
                                    </PDSSubmitButton>
                                </AdminEditButtonRow>
                            </ButtonContainerBody>
                        </ButtonContainerWrapper>
                    </FormRow>
                </Row>
            </EditLayoutColumn>
        </EditLayoutColumn>
    )
}


