/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Registration
 *
 * Purpose: PDS user registration page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02May2023 sasjxa file created
 * 31May2023 sasjxa add values to select components *
 * 15Jun2023 sasjxa display submission response after successful submit
 * 23Jun2023 sasjxa refactor to use new button container
 * 27Nov2023 sasjxa add ability to save contact us information
 * 07Dec2023 sasjxa new methods for handling users not logged in
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 11Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 19May2024 sasjxa add error message element to subject
 * 23May2024 sasjxa fix event handler for subject other
 * 05Jun2024 craig  added code handle DISABLED/LOCKED/EXPIRED accounts
 * 07Jun2024 sasjxa remove encoding for subject
 * 02Jul2024 craig  removed decode (moved to api)
 * 09Aug2024 sasjxa rework subject selector to reset to default for clear
 * 11Aug2024 sasjxa fix clear issue for subject other
 * 21Aug2024 craig  moved to useContext(PDSContext) for user session
 * 30Sep2024 sasjxa remove clear button temporarily due to invalid error message surfacing
 * 01Nov2024 craig  added yup max to text fields and maxlength
 * 08Nov2024 sasjxa correct text for yup max last name
 * 15Jan2025 craig  changed from useRequest to new API call
 \*****************************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
import {ContentPage, FormLayoutColumn, PageWrapper, TextAreaCountRow} from "../../components/styled/StyledComponents";

import {
    CONTACT_US_REASONS,
    FormList,
    HEADER_CONTACT_US,
    max2000Count,
    OTHER_OPTION,
    RESPONSE_CONTACT_US,
    SUBMIT,
} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {FirstLastName} from "../../components/forms/FirstLastName";
import {Email} from "../../components/forms/Email";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {usePDSContext} from "../../context/PDSContext";
import {postPDSData} from "../../utilities/axiosClient/axiosClientUtility";
import {AxiosError} from "axios";
import {PDSErrorResponse} from "../../helper/useRequest";


export const ContactUs: React.FC = () => {
    const {email, status} = useParams();
    const [message, setMessage] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<AxiosError<PDSErrorResponse> | undefined>(undefined);
    const [subject, setSubject] = useState<string>("");
    const [emailAddr, setEmailAddr] = useState<string | undefined>("");
    const navigate = useNavigate();
    const {sessionUser} = usePDSContext();
    const [showSubjectOther, setShowSubjectOther] = useState(false);
    const [messageCount, setMessageCount] = useState(0);
    const formikRef = useRef<FormikProps<any>>(null);
    const [subjectOtherValue, setSubjectOtherValue] = useState<string>("");
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    useMemo(() => {
        if (status === 'DISABLED') {
            setEmailAddr(email);
            setSubject("Other");
            setSubjectOtherValue("Disabled Account");
            setShowSubjectOther(true);
            setMessage(" My account is disabled. Please reset the account for user: " + email);
        }
    }, [])

    const disabledSchema = Yup.object().shape({
        first: Yup.string()
            .required("Please enter a first name.")
            .max(50, "First name should be no longer than 50 characters."),
        last: Yup.string()
            .required("Please enter a last name.")
            .max(100, "Last name should be no longer than 100 characters."),
    });

    // validation schema
    const baseSchema = Yup.object().shape({
        subject: Yup.string().required("Please select a subject."),
        subjectOther: Yup.string()
            .max(500, "Subject should be no longer than 500 characters.")
            .when(['subject'], {
                is: (subject: string) => {
                    return subject === OTHER_OPTION;
                },
                then: (schema) =>
                    schema
                        .required("Please enter a subject.")
            }),
        message: Yup.string().required("Please enter a message")
            .max(2000, "Last name should be no longer than 2000 characters."),
    });

    // full validation schema including email
    const fullSchema = baseSchema.concat(
        Yup.object().shape({
            first: Yup.string().required("Please enter a first name.")
                .max(50, "First name should be no longer than 50 characters."),
            last: Yup.string().required("Please enter a last name.")
                .max(100, "Last name should be no longer than 100 characters."),
            email: Yup.string().required("Please provide an email address.")
                .max(255, "Email address should be no longer than 255 characters.")
                .email(),
            emailRepeat: Yup.string().required("Please provide an email address.")
                .max(255, "Email address should be no longer than 255 characters.")
                .oneOf([Yup.ref('email')], 'Email addresses must match'),
        }));

    // return the validation schema depending on whether user logged in
    const getValidationSchema = () => {
        if (sessionUser?.userID > 0) {
            return baseSchema;
        } else if (status === 'DISABLED') {
            return baseSchema;
        } else return fullSchema;
    }

    // build the select list
    const buildSelectList = (listType: FormList[]) => {
        return listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
    }

    // show other field when 'Other" selected from list
    const handleSubjectChanged = (value: string) => {
        setSubject(value);
        if (value === OTHER_OPTION) {
            setShowSubjectOther(true);
        } else if (showSubjectOther) {
            setShowSubjectOther(false);
        }
    }

    // return first name of user if registered user and logged on
    const getFirstName = () => {
        if (sessionUser?.userID > 0) {
            const name = sessionUser.name
            const lastSpaceIndex = name.lastIndexOf(' ');
            return name.substring(0, lastSpaceIndex).trim();
        } else {
            return "";
        }
    }

    const getFirstNameForSave = (nameValue: string) => {
        if (sessionUser?.userID > 0) {
            const name = sessionUser.name
            const lastSpaceIndex = name.lastIndexOf(' ');
            const firstName = name.substring(0, lastSpaceIndex).trim();
            return getEncodedValue(firstName);
        } else {
            return getEncodedValue(nameValue);
        }
    }

    // return last name of user if registered user and logged on
    const getLastName = () => {
        if (sessionUser?.userID > 0) {
            const name = sessionUser.name
            const lastSpaceIndex = name.lastIndexOf(' ');
            return name.substring(lastSpaceIndex).trim();
        } else {
            return "";
        }
    }

    const getLastNameForSave = (nameValue: string) => {
        if (sessionUser?.userID > 0) {
            const name = sessionUser.name
            const lastSpaceIndex = name.lastIndexOf(' ');
            const lastName = name.substring(lastSpaceIndex).trim();
            return getEncodedValue(lastName);
        } else {
            return getEncodedValue(nameValue);
        }
    }

    // return email address of user if registered user
    const getEmail = () => {
        if (sessionUser?.userID > 0) {
            return sessionUser.username;
        } else if (status === 'DISABLED' && emailAddr !== undefined) {
            return emailAddr;
        } else {
            return "";
        }
    }

    // return email address of user if registered user
    const getEmailForSave = (emailValue: string) => {
        if (sessionUser?.userID > 0) {
            return sessionUser.username;
        } else if (status === 'DISABLED') {
            return emailAddr;
        } else {
            return emailValue;
        }
    }

    const getSubjectForSave = (subjectValue: string) => {
        if (status === 'DISABLED') {
            return subject;
        } else return subjectValue;
    }

    const getSubjectOtherForSave = (subjectOtherValue: string) => {
        if (status === 'DISABLED') {
            return subjectOtherValue;
        } else return getEncodedValue(subjectOtherValue);
    }

    const getMessage = () => {
        if (status === 'DISABLED') {
            return message;
        } else {
            return "";
        }
    }

    const getMessageForSave = (messageValue: string) => {
        if (status === 'DISABLED') {
            return message;
        } else return getEncodedValue(messageValue);
    }

    // disable name fields if registered user
    const shouldDisable = () => {
        return sessionUser?.userID > 0;
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            setShowSubjectOther(false)
        }
    }


    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    validationSchema={getValidationSchema()}
                    onSubmit={async (values) => {
                        const url: string = process.env.REACT_APP_API_URL + "/api/public/users/contactus";
                        //console.log(values);
                        setIsWaitingForResponse(true);
                        const {data, error} = await postPDSData<string, string>(url,
                            JSON.stringify({
                                first: getFirstNameForSave(values.first),
                                last: getLastNameForSave(values.last),
                                email: getEmailForSave(values.email),
                                subject: getSubjectForSave(values.subject),
                                subjectOther: getSubjectOtherForSave(values.subjectOther),
                                userComment: getMessageForSave(values.message)
                            }));
                        setIsWaitingForResponse(false);
                        if (data !== null && data.localeCompare('ok') === 0) {
                            navigate(BASE_PATH + 'SubmissionResponse', {state: {header: HEADER_CONTACT_US, response: RESPONSE_CONTACT_US}});
                        } else if (error) {
                            setErrorMessage(error);
                        }
                    }}
                    initialValues={{
                        first: '',
                        last: '',
                        email: '',
                        emailRepeat: '',
                        subject: '',
                        subjectOther: '',
                        message: '',
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={6}>
                                <FirstLastName first={getFirstName()} last={getLastName()} disabled={shouldDisable()}
                                               touched={touched} errors={errors} handleChange={handleChange}/>

                                <Row className="mt-3 mb-3">
                                    <Email fieldName={"email"} label={"Email"} value={getEmail()}
                                           disabled={shouldDisable()} touched={touched}
                                           errors={errors} size={6} handleChange={handleChange}/>
                                    <Email fieldName={"emailRepeat"} label={"Repeat E-mail"} value={getEmail()}
                                           disabled={shouldDisable()} touched={touched} errors={errors}
                                           size={6} handleChange={handleChange}/>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formSubject">
                                        <Form.Label className="required" column="sm">Subject </Form.Label>
                                        <Form.Select size="sm"
                                                     name={"subject"}
                                                     value={values.subject}
                                                     isValid={touched.subject && !errors.subject}
                                                     onChange={e => {
                                                         handleSubjectChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(CONTACT_US_REASONS)}
                                        </Form.Select>
                                        <ErrorMessage name={"subject"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showSubjectOther && (
                                    <Row className=" mt-3 mb-3 ">
                                        <Form.Group as={Col}
                                                    controlId="">
                                            <Form.Label className="required" column="sm">Other Subject </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"subjectOther"}
                                                          type="text"
                                                          value={values.subjectOther}
                                                          onChange={e => {
                                                              setSubjectOtherValue(e.target.value);
                                                              handleChange(e);
                                                          }}
                                                          isValid={touched.subjectOther && !errors.subjectOther}/>
                                            <ErrorMessage name={"subjectOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-5 ">
                                    <Form.Group as={Col}
                                                controlId="formMessage">
                                        <TextAreaCountRow>
                                            <Form.Label className="required" column="sm">Message </Form.Label>
                                            <span>{messageCount} of {max2000Count} characters</span>
                                        </TextAreaCountRow>
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"message"}
                                                      type="text"
                                                      defaultValue={getMessage()}
                                                      isValid={touched.message && !errors.message}
                                                      onChange={e => {
                                                          setMessageCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        <ErrorMessage name={"message"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <ClearAndSubmitButtonContainer enableClear={false} handleReset={handleReset}
                                                       submitButtonText={SUBMIT} isSubmitting={isWaitingForResponse}/>
                    </Form>

                )}
            </Formik>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        <div className={"form-error-msg"}>{errorMessage?.response?.data?.detail}</div>
                        {buildForm()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
