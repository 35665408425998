/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Forgot Password
 *
 * Purpose: PDS user registration page *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 06Jul2023 sasjxa file created
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 16Apr2024 sasjxa add error handling to form field
 * 21May2024 sasjxa switched to react-error-boundary (MPM-5454)
 * 05Jun2024 craig  Added code handle DISABLED/LOCKED/EXPIRED accounts
 * 31Oct2024 craig  Added waitingForResponse to prevent new submit before response
 * 18Dec2024 sasjxa display message for error condition
 \**********************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik} from "formik";
// @ts-ignore
import {
    ButtonRow,
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDS_H5,
    PDSSubmitButton
} from "../../components/styled/StyledComponents";

import {RESPONSE_FORGOT_PASSWORD,} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import axios, {AxiosError} from "axios";


export const ForgotPassword: React.FC = () => {
    const {status} = useParams();
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();

    const [forgotPasswordHeader, setForgotPasswordHeader] = useState<string>("Forgot Password");
    const [forgotPasswordMsg, setForgotPasswordMsg] = useState<string | undefined>("");

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    /*
   useMemo - check if error is in search params and true and then set error msg
             This is because the user will be redirected from the login dialog to here
 */
    useMemo(() => {
        if (status === 'EXPIRED') {
            setForgotPasswordHeader("Reset Password");
            setForgotPasswordMsg("Your account is expired, please reset your password.");
        } else if (status === 'LOCKED') {
            setForgotPasswordHeader("Reset Password");
            setForgotPasswordMsg("Your account is locked, please reset your password.");
        } else if (status === 'INACTIVE') {
            setForgotPasswordHeader("Reset Password");
            setForgotPasswordMsg("Your account is inactive, please reset your password.");
        }
    }, [])

    const handleSuccess = () => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + 'SubmissionResponse', {state: {response: RESPONSE_FORGOT_PASSWORD}});
    }

    /**
     * error handler
     *
     * @param error - error object
     */
    const handleError = (error: Error | AxiosError) => {
        setIsWaitingForResponse(false);

        if (axios.isAxiosError(error) && error.response !== undefined) {
            if ( error.response.data.detail !== undefined) {
                setForgotPasswordMsg(error.response.data.detail);
            }
        } else {
            console.log("could reset password");
        }

    }



// request setup
const [requestState, setPasswordReset] = useRequest({
    url: process.env.REACT_APP_API_URL + "/api/public/password/forgot",
    method: "post",
    withCredentials: true,
    initialIsLoading: true,
    onError: handleError,
    onSuccess: handleSuccess
})


const schema = Yup.object().shape({
    email: Yup.string().required("Please enter an email address."),

});

const buildForm = () => {
    return (
        <Formik
            validationSchema={schema}
            onSubmit={(values) => {
                setIsWaitingForResponse(true);
                setPasswordReset(JSON.stringify({email: values.email}));
            }}
            initialValues={{
                email: '',
            }}
            validateOnChange={false}
            validateOnBlur={false}>
            {({
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
              }) => (
                <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                    <FormLayoutColumn lg={12}>
                        <FormLayoutColumn lg={8}>

                            <Row className="justify-content-center mt-3 mb-3">
                                <Form.Group as={Col} lg={4}
                                            controlId="formEmail">
                                    <Form.Label className="required" column="sm">User ID (Email
                                        Address) </Form.Label>
                                    <Form.Control size="sm" required
                                                  name={"email"}
                                                  type="email"
                                                  placeholder=""
                                                  isValid={touched.email && !errors.email}
                                                  onChange={handleChange}/>
                                    <Form.Text className="text-muted"></Form.Text>
                                </Form.Group>
                                <ErrorMessage name={"email"}
                                              render={msg => <div className={"form-error-msg"}>{msg}</div>}/>


                            </Row>
                        </FormLayoutColumn>
                    </FormLayoutColumn>
                    <ButtonRow>
                        <PDSSubmitButton variant="success" className="btn-submit" type="submit">
                            {isWaitingForResponse &&
                                <Spinner as={"span"} animation={"border"} size={"sm"} role={"status"}
                                         variant={"warning"}
                                         aria-hidden={"true"}/>
                            }
                            Submit
                        </PDSSubmitButton>
                    </ButtonRow>
                </Form>

            )}
        </Formik>
    )
}

return (
    <Container className="form-container" fluid>
        <ContentPage name={"content-page"}>
            <PageWrapper name={"page-wrapper"}>
                <FormLayoutColumn>
                    <PDS_H5>{forgotPasswordHeader}</PDS_H5>
                    <div className={"form-error-msg"}>{forgotPasswordMsg}</div>
                    {buildForm()}
                </FormLayoutColumn>
            </PageWrapper>
        </ContentPage>
    </Container>
);

}
