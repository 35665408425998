/******************************************************************************\
 * :$
 *
 * Copyright(c) 2025 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: pdsApi
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 13Jan2025 craig File created.
 \******************************************************************************/
import {useEffect, useState} from "react";
import axiosInstance from "../../managers/axiosClient/axiosInstance";
import {AxiosError} from "axios";
import {IPDSErrorResponse} from "../../model/error/IPDSErrorResponse";

type ApiResponse<T> = {
    data: T | null;
    loading: boolean;
    error: AxiosError<IPDSErrorResponse> | null;
};

export function PdsApi<T>(url: string) {
    const [response, setResponse] = useState<ApiResponse<T>>({
        data: null,
        loading: true,
        error: null,
    });

    const fetchData = async () => {
        setResponse({ ...response, loading: true });
        try {
            const result = await axiosInstance.get<T, any>(url);
            setResponse({
                data: result,
                loading: false,
                error: null
            });
        } catch (error: any) {
            setResponse({
                data: null,
                loading: false,
                error: error,
            });
        }
    };

    // Run fetchData when the component is mounted
    useEffect(() => {
        fetchData();
    }, []);

    return response;
}