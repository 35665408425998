/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ShareDataDashboard
 *
 * Purpose: component to show share data dashboard
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 01Aug2023 craig File created.
 * 30Sep2023 craig pull over special IShareDataDashboard instead of all data
 * 10Oct2023 sasjxa fix header for approval cell
 * 20Oct2023 sasjxa correct url values for shareDatasetsBasicsRWD
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 18Jun2024 sasjxa encode/decode special chars (MPM-5389)
 * 19Jun2024 sasjxa correct link for manage files
 * 02Jul2024 craig  removed decode (moved to api)
 * 23Sep2024 sasjxa changed href to NavLink
 \******************************************************************************/
import React, {useEffect, useMemo, useState} from "react";
import {FormLayoutColumn, NavMenuItem} from "../styled/StyledComponents";
import {Col, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {IStatus} from "../../model/donations/IDonations";
import styled from "styled-components";
import {IShareDataDashboard} from "../../model/share/IShare";
import {ConfirmDialog} from "../dialogs/ConfirmDialog";
import {BASE_PATH} from "../../constants";
import axios, {AxiosError} from "axios";

const ShareDataContainer = styled(Col)`
  margin: 0;
  padding: 0;
`;

const ShareDataResults = styled(Col)`
    padding-bottom: 3rem;
    padding: 0;
    margin: 0;
`;

const ResultInformation = styled(Row)`
    width: 100%;
    padding: 0;
    margin: 0;
`;

const ResultHeader = styled(Row)`
    display: flex;
    align-items: stretch;
    width: 100%;
    margin:0 ;
    padding-right: 0 ;
    padding-left: 0 ;
`;

interface ShareDataDashboardProps {
    userId: number
}


export const ShareDataDashboard: React.FC<ShareDataDashboardProps> = ({userId}) => {
    const [dashboard, setDashboard] = useState<IShareDataDashboard[]>([]);
    const [dashboardMsg, setDashboardMsg] = useState<string>("");
    const [showSubmitDataset, setSubmitDataset] = useState<boolean>(false);
    const [selectedDonationId, setSelectedDonationId] = useState<number>(-1);
    const buildError = (error: AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            setDashboardMsg(error.response.data.detail);
        } else {
            setDashboardMsg(error.toString);
        }
    }

    const DSA: string = "DSA";
    const DATA_SET_FORM: string = "DATA_SET_FORM";
    const FILES: string = "FILES";
    const SUBMISSION: string = "SUBMISSION";
    const APPROVAL: string = "APPROVAL";
    const IMG_PATH: string = "/images/icons/";
    const SUBMISSION_NOT_STARTED: string = "Submission Not Started";
    const SUBMISSION_IN_PROGRESS: string = "Submission in Progress";
    const APPROVED: string = "APPROVED";
    const COMPLETE: string = "COMPLETE";
    const PENDING_VERIFICATION: string = "PENDING_VERIFICATION";
    const INCOMPLETE: string = "INCOMPLETE";
    const READY_FOR_ACTION: string = "READY_FOR_ACTION";
    const REJECTED: string = "REJECTED";

    const PROCEED_DATASET_FORM: string = "Please complete the dataset form to proceed.";
    const PROCEED_FILES: string = "Please upload files to proceed.";
    const PROCEED_SUBMISSION: string =
        "All required fields and files have been provided.  Your dataset appears"
        + " ready to submit.";
    const APPROVED_APPROVAL: string = "Congratulations. Your submission of shared data has been approved.";
    const DECLINED_APPROVAL: string = "Your submission of shared data has been declined.";
    const PENDING_VERIFICATION_APPROVAL: string = "Your submission of shared data is being processed";

    const NA: string = "NA";
    const REAL_WORLD: string = "REAL_WORLD";

    //--------------------------------------------------------------------------------------
    // LOAD THE DASHBOARD DATA
    //--------------------------------------------------------------------------------------
    const dsaURL = process.env.REACT_APP_API_URL + "/api/share/shareDashboard";

    const [requestState, getDashboard] = useRequest({
        url: dsaURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError
    })

    const {isLoading, data, error} = requestState;

    useEffect(() => {
        if (userId > 0) {
            getDashboard();
        }
    }, [])

    useMemo(() => {
        if (data != null) {
            setDashboard(data);
        }
    }, [data])

    useMemo( () => {
        ////
    },[dashboard])

    //--------------------------------------------------------------------------------------
    // HANDLE SUBMIT FOR CONTRIBUTION
    //--------------------------------------------------------------------------------------

    /**
     * handle submit success
     *
     * @param data - list of contributions
     */
    const handleSubmitSuccess = (data: IShareDataDashboard[]) => {
        if (data != null) {
            setDashboard(data);
            setSubmitDataset(!showSubmitDataset);

        }
    }

    /**
     * handleDeleteFileError - api throws exception
     *
     * @param error
     */
    const handleSubmitError = (error: object) => {
        console.log(error);
    }

    const [requestChangeTypeActionState, setChangeTypeAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/share/submit",
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleSubmitError,
        onSuccess: handleSubmitSuccess
    });

    // toggleDialog - show/hide submit dataset dialog
    const toggleSubmitDatasetDialog = (donationId: number) => {
        setSubmitDataset(!showSubmitDataset);
        setSelectedDonationId(donationId);
    }

    /**
     * handleSubmitDatasetDialog - user selects submit in the confirm dialog
     */
    const handleSubmitDatasetDialog = () => {
        setSubmitDataset(!showSubmitDataset);
        let formData = new FormData();
        formData.append("donationId", selectedDonationId.toString());
        setChangeTypeAction(formData);
    }

    //----------------------------------------------------------------------------
    // get various data methods
    //----------------------------------------------------------------------------

    /**
     * set status variable
     *
     * @param statusType - status type
     * @param statuses - statuses for that time
     */
    const getStatus = (statusType: string, statuses: IStatus[]) => {
        let found: IStatus | undefined = statuses.find(s => s.statusType === statusType);
        return found;
    }

    /**
     * get the submisstion status
     *
     * @param dashboardData - dashboard data
     */
    const getSubmissionStatus = (dashboardData: IShareDataDashboard) => {
        //TODO add submission for rejected status
        if (!dashboardData?.dsaApproved) {

            return SUBMISSION_NOT_STARTED;
        }
            //TODO add elseif for approved
            // else if (donation.donation.app) {
            //      return SUBMISSION_APPROVED;
            //  }

        //TODO add elseif for rejected

        else {
            return SUBMISSION_IN_PROGRESS;
        }
    }

    /**
     * get the submission progress
     *
     * @param dashboardData - dashboard data
     */
    const getSubmissionProgress = (dashboardData: IShareDataDashboard) => {
        //TODO add check for deleted dsa?

        if (!dashboardData?.dsaApproved) {
            return SUBMISSION_NOT_STARTED;
        } else {
            const datasetFormStatus: string | undefined = getStatus(DATA_SET_FORM, dashboardData.statuses)?.key;
            const filesStatus: string | undefined = getStatus(FILES, dashboardData.statuses)?.key;

            if (datasetFormStatus === COMPLETE && filesStatus !== COMPLETE) {
                return PROCEED_FILES;
            } else if (datasetFormStatus === INCOMPLETE || datasetFormStatus === READY_FOR_ACTION) {
                return PROCEED_DATASET_FORM;
            } else if (datasetFormStatus === COMPLETE && filesStatus === COMPLETE) {

                // check submission status
                const submissionStatus: string | undefined = getStatus(SUBMISSION, dashboardData.statuses)?.key;
                if (submissionStatus === READY_FOR_ACTION) {
                    return PROCEED_SUBMISSION;
                }

                // approval
                const approvalStatus: string | undefined = getStatus(APPROVAL, dashboardData.statuses)?.key;
                if (approvalStatus === PENDING_VERIFICATION) {
                    return PENDING_VERIFICATION_APPROVAL;
                } else if (approvalStatus === APPROVED) {
                    return APPROVED_APPROVAL;
                } else if (approvalStatus === REJECTED) {
                    return DECLINED_APPROVAL;
                }
            }
        }
    }

    /**
     * get the dataset title
     *
     * @param dashboardData - dashboard data
     */
    const getDatasetTitle = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.title != null) {
            return dashboardData?.title;
        } else {
            return NA;
        }
    }

    /**
     * get the study phase
     *
     * @param dashboardData - dashboard dtat
     */
    const getStudyPhase = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.studyPhase != null) {
            return dashboardData.studyPhase.join(',');
        } else {
            return NA;
        }
    }

    /**
     * get the completion data
     *
     * @param dashboardData - dashboard data
     */
    const getCompletionDate = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.completionDate != null) {
            return dashboardData?.completionDate;
        } else {
            return NA;
        }
    }

    /**
     * get the clin trial id
     *
     * @param dashboardData - dashboard data
     */
    const getClinTrialID = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.clinicalGovId != null) {
            return dashboardData?.clinicalGovId;
        } else {
            return NA;
        }
    }

    //----------------------------------------------------------------------------
    //build the display methods
    //----------------------------------------------------------------------------

    /**
     * build the dsa url
     *
     * @param dashboardData - dashboard data
     */
    const buildDSAURL = (dashboardData: IShareDataDashboard) => {
        if (dashboardData.dsaApproved) {
            return (
                <>
                    <NavMenuItem to={BASE_PATH + "dataShareAgreement/"   + dashboardData?.donationId}>
                        View DSA
                    </NavMenuItem>
                </>)
        } else {
            return (
            <NavMenuItem to={BASE_PATH + "dataShareAgreement/"   + dashboardData?.donationId}>
                View DSA Form
            </NavMenuItem>
            )
        }
    }

    /**
     * build the dataset form url
     *
     * @param dashboardData - dashboard data
     */
    const buildDatasetFormURL = (dashboardData: IShareDataDashboard) => {
        if (!dashboardData.dsaApproved) {
            return "";
        } else {
            let hrefValue: string = BASE_PATH + "shareDatasetBasics/" + dashboardData?.donationId;
            if (dashboardData.dataType === REAL_WORLD) {
                hrefValue = BASE_PATH + "shareDatasetBasicsRWD/" +  dashboardData?.donationId;
            }

            let formInstruction: string = "";
            if (dashboardData.submitted) {
                formInstruction = "View Data";
            } else if (getStatus(DATA_SET_FORM, dashboardData.statuses)?.key === READY_FOR_ACTION) {
                formInstruction = "Enter Data";
            } else if (getStatus(DATA_SET_FORM, dashboardData.statuses)?.key !== COMPLETE ||
                getStatus(DATA_SET_FORM, dashboardData.statuses)?.key !== INCOMPLETE) {
                formInstruction = "Update Data";
            }

            if (formInstruction !== "") {
                return (
                <NavMenuItem to={hrefValue}>
                    {formInstruction}
                </NavMenuItem>
            )
            }
        }
    }

    /**
     * build the files url
     *
     * @param dashboardData - dashboard data
     */
    const buildFilesUrl = (dashboardData: IShareDataDashboard) => {
        let urlLabel: string = "";
        let hrefValue = BASE_PATH + "selectFiles/" + dashboardData.donationId;
        if (getStatus(FILES, dashboardData.statuses)?.key === READY_FOR_ACTION ||
            (getStatus(FILES, dashboardData.statuses)?.key === INCOMPLETE) ||
            (getStatus(FILES, dashboardData.statuses)?.key === COMPLETE)
        ) {
            urlLabel = "Manage Files"
        }
        if (urlLabel !== "") {
            return (
            <NavMenuItem to={hrefValue}>
                {urlLabel}
            </NavMenuItem>
            )
        }
    }

    /**
     * build Submission url for submitting the contribution
     *
     * param dashboardData
     */
    const buildSubmissionUrl = (dashboardData: IShareDataDashboard) => {
        let urlLabel: string = "";
        if (getStatus(SUBMISSION, dashboardData.statuses)?.key === READY_FOR_ACTION) {
            urlLabel = "Submit Dataset"
        }
        if (urlLabel !== "") {
            return (
                <a className="dsa linkstyle" onClick={() => toggleSubmitDatasetDialog(dashboardData.donationId)}>{urlLabel}</a>
            )
        }
    }

    /**
     * build the header
     *
     * @param dashboardData - dashboard data
     */
    const buildHeader = (dashboardData: IShareDataDashboard) => {
        return (
            <ResultHeader className=" ps-0 pe-0 ">
                <Col className="study-name " lg={9}>
                    <span className="share share-title">Study Title</span>
                    <span className="share share-header">{getDatasetTitle(dashboardData)}</span>
                </Col>
                <Col className=" ps-0 pe-0 " lg={3}>
                    <ResultHeader className="w-100 ps-0 pe-0  h-100">
                        <Col className="study-phase  " lg={5}>
                            <span className="share share-title">Study Phase</span>
                            <span
                                className="share share-header">{getStudyPhase(dashboardData)}</span>
                        </Col>

                        <Col className="completion-date ps-2 " lg={7}>
                            <span className="share share-title">Completion Date</span>
                            <span
                                className="share share-header">{getCompletionDate(dashboardData)}</span>
                        </Col>
                    </ResultHeader>
                </Col>
            </ResultHeader>
        )
    }


    /**
     * build the sub header
     *
     * @param dashboardData - dashboard data
     */
    const buildSubHeader = (dashboardData: IShareDataDashboard) => {
        return (
            <ResultHeader className="w-100">
                <Col className="clin-trial ps-2 pt-1 pb-1 " lg={9}>
                    <span className="subheader">{getClinTrialID(dashboardData)}</span>
                </Col>
                <Col className="submission-status ps-2 pt-1  pb-1" lg={3}>
                    <span className="subheader">{getSubmissionStatus(dashboardData)}</span>
                </Col>

            </ResultHeader>
        )
    }

    /**
     * build the progress display
     *
     * @param dashboardData - dashboard data
     */
    const buildProgressDisplay = (dashboardData: IShareDataDashboard) => {
        return (
            <ResultHeader className="submission-status w-100 pt-3">
                <Col className="px-0 " lg={9}>
                    <ResultHeader className=" w-100 submission-status ">
                        {buildIconCell(dashboardData, DSA, "DSA Status", true, buildDSAURL)}
                        {buildIconCell(dashboardData, DATA_SET_FORM, "Dataset Form", true, buildDatasetFormURL)}
                        {buildIconCell(dashboardData, FILES, "Files", true, buildFilesUrl)}
                        {buildIconCell(dashboardData, SUBMISSION, "Submission", true, buildSubmissionUrl)}
                        {buildIconCell(dashboardData, APPROVAL, "Approval", false)}
                    </ResultHeader>
                </Col>
                <Col className=" ps-2" lg={3}>
                    <span className="subheader-title">{getSubmissionProgress(dashboardData)}</span>
                </Col>
            </ResultHeader>
        )
    }


    /**
     * build the icon cell
     *
     * @param dashboardData - dashboard data
     * @param statusValue - status of cell
     * @param statusText - text for the cell
     * @param hasURLMethod - link for the cell
     * @param urlMethod - method for click of link
     */
    const buildIconCell = (dashboardData: IShareDataDashboard, statusValue: string, statusText: string, hasURLMethod: boolean,
                           urlMethod?: any) => {
        return (
            <Col className="icon  ">
                <span className="w-100 icon bold-label share-title text-center">
                    <img className="mx-auto"
                         src={IMG_PATH + getStatus(statusValue, dashboardData.statuses)?.icon}
                         alt="Waiting icon"
                         width="32" height="32"/>
                </span>
                <span
                    className="w-100 icon bold-label share-title text-center">{statusText}</span>
                <span
                    className="w-100 icon share-title text-center "> {getStatus(statusValue, dashboardData.statuses)?.description}</span>
                {hasURLMethod && (
                    <span className="w-100 icon share-title text-center mt-1 pb-2"> {urlMethod(dashboardData)}</span>
                )}
            </Col>
        )
    }

    /**
     * build the dashboard
     */
    const buildDashboard = () => {
        if (dashboard?.length > 0) {
            return (
                <>
                    {dashboard?.map((dashboardData: IShareDataDashboard) =>
                        <ShareDataContainer key={dashboardData.donationId} className="mb-2">
                            <ShareDataResults className="">
                                <ResultInformation>
                                    {buildHeader(dashboardData)}
                                    {buildSubHeader(dashboardData)}
                                    {buildProgressDisplay(dashboardData)}
                                </ResultInformation>
                            </ShareDataResults>
                        </ShareDataContainer>
                    )}
                </>
            )
        }
    }

    //----------------------------------------------------------------------------
    // return the html to display
    //----------------------------------------------------------------------------
    return (
        <>
            <ConfirmDialog title={"Submit Dataset"} body={<p>Are you ready to submit?</p>}
                           show={showSubmitDataset} showHide={toggleSubmitDatasetDialog}
                           submitAction={handleSubmitDatasetDialog} primaryButtonText={"Yes"}
                           secondaryButtonText={"No"}/>
            <Row>
                <div className={"error-message"}>{dashboardMsg}</div>
            </Row>
            <FormLayoutColumn className="" name={"formLayoutColumn"}>
                {isLoading &&
                    <Spinner  className ="spinner-center" animation={"border"} variant={"primary"}  role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {data && buildDashboard()}
            </FormLayoutColumn>
        </>

    );

}
