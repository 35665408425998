/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminAdditionalResourceAction
 *
 * Purpose: Admin User Approval
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 04Dec2024 sasjxa file created
 \******************************************************************************/
import React, {Fragment, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
// @ts-ignore
import {
    ContentPage,
    FormLayoutColumn, GridRow,
    PageWrapper,
    PDS_H5,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";

import {
    ACCOUNT_DECLINE_REASONS,
    APPROVED,
    DECLINE_USER,
    DECLINED,
    FormList, max1000Count, max2000Count,
    NEW,
    OTHER_OPTION,
    RMI_ENROLLMENT_REASONS,
    RMI_USER,
    SUBMIT,
    USER_APPROVAL,
} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {useRequest} from "../../helper/useRequest";
import {IAdminDispositionUser, IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {IAdminAdditionalResource} from "../../model/announcements/IAdminAdditionalResource";
import {TextFieldRequired} from "../../components/forms/TextFieldRequired";
import {dataSummaryDesc} from "../../constants/ShareDataFieldsText";
import {TextAreaRequired} from "../../components/forms/TextAreaRequired";
import {CancelAndSubmitButtonContainer} from "../../components/buttonContainer/CancelAndSubmitButtonContainer";
import {IAdminAnnouncement} from "../../model/announcements/IAdminAnnouncement";
import styled from "styled-components";
import {COLORS} from "../../components/styled/StyleConstants";

const CheckBoxRow = styled(Row)`
    padding-left: 12px;
`;


export const AdminAdditionalResourceAction: React.FC = () => {

    const {additionalResourceId} = useParams();
    const {showBoundary} = useErrorBoundary();
    const [title, setTitle] = useState("");
    const [url, setUrl] = useState("");
    const [description, setDescription] = useState("");
    const [isCheckedVisible, setIsCheckedVisible] = useState(false);
    const [descriptionCount, setDescriptionCount] = useState(0);
    const navigate = useNavigate();
    const formikRef = useRef<FormikProps<any>>(null);
    const [additionalResource, setAdditionalResource] = useState<IAdminAdditionalResource>();

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        setIsWaitingForResponse(false);
        showBoundary(error);
    }

    // handle cancel
    const handleResetAction = () => {
        navigate(BASE_PATH + 'admin/additionalResources/ACTIVE')
    }

    // handle successful save of data
    const handleSuccess = () => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + 'admin/additionalResources/ACTIVE')
    }

    // toggle is visible field
    const handleVisibleChanged = (value: boolean) => {
        setIsCheckedVisible(value);
    }

    //get data
    const requestURL = process.env.REACT_APP_API_URL + "/api/admin/additionalResources/find/" + additionalResourceId;
    const [requestState, getAdditionalResource] = useRequest({
        url: requestURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // save data
    const actionURL = process.env.REACT_APP_API_URL + "/api/admin/additionalResources/addOrEditAdditionalResource";
    const [requestActionState, setAdditionalResourceAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })


    useMemo(() => {
        if (additionalResourceId != null) {
            getAdditionalResource();
        }

    }, [])

    useMemo(() => {
        if (data != null) {
            setTitle(data.title);
            setUrl(data.url);
            setDescription(data.description);
            setIsCheckedVisible(data.visible);
            setAdditionalResource(data);
        }
    }, [data])


    // validation schema
    const schema = Yup.object().shape({
        title: Yup.string().required("Please enter a title."),
        url: Yup.string().required("Please enter a url."),
        description: Yup.string().required("Please enter a description."),
    });


    const buildForm = () => {
        return (
            <>
                <Formik innerRef={formikRef}
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => {
                            setIsWaitingForResponse(true);
                            if (additionalResourceId !== null) {
                                setAdditionalResourceAction(JSON.stringify({
                                    id: additionalResourceId,title: values.title, url: values.url,
                                    description: values.description,
                                    visible: isCheckedVisible

                                }));
                            }
                            else {
                                setAdditionalResourceAction(JSON.stringify({
                                    title: values.title, url: values.url,
                                    description: values.description,
                                    visible: isCheckedVisible

                                }));
                            }
                        }}
                        initialValues={{
                            title: title ?? "",
                            url: url ?? "",
                            description: description ?? "",
                            visible: isCheckedVisible

                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn lg={12}>
                                <FormLayoutColumn lg={8}>
                                    <Row className="justify-content-center mt-3 mb-3">
                                        <TextFieldRequired fieldName={"title"} label="Resource Label"
                                                           value={title}
                                                           valueHandler={setTitle}
                                                           disabled={false}
                                                           touched={touched} errors={errors} size={12}/>

                                    </Row>
                                    <Row className="justify-content-center mt-3 mb-3">
                                        <TextFieldRequired fieldName={"url"} label="URL"
                                                           value={url}
                                                           valueHandler={setUrl}
                                                           disabled={false}
                                                           touched={touched} errors={errors} size={12}/>
                                    </Row>
                                    <Row className="mt-3 mb-3 justify-content-center">
                                        <TextAreaRequired fieldName={"description"} label="Description"
                                                          value={description} valueHandler={setDescription}
                                                          currentCharCount={descriptionCount}
                                                          maxTextAreaCount={max1000Count}
                                                          countHandler={setDescriptionCount} disabled={false}
                                                          touched={touched} errors={errors}
                                                          handleChange={handleChange}/>
                                    </Row>
                                    <CheckBoxRow className="mt-3 mb-5  ">
                                        <Form.Group as={Col} controlId="">
                                            <Form.Check className="bold_label" required type={"checkbox"}
                                                        disabled={false}
                                                        label="Is visible"
                                                        checked={values.visible}

                                                        onChange={e => {
                                                            handleVisibleChanged(!isCheckedVisible);
                                                            handleChange(e);
                                                        }}>
                                            </Form.Check>

                                        </Form.Group>
                                    </CheckBoxRow>
                                </FormLayoutColumn>
                            </FormLayoutColumn>
                            <CancelAndSubmitButtonContainer handleReset={handleResetAction} submitButtonText={SUBMIT}/>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {additionalResourceId != null && (
                            <>
                                {isLoading && (
                                    <>
                                        <Spinner className="spinner-center" animation={"border"} variant={"primary"}
                                                 role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </>
                                )}
                                {error && <p>Error</p>}
                                {data && buildForm()}
                            </>

                        )}
                        {additionalResourceId == null && (
                                buildForm()
                        )}


                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
