/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Styles
 *
 * Purpose: basic styles
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 16Feb2023 craig File created.
 \******************************************************************************/
import {ListGroup, NavLink, Button} from "react-bootstrap";
// @ts-ignore
import styled from 'styled-components';
import {COLORS, FONT_SIZES} from './StyleConstants';

export const PDS_LI = styled(ListGroup.Item)`
  font-size: ${FONT_SIZES.px12};//.8125rem;
  font-weight: 400;
  line-height: 1.5;
`;
export const PDS_Button = styled(Button)`
  font-weight: 700;
  font-size: 14pt !important;
  letter-spacing: 0.25px;
  line-height: 1.2rem;
  padding: 12px 46px;
  min-width: 70px;
  max-width: 475px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  margin: 0px 10px;
  border: 0;
  outline: 0;
  display: inline-block;
`;

export const PDS_P = styled.p`
    font-family: 'Source Sans Pro', sans-serif;
    
`;