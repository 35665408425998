/******************************************************************************\
 * :$
 *
 * Copyright(c) 2025 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: axiosInstance
 *
 * Purpose: Wrapper for axios calls
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 07Jan2025 craig File created.
 * 23Jan2025 craig added withCredentials
 \******************************************************************************/
import axios, {AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {IPDSErrorResponse} from "../../model/error/IPDSErrorResponse";

const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // API base URL
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Access-Control_Allow-Origin': "*",
        'Access-Control-Allow-Credentials': "true"
    },
    timeout: 10000, // 10 seconds timeout
});

/**
 * Add request interceptors to modify or log requests
 */
axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig<any>) => {
        config.withCredentials = true;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

/**
 * Add response interceptors to handle responses globally
 */
axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        // You can transform data or handle responses here
        return response.data; // or return response if you want full response
    },
    (error: AxiosError<IPDSErrorResponse>) => {
        // Global error handling
        if (error.response) {
            // Request made and server responded with a status code
            console.error('Response Error:', error.response);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request Error:', error.request);
        } else {
            // Something else happened while setting up the request
            console.error('General Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;