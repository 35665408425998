/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Header
 *
 * Purpose: Header for PDS application
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Feb2023 craig  file created.
 * 02May2023 sasjxa add component for mobile menu
 * 02Jun2023 sasjxa move styled components out of FC
 * 05Jul2023 sasjxa add support for launching sign in dialog
 * 04Aug2023 sasjxa corrections for nav bar
 * 10Aug2023 sasjxa rework to show sign in dropdown after successful login
 * 16Nov2023 sasjxa use centered dropdown instead of dialog for sign in
 * 10Dec2023 craig  Added nciRequestsView to users dropdown
 * 19Dec2023 sasjxa Remove error handler from login form
 * 28Jan2024 craig  Added buildExpireWarningMessage and buildExpiredMessage
 * 29Jan2024 sasjxa Disable expired messages when clicked
 * 13Feb2024 craig  Removed expired messages disabled
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 20Mar2024 sasjxa eliminate show/hide error (MPM-5414)
 * 16Apr2024 sasjxa change order of nav dropdowns
 * 30Apr2024 sasjxa fix button text centering
 * 13Jun2024 sasjxa add decoding for user's name displayed in dropdown
 * 21Aug2024 craig  moved to useContext(PDSContext) for user session
 * 21Nov2024 sasjxa add message for password expiration
 * 19Dec2024 sasjxa get data for site messages
 * 02Jan2025 craig  removed console.log
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Dropdown, Image, NavDropdown, Spinner} from "react-bootstrap";
// @ts-ignore
import styled from 'styled-components';
import {PDSGroup} from "../model/user/IUser";
import {COLORS, FONT_SIZES, SIZES} from "./styled/StyleConstants";
import {Link, NavLink} from "react-router-dom";
import {PDS_P} from "./styled/StyledComponents";
import {LoginForm} from "./forms/LoginForm";
import {BASE_PATH} from "../constants";
import {usePDSContext} from "../context/PDSContext";
import {useRequest} from "../helper/useRequest";
import {IAdminSiteMessage} from "../model/announcements/IAdminSiteMessage";
import parse from "html-react-parser";


const HeaderWrapper = styled.div`
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 19%), 0 0 10px 0 rgb(0 0 0 / 8%);
    background-image: linear-gradient(to bottom, #f4f4f4, #FFFFFF);

    @media (min-width: 768px) {
        flex-direction: column;
    }
`;

const HeaderBody = styled.div`
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: normal;
    margin-left: 3%;

    @media (min-width: 768px) {
        max-width: ${SIZES.MAX_WIDTH};
        width: 100%;
        height: 6.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 2% 10px;
        margin-left: 0;


    }
`;

const NavMobileSmallContainer = styled.div`
    display: block;
    margin-top: 1%;
    margin-bottom: auto;
    height: 100%;

    @media (min-width: 768px) {
        display: none;
    }
`;

const HeaderLogo = styled.div`
    margin-top: 1%;

    @media (min-width: 768px) {
        width: 200px;
        max-width: 200px;
        height: 75px;
        max-height: 75px;
        margin-right: 2%;
        margin-top: 0;
    }

    @media (min-width: 1024px) {
        width: 181px;
        max-width: 225px;
        height: 75px;
        max-height: 75px;
    }
`;

const ImgLogo = styled(Image)`
    width: 181px;
    height: 75px;
`;

const HeaderNavigation = styled.div`
    display: block;
    margin: 1% 0;

    a {
        padding: 0;
    }

    @media (min-width: 768px) {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        a {
            padding: 5px 10px;
        }

        margin: 0;

    }
`;

const NavRowTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 0;
        height: 50%
    }

    @media (min-width: 1024px) {
        justify-content: flex-end;
    }
`;

const NavMessageBox = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-image: linear-gradient(to bottom, #bdccdb, #FFFFFF);
    padding: 10px;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const NavMessage = styled.div`
    min-width: 1024px;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 4%;
    
    span {
        display: inline-block;        
        padding-left: 5px;      
    }
`;

const SignInRegistration = styled.div`
    display: flex;
    align-items: center;
`;

const NavRowTopMenuItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const NavRowBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: end;
        height: 50%
    }

    @media (min-width: 1024px) {
        padding-top: .5%;
    }
`;

const NavMenuItem = styled(NavLink)`
    :first-child {
        padding-left: 0px;
    }

    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    text-decoration: none;
    font-size: ${FONT_SIZES.px16};
    font-weight: 400;
    color: ${COLORS.cerulean};
`;

const NavMenuItemBar = styled(NavMenuItem)`
    @media (min-width: 768px) {
        padding-right: 20px;
        border-right: solid 1px #6495ED;
        margin-top: 1px;
    }
`;

const NavMenuItemReg = styled(NavMenuItem)`
    display: none;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 2rem;
        line-height: 2rem;
        margin-left: auto;

        :hover {
            background-color: ${COLORS.hover_green};
            color: ${COLORS.white};
        }

        background-color: ${COLORS.turtle_green};
        color: ${COLORS.white};
        font-size: ${FONT_SIZES.px13} !important;
        padding: 20px 40px;
        font-weight: 700;
    }

    @media (min-width: 768px) {
        margin-left: 50px;
    }

    @media (min-width: 1024px) {
        margin-left: 20px;
    }
`;


interface HeaderProps {
    logoffHandler: any
}

export const Header: React.FC<HeaderProps> = ({logoffHandler}: HeaderProps) => {
    const {sessionUser} = usePDSContext();
    const inputRefButton = useRef<HTMLButtonElement>(null);
    const [siteMessages, setSiteMessages] = useState<IAdminSiteMessage[]>([]);

    /**
     * toggle the login dialog
     */
    const toggleLoginForm = () => {
        inputRefButton.current?.click()
    }

    /**
     * handle error - unsuccessful retrieve of data
     *
     * @param error
     */
    const handleError = (error: object) => {
        //console.log(error);
    }


    // get data from api
    const requestsURL = process.env.REACT_APP_API_URL + "/api/public/announcements/getSiteMessages/ACTIVE";
    const [requestState, getMessages] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;


    /**
     * useMemo on load to get messages from api
     */
    useMemo(() => {
        getMessages();
    }, [])

    /**
     * useMemo when return from api to set the site messages
     */
    useMemo(() => {
        if (data != null) {
            setSiteMessages(data);
        }
    }, [data])


    /**
     * check if user attestation will expire in the next 30 days and display a message if true
     */
    const buildExpireWarningMessage = () => {
        if (sessionUser.userExpiration?.daysToExpired <= 30 && !sessionUser.userExpiration?.expired) {
            const expired: string = new Date(sessionUser.userExpiration.expirationDt).toLocaleDateString("en-us", {
                month: "long",
                day: "numeric",
                year: "numeric"
            });
            return <NavMessageBox>
                <NavMessage>
                    <Image src={"/images/icons/i_alert.png"}/>
                    <span>Your account will expire on {expired}. You can reactivate it by <Link
                        to={BASE_PATH + "registration/reactivate/" + sessionUser.userExpiration.randomKey}>clicking here</Link></span>
                </NavMessage>
            </NavMessageBox>
        }
    }

    /**
     * check if user attestation has expired and display message if true
     */
    const buildExpiredMessage = () => {
        if (sessionUser.userExpiration?.expired) {
            const expired: string = new Date(sessionUser.userExpiration.expirationDt).toLocaleDateString("en-us", {
                month: "long",
                day: "numeric",
                year: "numeric"
            });
            return <NavMessageBox>
                <NavMessage>
                    <Image src={"/images/icons/i_alert.png"}/>
                    <span>Your account expired on {expired}. You can reactivate it by <Link
                        /*onClick={toggleExpiredMessage}*/
                        to={BASE_PATH + "registration/reactivate/" + sessionUser.userExpiration.randomKey}>clicking here</Link></span>
                </NavMessage>
            </NavMessageBox>
        }
    }

    /**
     * build site messages if present
     */
    const buildSiteMessages = () => {
        return (
            <>
                {siteMessages?.map((siteMessage: IAdminSiteMessage) => (
                    <NavMessageBox>
                        <NavMessage>
                            <Image src={"/images/icons/i_alert.png"}/>
                            <span>{parse(siteMessage.message)}</span>
                        </NavMessage>
                    </NavMessageBox>
                ))}
            </>
        )
    }

    /**
     * check if user password will expire in the next 30 days and display a message if true
     */
    const buildPasswordExpireWarningMessage = () => {
        const today = new Date();
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);
        const userPwdExpires = sessionUser.passwordExpires;
        // const userPwdExpires = new Date();
        // userPwdExpires.setDate(today.getDate() - 15);
        if (userPwdExpires != null && userPwdExpires > thirtyDaysAgo && userPwdExpires < today) {
            const expired: string = new Date(userPwdExpires).toLocaleDateString("en-us", {
                month: "long",
                day: "numeric",
                year: "numeric"
            });
            return <NavMessageBox>
                <NavMessage>
                    <Image src={"/images/icons/i_alert.png"}/>
                    <span>Your password will expire on {expired}. You can reset it by <Link
                        to={BASE_PATH + "myAccount"}>clicking here</Link></span>
                </NavMessage>
            </NavMessageBox>
        }
    }

    /**
     * build the top nav bar
     */
    const topNavBar = () => {
        if (sessionUser.userID > 0) {
            return <NavRowTop>
                <NavRowTopMenuItems>
                    <NavMenuItemBar to={"https://www.projectdatasphere.org/"}>PDS Home</NavMenuItemBar>
                    <NavDropdown
                        title={
                            <>
                                <span className="nav-dropdown-title">Signed in as </span>
                                <span className="color-dropdown">{sessionUser.username}</span>
                            </>
                        }

                        id="nav-dropdown" align={"end"} className={"color-dropdown"}>
                        <p> &nbsp;&nbsp;{decodeURIComponent(sessionUser.name)}&nbsp;&nbsp;</p>
                        <NavDropdown.Item className={"mb-1"}>
                            <NavMenuItem className={"color-dropdown"} to={BASE_PATH + "MyAccount"}>My
                                Account</NavMenuItem>
                        </NavDropdown.Item>
                        <NavDropdown.Item className={"mb-1"}>
                            <NavMenuItem className={"color-dropdown "} to={BASE_PATH + "ServiceTickets"}>Service
                                Tickets</NavMenuItem>
                        </NavDropdown.Item>

                        <NavDropdown.Item className={"mb-1"}>
                            <NavMenuItem className={"color-dropdown"} to={BASE_PATH + "nciRequestsView"}>NCI
                                Requests</NavMenuItem>
                        </NavDropdown.Item>
                        <NavDropdown.Item className={"mb-1"}>
                            <NavMenuItem className={"color-dropdown"} to={BASE_PATH + "EmailPreferences"}>Email
                                Preferences</NavMenuItem>
                        </NavDropdown.Item>
                        <NavDropdown.Item className={"mb-1"}>
                            <NavMenuItem className={"color-dropdown "} to={"/api/logout"} onClick={logoffHandler}>Sign
                                Out</NavMenuItem>
                        </NavDropdown.Item>
                    </NavDropdown>
                </NavRowTopMenuItems>
            </NavRowTop>
        } else {
            return <NavRowTop>
                <NavMenuItemBar to={"https://www.projectdatasphere.org/"}>PDS
                    Home</NavMenuItemBar>

                <SignInRegistration>
                    <Dropdown align={"end"} drop={"down-centered"} onToggle={toggleLoginForm}>
                        <Dropdown.Toggle ref={inputRefButton} variant="link" id="dropdown-basic">
                            Sign In
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <LoginForm showHide={toggleLoginForm} showDropdown={toggleLoginForm}
                            />
                        </Dropdown.Menu>
                    </Dropdown>

                    <NavMenuItemReg to={BASE_PATH + "registration"}>Register for Access</NavMenuItemReg>
                </SignInRegistration>
            </NavRowTop>
        }
    }

    /**
     * build the user bottom nav bar
     */
    const userBottomNavBar = () => {
        return <NavRowBottom>
            <NavMenuItem to={BASE_PATH + "home"}>Home</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "access"}>Access Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "share"}>Share Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "resources"}>Resources</NavMenuItem>
        </NavRowBottom>
    }

    /**
     * build the admin bottom nav bar
     */
    const adminBottomNavBar = () => {
        return <NavRowBottom>
            <NavMenuItem to={BASE_PATH + "home"}>Home</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "access"}>Access Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "share"}>Share Data</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "resources"}>Resources</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "clinicalDrugAdditivity"}>Clinical Drug</NavMenuItem>
            <NavMenuItem to={BASE_PATH + "admin/dashboard"}>Admin</NavMenuItem>
        </NavRowBottom>
    }

    /**
     * build the bottom nav bar
     */
    const bottomNavBar = () => {
        if (sessionUser.pdsGroup === PDSGroup.Approver || sessionUser.pdsGroup === PDSGroup.Admin) {
            return adminBottomNavBar();
        } else {
            return userBottomNavBar();
        }
    }

    /**
     * build the messages
     */
    const buildMessages = () => {
        return (
            <>
                {buildExpiredMessage()}
                {buildExpireWarningMessage()}
                {buildPasswordExpireWarningMessage()}
            </>
        )
    }

    /**
     * return the html
     */
    return <HeaderWrapper className={"HeaderWrapper"}>
        <HeaderBody className={"HeaderBody"}>
            <HeaderLogo className={"pds-logo"}>
                <ImgLogo src={"/images/PDSLogo_small.png"}/>
            </HeaderLogo>

            <HeaderNavigation className={"HeaderNavigation"}>
                {topNavBar()}
                {bottomNavBar()}
            </HeaderNavigation>
        </HeaderBody>

        <NavMobileSmallContainer className={"menu-small"}>
            <PDS_P>menu</PDS_P>
        </NavMobileSmallContainer>
        {sessionUser != null && sessionUser.pdsGroup != PDSGroup.Guest && (
            buildMessages()
        )}
        {isLoading &&
            <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>}
        {error && <p>Error</p>}
        {data && buildSiteMessages()}
    </HeaderWrapper>
}