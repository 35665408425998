/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminFileDeleteDialog
 *
 * Purpose: Dialog for deleting dataset files
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11Nov2024 sasjxa file created
 \******************************************************************************/
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {DialogHeader, PDS_P_BOLD, TextAreaCountRow} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik, FormikProps} from "formik";
import {PDS_P} from "../styled/Styles";
import * as Yup from "yup";
import {useRequest} from "../../helper/useRequest";

const FileNameCol = styled(Col)`
    margin-left: 11%;   
`;

interface DialogProps {
    donationId: string | undefined,
    fileId : number;
    filename: string,
    show:boolean,
    showHide:any,
    submitAction:any
}



export const AdminFileDeleteDialog: React.FC<DialogProps> = ({
                                                               donationId,
                                                               filename, fileId,
                                                               show,
                                                               submitAction, showHide
                                                           }: DialogProps) => {
    const formikRef = useRef<FormikProps<any>>(null);
    const [commentsCount, setCommentsCount] = useState(0);
    const [comments, setComments] = useState("");
    const maxCommentsCount = 1000;


    const handleError = (error: object) => {
        console.log(error);
    }


    // create the file log
    const createLogEntry = () => {
        setFileLogAction(JSON.stringify({
            fileAction: "DELETE",
            comments: comments,
            fileId : "-1",
            oldFileId : fileId.toString(),
        }));
    }

    // delete file
    const actionURL = process.env.REACT_APP_API_URL + "/api/admin/dataset/files/delete/" +
        donationId + "/" + fileId;
    const [requestActionState, setDeleteFileAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: createLogEntry

    })


    // save log
    const logURL = process.env.REACT_APP_API_URL + "/api/admin/dataset/files/logEntry";
    const [logActionState, setFileLogAction] = useRequest({
        url: logURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: submitAction
    })


    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const getFileToBeDeleted= () => {
        return (
            <>
                <FileNameCol className="">
                <PDS_P_BOLD className="ms-5 mb-0">File to Delete:</PDS_P_BOLD>
                <PDS_P  className="ms-5">{filename}</PDS_P>
                </FileNameCol>

            </>
        )
    }



    // validation schema
    const schema = Yup.object().shape({
        comments: Yup.string().required("Please enter comments"),
    });


    const buildForm = () => {
        return (
            <>
                <Formik innerRef={formikRef}
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => {
                            setDeleteFileAction();
                        }}
                        initialValues={{
                            comments:  comments ?? '',

                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          touched,
                          errors
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>


                                    <Row className="mt-3 mb-3 justify-content-center">
                                        <Form.Group as={Col} lg={8} className="mb-3"
                                                    controlId="formComments">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>
                                                <span>{commentsCount} of {maxCommentsCount} characters</span>
                                            </TextAreaCountRow>

                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comments"}
                                                          type="text"
                                                          value={values.comments}
                                                          isValid={touched.comments && !errors.comments}
                                                          onChange={e => {
                                                              setComments(e.target.value);
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <Form.Control.Feedback type="invalid">
                                                Please select an action
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"comments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>

                        </Form>

                    )}
                </Formik>
            </>
        )
    }


    return (
        <Modal show={show} size={"lg"}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Delete File</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                <>
                    <Col>
                        <PDS_P className={"text-center"}>Are you sure you want to delete this file?</PDS_P>
                        {getFileToBeDeleted()}
                        {buildForm()}

                    </Col>
                </>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                        onClick={() => {
                            handleSubmit();
                        }}>
                    Submit
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
