/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminFileEditDialog
 *
 * Purpose: Dialog for editing dataset files
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 11Nov2024 sasjxa file created
 * 22Dec2024 ssjxa  change handling of file selector
 \******************************************************************************/
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import React, {useRef, useState} from "react";
import {DialogHeader, FormLayoutColumn, LinkButton, PDS_P_BOLD, TextAreaCountRow} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik, FormikProps} from "formik";
import {IShareDataDashboard} from "../../model/share/IShare";
import {IFile} from "../../model/donations/IAccessData";
import {
    DATA_DESC,
    DATA_DICTIONARY_DESC,
    DATA_SUMMARY_DESC,
    DATA_TYPE_COMPARATOR_RAW,
    FILES_SELECT,
    FormList,
    SelectFilesList,
    validCRFProtocolFileTypes,
    validDataDictionaryFileTypes,
    validDataFileTypes,
    ZIP_DESC
} from "../../data/formList";
import {PDS_P} from "../styled/Styles";
import * as Yup from "yup";
import {useRequest} from "../../helper/useRequest";

interface DialogProps {
    donationId: string | undefined,
    dashboard: IShareDataDashboard | undefined,
    title?: string | undefined,

    fileId: number,
    filename: string,
    fileDesc: string,
    fileType?: string,
    handleFileChange: any,
    show: boolean,
    handleFileAdded?: any
    showHide: any,
}


export const AdminFileEditDialog: React.FC<DialogProps> = ({
                                                               donationId,
                                                               dashboard, title,
                                                               show, fileId, filename, fileDesc,fileType,
                                                               handleFileChange, showHide
                                                           }: DialogProps) => {
    const formikRef = useRef<FormikProps<any>>(null);
    const [addedFileId, setAddedFileId] = useState<number>(-1);
    // const [replacedFileId, setReplacedFileId] = useState<number>(-1);
    const [commentsCount, setCommentsCount] = useState(0);
    const [comments, setComments] = useState("");
    const [fileSelected, setFileSelected] = useState(false);

    const maxCommentsCount = 1000;
    const PROTOCOL_DESC = "Protocol";
    const CRF_DESC = "CRF";


    const handleError = (error: object) => {
        console.log(error);
    }

    // handle upload error
    const handleUploadError = (error: object) => {
        console.log(error);
    }

    // handle upload success.. create file log
    const handleUploadSuccess = (file: IFile) => {
        setAddedFileId(file.id);
        // file added
        if (fileId == -1) {
            createLogEntry(file);
        }

        // replace action. new file has been uploaded, time to delete the current files
       else {
            setDeleteFileAction();
        }
    }

    // handle upload success.. create file log
    const createLogEntry = (file: IFile) => {
        // file added
        if (fileId == -1){
            setFileLogAction(JSON.stringify({
                fileAction: "ADD",
                comments: comments,
                fileId: file.id.toString(),
                oldFileId: fileId.toString(),
            }));
        }
        else {
            setFileLogAction(JSON.stringify({
                fileAction: "REPLACE",
                comments: comments,
                fileId: addedFileId,
                oldFileId: fileId.toString(),
            }));

        }

    }

    //upload file request
    const fileUploadUrl = process.env.REACT_APP_API_URL + "/api/admin/dataset/files/upload/" + donationId;
    const [requestActionState, setUpdateFileAction] = useRequest({
        url:  fileUploadUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleUploadError,
        onSuccess: handleUploadSuccess
    });

    // delete file
    const actionURL = process.env.REACT_APP_API_URL + "/api/admin/dataset/files/delete/" +
        donationId + "/" + fileId;
    const [deleteActionState, setDeleteFileAction] = useRequest({
        url: actionURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: createLogEntry

    })

    // save log
    const logURL = process.env.REACT_APP_API_URL + "/api/admin/dataset/files/logEntry";
    const [logActionState, setFileLogAction] = useRequest({
        url: logURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleFileChange
    })

    // get valid fileTypes based on file description value
    const getValidFileTypes = () => {
        if (fileDesc === PROTOCOL_DESC || fileDesc === CRF_DESC) {
            return validCRFProtocolFileTypes;
        } else if (fileDesc === DATA_DICTIONARY_DESC) {
            return validDataDictionaryFileTypes;
        } else {
            return validDataFileTypes;
        }
    }

    // return boolean indicating whether file is DATA, ZIP, or Data Summary or if adding a new Data file
    const isDataFileTypeToBeAddedOrReplaced = () => {
        if (fileId != -1) {
            if (fileDesc === DATA_DESC || fileDesc === DATA_SUMMARY_DESC ||
                fileDesc === ZIP_DESC) {
                return true;
            } else {
                return false;
            }
        }

        // can only add data file types
        else {
            return true;
        }
    }

    // build select list for data types
    const buildSelectList = () => {
        // default value
        let listType: FormList[] = DATA_TYPE_COMPARATOR_RAW;

        let searched_obj: SelectFilesList | undefined = FILES_SELECT.find(findSelectList);
        if (searched_obj != null) {
            listType = searched_obj.valueList;
        }

        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const findSelectList = (fileEntry: SelectFilesList) => {
        if (fileEntry.dsaType === dashboard?.dataType && fileEntry.studyContent === dashboard?.studyContent) {
            return true;
        }
        return false;
    }

    const handleSubmit = () => {
        if (formikRef.current) {
            formikRef.current.handleSubmit()
        }
    }

    const handleFileSelected = () => {
        setFileSelected(true);
    }

    // display file name to be replaced
    const getFileToBeReplaced = () => {
        return (
            <>
                <Col className="ms-5 ">
                    <PDS_P_BOLD className="ms-5 mb-0">File to Replace:</PDS_P_BOLD>
                    <PDS_P className="ms-5">{filename}</PDS_P>
                </Col>
            </>
        )
    }

    // validation schema
    const schema = Yup.object().shape({
        fileUploaded: Yup.mixed().required('Please select a file'),
        comments: Yup.string().required("Please enter comments"),
    });


    const buildForm = () => {
        return (
            <>
                <Formik innerRef={formikRef}
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => {
                            if (values.fileUploaded !== null) {
                                let data: FormData = new FormData();
                                data.append("file", values.fileUploaded);
                                 data.append("fileType", isDataFileTypeToBeAddedOrReplaced()? values.fileType: fileType);
                                 data.append("fileDesc", fileDesc);
                                setUpdateFileAction(data);
                            }
                        }}
                        initialValues={{
                            comments:  '',
                            fileType: "",

                        }}
                        validateOnChange={false}
                        validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          values,
                          setFieldValue,
                          touched,
                          errors
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <FormLayoutColumn lg={12}>
                                <FormLayoutColumn lg={8}>
                                    <Row className="mb-3 ">
                                        <Form.Group as={Col} lg={12} required className="mb-3 "
                                            /*controlId="formFileUpload"*/>
                                            <Form.Label className="required" column="sm">
                                                <LinkButton type="button"
                                                            className="btn btn-link bold-label required"
                                                >
                                                    Choose File

                                                </LinkButton>
                                            </Form.Label>


                                            <Form.Control id="fileUploaded " type="file" size="sm"
                                                          name={"fileUploaded"}
                                                          accept={getValidFileTypes()}
                                                          value={undefined}
                                                          onChange={(event: any) => {
                                                              setFieldValue("fileUploaded", event.currentTarget.files[0]);
                                                              handleFileSelected();
                                                          }}
                                                          isValid={touched.fileUploaded && !errors.fileUploaded}
                                            />
                                        </Form.Group>
                                        <ErrorMessage name={"fileUploaded"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>


                                    </Row>
                                    {fileSelected && isDataFileTypeToBeAddedOrReplaced() && (
                                        <div className={"selectorFileType-div"}>
                                            <Form.Label className="required" column="sm">Data Type </Form.Label>


                                            <Form.Select size="sm" required
                                                         name={"fileType"}
                                                         value={undefined}
                                                         onChange={e => {
                                                             setFieldValue("fileType", e.currentTarget.value);
                                                         }}>
                                                {buildSelectList()}
                                            </Form.Select>
                                        </div>
                                    )}

                                    <Row className="mt-3 mb-3 justify-content-center">
                                        <Form.Group as={Col} lg={12} className="mb-3"
                                                    controlId="formComments">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>
                                                <span>{commentsCount} of {maxCommentsCount} characters</span>
                                            </TextAreaCountRow>

                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comments"}
                                                          type="text"
                                                          value={values.comments}
                                                          isValid={touched.comments && !errors.comments}
                                                          onChange={e => {
                                                              setComments(e.target.value);
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <ErrorMessage name={"comments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                </FormLayoutColumn>
                            </FormLayoutColumn>

                        </Form>

                    )}
                </Formik>
            </>
        )
    }


    return (
        <Modal show={show} size={"lg"}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>{title}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                <>

                    <Col className="">
                        {fileId > 0 && (
                            getFileToBeReplaced()
                        )}
                        {buildForm()}
                    </Col>
                </>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                        onClick={() => {
                            handleSubmit();
                        }}>
                    Submit
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
