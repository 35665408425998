/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminSendTestDialog
 *
 * Purpose: Dialog for sending email to admins
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Nov2024 sasjxa file created
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React from "react";
import {DialogHeader} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {useRequest} from "../../helper/useRequest";

interface DialogProps {
    announcementId: number,
    show: boolean,
    showHide: any,
}

export const AdminSendEmailTestDialog: React.FC<DialogProps> = ({ announcementId,
                                                                           show,
                                                                           showHide
                                                                       }: DialogProps) => {

    const handleError = (error: object) => {
        console.log(error);
    }



    // send  message to admins
    const sendMessageURL = process.env.REACT_APP_API_URL + "/api/admin/announcements/sendTest/" + announcementId;
    const [getSendSate, sendMessageAction] = useRequest({
        url: sendMessageURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: showHide,
    })
    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Send Email</DialogHeader>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to send this email to  PDS Admins?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                        onClick={() => {
                            sendMessageAction();
                        }}>
                    Send
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
