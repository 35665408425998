/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminPlatformAnnouncement
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 12Dec2024 sasjxa file created
 \****************************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink} from "react-router-dom";
import {
    AnnouncementsMenuItem,
    FormSectionTitle,
    GridRow,
    NewAnnouncementButtonRow,
    PDS_P_BOLD
} from "../../components/styled/StyledComponents";
import {BASE_PATH} from "../../constants";
import {IPdsPlatformAnnouncement} from "../../model/announcements/IPdsPlatformAnnouncement";
import parse from "html-react-parser";


export const AdminPlatformAnnouncement: React.FC = () => {
    const [announcements, setAnnouncements] = useState<IPdsPlatformAnnouncement[]>([]);
    const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(-1);


    //handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        console.log(error);
    }

    // get data
    const getAnnouncementsURL = process.env.REACT_APP_API_URL + "/api/admin/pdsAnnouncement/allAnnouncements";
    const [requestState, getAnnouncements] = useRequest({
        url: getAnnouncementsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getAnnouncements();

    }, [])
    useMemo(() => {
        if (data != null) {
            setAnnouncements(data);
        }
    }, [data])


    const buildHeaders = () => {
        return (
            <GridRow className="">
                <Col className="grid-header grid-entry space-5 ">Content</Col>
                <Col className="grid-header grid-entry space-2"> Date Created</Col>
                <Col className="grid-header grid-entry space-2 ">Edit</Col>
            </GridRow>
        )

    }

    const buildDataRow = (announcement: IPdsPlatformAnnouncement) => {
        if (announcement){
            return (
                <GridRow className="">
                    <Col className="grid-header grid-entry space-5"> {parse(announcement.message)}</Col>
                    <Col className="grid-header grid-entry space-2 ">
                        {new Date(announcement.msgDate).toLocaleDateString('en-us', {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric"
                        })}</Col>
                    <Col className="grid-header grid-entry space-2 ">
                        <NavLink to={BASE_PATH + "admin/platformAnnouncement/action/" + announcement.id} className={"linkstyle"}>Edit</NavLink></Col>

                </GridRow>
            )
        }
    }

    // build active and inactive platform announcements
    const buildAnnouncements = (announcements: IPdsPlatformAnnouncement[]) => {
        let activeAnnouncements: IPdsPlatformAnnouncement[] | undefined = announcements.filter(s => s.active)
        return (
            <>
                <FormSectionTitle className="mb-3 mt-2">Active Platform Announcements</FormSectionTitle>
                { activeAnnouncements?.length && (
                    buildHeaders()

                )}
                {activeAnnouncements?.map((announcement: IPdsPlatformAnnouncement) => (
                    buildDataRow(announcement)
                ))}
                {!activeAnnouncements?.length  && (
                    <PDS_P_BOLD>No active platform announcements</PDS_P_BOLD>

                )}

                {buildInactiveAnnouncements(announcements)}
            </>
        )
    }

    const buildInactiveAnnouncements = (announcements: IPdsPlatformAnnouncement[]) => {
        let inactiveAnnouncements: IPdsPlatformAnnouncement[] | undefined = announcements.filter(s => !s.active)
        return (
            <>
                <FormSectionTitle className="mb-1 mt-5">Inactive Platform Announcements</FormSectionTitle>
                { inactiveAnnouncements != null && inactiveAnnouncements.length > 0 && (
                    buildHeaders()

                )}
                {inactiveAnnouncements?.map((announcement: IPdsPlatformAnnouncement) => (
                    buildDataRow(announcement)
                ))}
                {!inactiveAnnouncements?.length  && (
                    <PDS_P_BOLD>No inactive platform announcements</PDS_P_BOLD>

                )}

            </>
        )
    }


    return (
        <Container className={"w-100 "}>
            <NewAnnouncementButtonRow className={"mt-3  "}>
                <Col className="mt-2" md={8} lg={5}>
                    <AnnouncementsMenuItem to={BASE_PATH + "admin/platformAnnouncement/action"}>Create New Platform Announcement
                        </AnnouncementsMenuItem>
                </Col>
            </NewAnnouncementButtonRow>
            <>

                {isLoading &&
                    <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {announcements && buildAnnouncements(announcements)}
            </>
        </Container>
    )
}