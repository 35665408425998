/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Registration
 *
 * Purpose: PDS user registration page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 25Apr2023 sasjxa file created
 * 23May2023 sasjxa add character count for text area
 * 31May2023 sasjxa add values to select components
 * 02Jun2023 sasjxa support for displaying other fields
 * 15Jun2023 sasjxa display submission response after successful submit
 * 15Jun2023 sasjxa add links to aside text for contact us and resources
 * 23Jun2023 sasjxa refactor to use new button container
 * 10Aug2023 sasjxa correction to submit button text
 * 28Jan2024 craig  Handle reactivate user
 * 13Feb2024 craig  refactor reactivation and RMI
 * 15Feb2024 sasjxa correct response for user registration
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 11Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 25Mar2024 sasjxa add new method to handle RMI error (MPM-5402)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 14May2024 sasjxa add decoding for sector other
 * 16May2024 sasjxa show Other fields when returned values are not null
 * 14Jun2024 sasjxa redirect to expired token page upon RMI error
 * 17Jun2024 sasjxa add check for disabled email before displaying email in db message
 * 02Jul2024 craig  removed decode (moved to api)
 * 06Aug2024 sasjxa change error message
 * 21Aug2024 craig   moved to useContext(PDSContext) for user session
 * 24Sep2024 craig  Added AxiosError to handleError
 * 31Oct2024 craig  Added waitingForResponse to prevent new submit before response
 * 01Nov2024 craig  Added yup max to text fields
 * 05Nov2024 sasjxa change yup max values for consistency amongst forms
 * 08Nov2024 sasjxa change yup text of affilation other max and registration value
 \*************************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
// @ts-ignore
import styled from 'styled-components';

import {
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDS_P_BOLD,
    PDS_SPAN_BOLD,
    TextAreaCountRow,
    UserAgreementRow,
    UserAgreementTitle
} from "../../components/styled/StyledComponents";
import {Attestation} from "../../components/Attestation";
import "../forms/forms.css";
import {
    AFFILIATION,
    CONFERENCE_OPTION,
    COUNTRY,
    FormList,
    HEADER_REGISTRATION,
    LEARN_ABOUT_PDS,
    OTHER_OPTION,
    PUBLICATION_OPTION,
    REASON_FOR_VISITING,
    RESPONSE_REGISTRATION,
    ROLE,
    SUBMIT
} from "../../data/formList";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {IUser} from "../../model/user/IUser";
import {setSessionCookie} from "../../context/context";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {Aside} from "../../components/Aside";
import {IFullUserDetails} from "../../model/user/IFullUser";
import {BASE_PATH} from "../../constants";
import {getEncodedValue, labelIfReadOnly} from "../../constants/CommonFunctions";
import {useErrorBoundary} from "react-error-boundary";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {usePDSContext} from "../../context/PDSContext";
import axios, {AxiosError} from "axios";

const IndentedListItem = styled.li`
    margin-left: 2%;
    font-size: ${FONT_SIZES.px13};
`;

const PDS_P_REGISTRATION_MSG = styled(PDS_P_BOLD)`
    font-weight: 600;
    color: ${COLORS.remove_red};
`;

interface IHandleSubmit {
    url: string,
    onSuccess?: Function;
}

export const Registration: React.FC = () => {
    const topRef = useRef<null | HTMLDivElement>(null);

    const {token} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const {showBoundary} = useErrorBoundary();
    const [disabled] = useState(false);
    const {sessionUser} = usePDSContext();
    const [userDetails, setUserDetails] = useState<IFullUserDetails>();

    const [disableEmail, setDisableEmail] = useState<boolean>(false);
    const [researchDescriptionCount, setResearchDescriptionCount] = useState(0);
    const [showAffiliationOther, setShowAffiliationOther] = useState(false);
    const [showRoleOther, setShowRoleOther] = useState(false);
    const [showReasonForVisitingOther, setShowReasonForVisitingOther] = useState(false);
    const [showLearnAboutPDSOther, setShowLearnAboutPDSOther] = useState(false);
    const [isCheckedTermsOfUse, setIsCheckedTermsOfUse] = useState(false);
    const [registrationMsg, setRegistrationMsg] = useState<string | undefined>("");
    const formikRef = useRef<FormikProps<any>>(null);
    const [showRegistrationMessage, setShowRegistrationMessage] = useState(false);

    //this will disable the submit button while waiting for the response after submit
    const [waitingForResponse, setWaitingForResponse] = useState<boolean>(false);


    const TERMS_OF_USE: string = "I have read the Project Data Sphere " +
        "agreement in full.I agree to comply with the conditions as stated in the agreement.";

    const maxResearchDescriptionCount = 1000;

    const minimumOneCharRegExp = /[A-Za-z]+/;
    const NO_CHARS_ERROR_MSG = "Field must contain at least one character";

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
        setWaitingForResponse(false);
        if (topRef !== null) {
            if (topRef.current !== null) {
                topRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    // handle unsuccessful save of data
    const buildError = (error: Error | AxiosError) => {
        if (!disableEmail) {
            if (axios.isAxiosError(error) && error.response !== undefined) {
                setRegistrationMsg(error.response.data.detail);
            } else {
                setRegistrationMsg("An error has occurred and the Project Data Sphere Administrator has been notified");
            }
            setShowRegistrationMessage(true);
            setWaitingForResponse(false);
        }
        if (topRef !== null) {
            if (topRef.current !== null) {
                topRef.current.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    // handle error concerning RMI - navigate to ExpiredTokenRegistration
    const handleRmiError = (error: object) => {
        navigate(BASE_PATH + "registration/expired/" + token);
    }

    // handle successful save of data
    const handleSuccess = () => {
        setWaitingForResponse(false);
        navigate(BASE_PATH + 'SubmissionResponse', {
            state: {
                header: HEADER_REGISTRATION,
                response: RESPONSE_REGISTRATION
            }
        });
    }


    // handle successful reactivation of user account
    const handleReactiveSuccess = (user: IUser) => {
        setWaitingForResponse(false);
        if (user) {
            setSessionCookie(user);
            //window.location.href = "/submissionResponse/" + RESPONSE_REACTIVATE;
            window.location.href = "/";
        }
    }

    // initialize fields after successful retrieve of data
    const handleGetRegistration = (userDetails: IFullUserDetails) => {
        if (userDetails !== null) {
            //console.log(userDetails);
            userDetails.first = userDetails.first;
            userDetails.last = userDetails.last;
            userDetails.sectorOther = userDetails.sectorOther;
            userDetails.organization = userDetails.organization;
            userDetails.positionOther = userDetails.positionOther;
            userDetails.roleOther = userDetails.roleOther;
            userDetails.department = userDetails.department;
            userDetails.linkedinProfileURL = userDetails.linkedinProfileURL;
            userDetails.address = userDetails.address;
            userDetails.city = userDetails.city;
            userDetails.state = userDetails.state;
            userDetails.reasonForVisitingOther = userDetails.reasonForVisitingOther;
            userDetails.learnAboutPDSOther = userDetails.learnAboutPDSOther;
            userDetails.proposedResearchDescription = userDetails.proposedResearchDescription;
            userDetails.proposedResearchGoals = userDetails.proposedResearchGoals;

            setUserDetails(userDetails);

            if (userDetails.sectorOther != null) {
                setShowAffiliationOther(true);
            }

            if (userDetails.roleOther != null || userDetails.positionOther != null) {
                setShowRoleOther(true);
            }

            if (userDetails.reasonForVisitingOther != null) {
                setShowReasonForVisitingOther(true);
            }

            if (userDetails.learnAboutPDSOther != null) {
                setShowLearnAboutPDSOther(true);
            }


        }
    }

    // get data
    const [userRegistrationState, getUserRegistration] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/user/registration/" + token,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetRegistration
    });
    let {isLoading, data, error} = userRegistrationState;

    // get data for rmi situation
    const [userRmiState, getUserRmi] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/public/users/registration/rmi/" + token,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleRmiError,
        onSuccess: handleGetRegistration //need new one
    });

    // save data
    const [handleSubmit, setHandleSubmit] = useState<IHandleSubmit>({
        url: "/api/public/users/registration",
        onSuccess: handleSuccess
    });
    const [requestState, setUserRegistration] = useRequest({
        url: handleSubmit.url,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError,
        onSuccess: handleSubmit.onSuccess
    })

    // memo for page load
    useMemo(() => {
        if (location.pathname.includes("/reactivate")) {
            setHandleSubmit({url: "/api/user/registration/" + token, onSuccess: handleReactiveSuccess});
        } else if (location.pathname.includes("/rmi")) {
            setHandleSubmit({url: "/api/public/users/registration/rmi/" + token, onSuccess: handleSuccess});
        } else {
            setHandleSubmit({url: "/api/public/users/registration", onSuccess: handleSuccess});
        }
        if (token !== undefined && token.length > 0) {
            if (sessionUser.userID > 0) {
                getUserRegistration();
                setDisableEmail(true);
            } else {
                getUserRmi();
                setDisableEmail(true);
            }
        }
    }, []);

    // validation schema without email validation
    const baseSchema = Yup.object().shape({
        first: Yup.string().required("Please provide a first name.")
            .matches(minimumOneCharRegExp, NO_CHARS_ERROR_MSG)
            .max(50, "First name should be no longer than 50 characters."),
        last: Yup.string().required("Please provide a last name.")
            .matches(minimumOneCharRegExp, NO_CHARS_ERROR_MSG)
            .max(100, "Last name should be no longer than 100 characters."),
        //email: Yup.string().required("Please provide an email address.").email(),
        //emailRepeat: Yup.string().required("Please provide an email address.").oneOf([Yup.ref('email')], 'Email addresses must match'),
        phone: Yup.string().required("Please provide a phone number.")
            .max(30, "Phone number should be no longer than 30 characters."),
        affiliation: Yup.string().required("Please select an affiliation."),
        affiliationOther: Yup.string()
            .max(45, "Affiliation should be no longer than 45 characters")
            .when(['affiliation'], {
            is: (affiliation: string) => {
                return affiliation === OTHER_OPTION; // for all other cases proData is not required
            },
            then: (schema) =>
                schema.required("Please enter an affiliation.")
        }),
        linkedInURL: Yup.string().max(255, "LinkedIn url should be no longer than 255 characters."),
        organization: Yup.string().required("Please provide an organization.")
            .max(150, "Organization should be no longer than 150 characters.")
            .matches(minimumOneCharRegExp, NO_CHARS_ERROR_MSG),
        role: Yup.string().required("Please select a role."),
        roleOther: Yup.string().max(45, "Role should be no longer than 45 characters.")
            .when(['role'], {
            is: (role: string) => {
                if (role === OTHER_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases proData is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a role.")
        }),
        department: Yup.string().required("Please provide a department.")
            .max(30, "Department should be no longer than 30 characters."),
        streetAddress: Yup.string().required("Please provide a street address.")
            .max(200, "Address should be no longer than 200 characters."),
        city: Yup.string().required("Please provide a city.")
            .max(100, "City should be no longer than 100 characters."),
        state: Yup.string().required("Please provide a state.")
            .max(100, "State should be no longer than 100 characters."),
        postalCode: Yup.string().required("Please provide a postal code.")
            .max(12, "Postal code should be no longer than 12 characters."),
        country: Yup.string().required("Please select a country."),
        learnAboutPDSOther: Yup.string()
            .max(200, "Learn about PDS should be no longer than 200 characters."),
        reasonForVisiting: Yup.string().required("Please select a reason for visiting."),
        reasonForVisitingOther: Yup.string()
            .max(100, "Reason for visiting should be no longer than 100 characters.")
            .when(['reasonForVisiting'], {
            is: (reasonForVisiting: string) => {
                if (reasonForVisiting === OTHER_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases proData is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a reason for visiting.")
        }),
        researchGoals: Yup.string().required("Please provide research goals.").matches(minimumOneCharRegExp, NO_CHARS_ERROR_MSG),
        termsOfUse: Yup
            .boolean()
            .test('terms of use', '', function (value) {
                if (isCheckedTermsOfUse) {
                    return true;
                }
                return this.createError({message: "Please indicate that you have read the Project Data Sphere agreement."})
            }),
    });

    // full validation schema including email
    const fullSchema = baseSchema.concat(
        Yup.object().shape({
            email: Yup.string().required("Please provide an email address.")
                .max(255, "Email address should be no longer than 255 characters.")
                .email(),
            emailRepeat: Yup.string().required("Please provide an email address.")
                .max(255, "Email address should be no longer than 255 characters.")
                .oneOf([Yup.ref('email')], 'Email addresses must match.'),
        }));


    // return the validation schema depending on whether email is disabled
    const getValidationSchema = () => {
        if (!disableEmail) {
            return fullSchema;
        } else return baseSchema;
    }

    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    // set spacing for label depending on disabled state
    const labelForResearchGoals = () => {
        if (disabled) {
            return "mt-5 mb-0";
        } else return "mt-5 mb-5";
    }

    // show other field when 'Other" selected from list
    const handleRoleChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowRoleOther(true);
        } else if (showRoleOther) {
            setShowRoleOther(false);
        }
    }

    // show other field when 'Other" selected from list
    const handleReasonForVisitingChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowReasonForVisitingOther(true);
        } else if (showReasonForVisitingOther) {
            setShowReasonForVisitingOther(false);
        }
    }

    // show other field when 'Other" selected from list
    const handleAffiliationChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowAffiliationOther(true);
        } else if (showAffiliationOther) {
            setShowAffiliationOther(false);
        }
    }

    // show other field when 'Other" selected from list
    const handleLearnAboutPDSChanged = (value: string) => {
        if (value === OTHER_OPTION || value === PUBLICATION_OPTION || value === CONFERENCE_OPTION) {
            setShowLearnAboutPDSOther(true);
        } else if (showLearnAboutPDSOther) {
            setShowLearnAboutPDSOther(false);
        }
    }

    // toggle terms of use field
    const handleTermsOfUseChanged = (value: boolean) => {
        setIsCheckedTermsOfUse(value);
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setShowAffiliationOther(false);
        setShowRoleOther(false);
        setShowReasonForVisitingOther(false);
        setShowLearnAboutPDSOther(false);
        setShowRegistrationMessage(false);
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    validationSchema={getValidationSchema()}
                    onSubmit={async (values) => {
                        setWaitingForResponse(true);
                        setUserRegistration(JSON.stringify({
                            first: getEncodedValue(values.first),
                            last: getEncodedValue(values.last),
                            email: values.email,
                            phone: values.phone,
                            affiliation: values.affiliation,
                            affiliationOther: getEncodedValue(values.affiliationOther),
                            organization: getEncodedValue(values.organization),
                            role: values.role,
                            roleOther: getEncodedValue(values.roleOther),
                            department: getEncodedValue(values.department),
                            linkedInURL: getEncodedValue(values.linkedInURL),
                            streetAddress: getEncodedValue(values.streetAddress),
                            city: getEncodedValue(values.city),
                            state: getEncodedValue(values.state),
                            postalCode: values.postalCode,
                            country: values.country,
                            reasonForVisiting: values.reasonForVisiting,
                            reasonForVisitingOther: getEncodedValue(values.reasonForVisitingOther),
                            learnAboutPDS: values.learnAboutPDS,
                            learnAboutPDSOther: getEncodedValue(values.learnAboutPDSOther),
                            researchGoals: getEncodedValue(values.researchGoals),
                            termsOfUse: isCheckedTermsOfUse
                        }));
                    }}
                    initialValues={{
                        first: userDetails?.first ?? '',
                        last: userDetails?.last ?? '',
                        email: userDetails?.email ?? '',
                        emailRepeat: '',
                        phone: userDetails?.phoneNumber ?? '',
                        affiliation: userDetails?.sector ?? '',
                        affiliationOther: userDetails?.sectorOther ?? '',
                        organization: userDetails?.organization ?? '',
                        role: userDetails?.role ?? '',
                        roleOther: userDetails?.roleOther ?? '',
                        department: userDetails?.department ?? '',
                        linkedInURL: userDetails?.linkedinProfileURL ?? '',
                        streetAddress: userDetails?.address ?? "",
                        city: userDetails?.city ?? "",
                        state: userDetails?.state ?? "",
                        postalCode: userDetails?.postalCode ?? "",
                        country: userDetails?.country ?? "",
                        reasonForVisiting: userDetails?.reasonForVisiting ?? "",
                        reasonForVisitingOther: userDetails?.reasonForVisitingOther ?? "",
                        learnAboutPDS: userDetails?.learnAboutPDS ?? "",
                        learnAboutPDSOther: userDetails?.learnAboutPDSOther ?? "",
                        researchGoals: userDetails?.proposedResearchGoals ?? "",
                        termsOfUse: false,

                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      values,
                      touched,
                      errors,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>

                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6} controlId="formfirstName">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">First Name </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"first"}
                                                      type="text"
                                                      value={values.first}
                                                      disabled={disabled}
                                                      isValid={touched.first && !errors.first}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a first name
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"first"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={6} controlId="formLastName">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Last
                                            Name </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"last"}
                                                      type="text"
                                                      value={values.last}
                                                      disabled={disabled}
                                                      isValid={touched.last && !errors.last}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a last name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"last"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} controlId="formEmail">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Email
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"email"}
                                                      type="email"
                                                      value={values.email}
                                                      disabled={disabled || disableEmail}
                                                      isValid={touched.email && !errors.email}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid email address.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"email"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {!disabled && !disableEmail && (
                                    <Row className="mt-3 mb-3">
                                        <Form.Group as={Col} controlId="formEmailRepeat">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Repeat
                                                Email
                                                Address </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"emailRepeat"}
                                                          type="email"
                                                          disabled={disabled}
                                                          isValid={touched.emailRepeat && !errors.emailRepeat}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Email addresses do not match.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"emailRepeat"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6} controlId="formPhone">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Phone </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"phone"}
                                                      type="text"
                                                      value={values.phone}
                                                      disabled={disabled}
                                                      isValid={touched.phone && !errors.phone}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a phone number.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"phone"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formAffiliation">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Affiliation </Form.Label>

                                        <Form.Select size="sm" required
                                                     name={"affiliation"}
                                                     value={values.affiliation}
                                                     isValid={touched.affiliation && !errors.affiliation}
                                                     onChange={e => {
                                                         handleAffiliationChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(AFFILIATION)}
                                        </Form.Select>

                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select an affiliation.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"affiliation"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showAffiliationOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formAffiliationOther">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Other
                                                Affiliation </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"affiliationOther"}
                                                          type="text"
                                                          value={values.affiliationOther}
                                                          disabled={disabled}
                                                          isValid={touched.affiliationOther && !errors.affiliationOther}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide an affiliation.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"affiliationOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} className="organization"
                                                controlId="formOrganization">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Organization </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"organization"}
                                                      type="text"
                                                      value={values.organization}
                                                      disabled={disabled}
                                                      isValid={touched.organization && !errors.organization}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide an organization name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"organization"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formRole">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Role </Form.Label>

                                        <Form.Select size="sm" required
                                                     name={"role"}
                                                     value={values.role}
                                                     isValid={touched.role && !errors.role}
                                                     onChange={e => {
                                                         handleRoleChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(ROLE)}
                                        </Form.Select>

                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a role
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"role"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={6}
                                                controlId="formDepartment">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Department </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"department"}
                                                      type="text"
                                                      value={values.department}
                                                      disabled={disabled}
                                                      isValid={touched.department && !errors.department}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a department name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"department"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showRoleOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formRoleOther">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Other
                                                Role </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"roleOther"}
                                                          type="text"
                                                          value={values.roleOther}
                                                          disabled={disabled}
                                                          isValid={touched.roleOther && !errors.roleOther}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a role.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"roleOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6} className="linkedInURL"
                                                controlId="formLinkedInURL">
                                        <Form.Label column="sm">LinkedIn Profile URL </Form.Label>
                                        <Form.Control size="sm"
                                                      name={"linkedInURL"}
                                                      type="text"
                                                      value={values.linkedInURL}
                                                      disabled={disabled}
                                                      aria-describedby="linkedInUrlHelpBlock"
                                                      onChange={handleChange}/>
                                        {!disabled && (
                                            <Form.Text id="linkedInUrlHelpBlock" muted>
                                                Used for registration verification.
                                            </Form.Text>
                                        )}
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formStreetAddress">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Street
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"streetAddress"}
                                                      type="text"
                                                      value={values.streetAddress}
                                                      disabled={disabled}
                                                      isValid={touched.streetAddress && !errors.streetAddress}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a street address.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"streetAddress"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={5} controlId="formCity">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">City </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"city"}
                                                      type="text"
                                                      value={values.city}
                                                      disabled={disabled}
                                                      isValid={touched.city && !errors.city}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a city
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"city"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} controlId="formState">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">State </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"state"}
                                                      type="text"
                                                      value={values.state}
                                                      disabled={disabled}
                                                      isValid={touched.state && !errors.state}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a state.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"state"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4} controlId="formPostalCode">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Postal
                                            Code </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"postalCode"}
                                                      type="text"
                                                      value={values.postalCode}
                                                      disabled={disabled}
                                                      isValid={touched.postalCode && !errors.postalCode}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a postal code.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"postalCode"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formCountry">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Country </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"country"}
                                                         value={values.country}
                                                         isValid={touched.country && !errors.country}
                                                         onChange={handleChange}>
                                                {buildSelectList(COUNTRY)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a country.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"country"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-4 mb-3">
                                    <Form.Group as={Col} lg={6} controlId="formReasonForVisiting">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">What
                                            is your
                                            main reason for
                                            visiting? </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"reasonForVisiting"}
                                                         value={values.reasonForVisiting}
                                                         isValid={touched.reasonForVisiting && !errors.reasonForVisiting}
                                                         onChange={e => {
                                                             handleReasonForVisitingChanged(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(REASON_FOR_VISITING)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a reason for visiting.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"reasonForVisiting"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showReasonForVisitingOther && (
                                    <Row className="mt-4 mb-5 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formReasonForVisitingOther">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Other
                                                Reason
                                                For
                                                Visiting </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"reasonForVisitingOther"}
                                                          type="text"
                                                          value={values.reasonForVisitingOther}
                                                          disabled={disabled}
                                                          isValid={touched.reasonForVisitingOther && !errors.reasonForVisitingOther}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a reason for visiting.
                                            </Form.Control.Feedback>
                                            <ErrorMessage
                                                name={"reasonForVisitingOther"}
                                                render={msg => <div
                                                    className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-4 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formLearnAbout">
                                        <Form.Label column="sm">How did you learn about Project Data
                                            Sphere?
                                        </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm"
                                                         name={"learnAboutPDS"}
                                                         value={values.learnAboutPDS}
                                                         onChange={e => {
                                                             handleLearnAboutPDSChanged(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(LEARN_ABOUT_PDS)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                    </Form.Group>
                                </Row>

                                {showLearnAboutPDSOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={11}
                                                    controlId="formLearnAboutPDSOther">
                                            <Form.Label column="sm">Please describe in more detail </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"learnAboutPDSOther"}
                                                          type="text"
                                                          value={values.learnAboutPDSOther}
                                                          disabled={disabled}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className={labelForResearchGoals()}>
                                    <Form.Group as={Col} controlId="formResearchGoals">
                                        <TextAreaCountRow>
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Research
                                                Description &
                                                Goals </Form.Label>
                                            {!disabled && (

                                                <span>{researchDescriptionCount} of {maxResearchDescriptionCount} characters</span>
                                            )}
                                        </TextAreaCountRow>
                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"researchGoals"}
                                                      type="text"
                                                      disabled={disabled}
                                                      value={values.researchGoals}
                                                      aria-describedby="researchGoalsHelpBlock"
                                                      isValid={touched.researchGoals && !errors.researchGoals}
                                                      onChange={e => {
                                                          setResearchDescriptionCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        {!disabled && (
                                            <Form.Text id="researchGoalsHelpBlock" muted>
                                                Provide your specific area of interest such as cancer
                                                therapeutic
                                                focus along with your intended use or application of this
                                                data. Also
                                                include if you are looking to use certain type of data and
                                                have a
                                                specific methodology/research goal
                                                in mind such as external control arms, etc.
                                            </Form.Text>
                                        )}
                                        <Form.Control.Feedback type="invalid">
                                            Please provide research goals.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"researchGoals"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {!disabled && (
                                    <UserAgreementTitle>User Data Agreement</UserAgreementTitle>
                                )}

                                {!disabled && (
                                    <UserAgreementRow className="mb-3">
                                        <Attestation/>
                                    </UserAgreementRow>
                                )}


                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} controlId="formTermsOfUse">
                                        {!disabled && (
                                            <Form.Check className="bold_label" required type={"checkbox"}
                                                        disabled={disabled}
                                                        label={TERMS_OF_USE}
                                                //checked={values.termsOfUse}
                                                        isValid={touched.termsOfUse && !errors.termsOfUse}
                                                        onChange={e => {
                                                            handleTermsOfUseChanged(!isCheckedTermsOfUse);
                                                            handleChange(e);
                                                        }}>
                                            </Form.Check>
                                        )}
                                        <ErrorMessage name={"termsOfUse"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        {!disabled && (
                            <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                           isSubmitting={waitingForResponse}
                                                           submitButtonText={SUBMIT}/>
                        )}
                    </Form>

                )}
            </Formik>
        )
    }

    /**
     * reactive account message
     */
    const reactiveMessage = () => {
        if (token !== null) {
            return <Row>
                {/*<span>Your account expired on {expired}. It must be reactivated to ensure continued access to the site.</span>*/}
                <PDS_SPAN_BOLD>The Project Data Sphere platform has been designed to provide broad access.
                    Required fields are collected to gain an understanding of the user community and to enhance future
                    versions of the platform.</PDS_SPAN_BOLD>
                <ul>
                    <IndentedListItem>Complete all required sections of the form and then click
                        "Submit".</IndentedListItem>
                    <IndentedListItem>If you feel you have missed any communications from us, please use the "Contact
                        Us" feature on
                        this
                        website.
                    </IndentedListItem>
                </ul>
            </Row>
        }
    }

    return (
        <Container className="form-container" fluid ref={topRef}>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>
                        {reactiveMessage()}
                        {showRegistrationMessage && (
                            <PDS_P_REGISTRATION_MSG>{registrationMsg}</PDS_P_REGISTRATION_MSG>
                        )}
                        {buildForm()}
                    </FormLayoutColumn>
                    {!disabled && (
                        <Col md={4} lg={3}>
                            <Aside needHelp={true} requiredFields={true}/>
                        </Col>
                    )}
                </PageWrapper>
            </ContentPage>

        </Container>
    );

}
