/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminUserRequests
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Jul2023 sasjxa file created
 * 10Jan2024 sasjxa use new common component for header
 * 15Feb2024 sasjxa add section for RMI
 * 05Mar2024 sasjxa add search box for approved users (MPM-5413)
 * 06Mar2024 sasjxa add sorting for name and date (MPM-5407)
 * 08Mar2024 craig  added base_path
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 25Mar2024 sasjxa add test conditions for rmi new token (MPM-5402)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 14May2024 sasjxa check for new user requests in returned list that contain new and RMI
 * 14Jun2024 sasjxa add check for empty url
 * 17Jun2024 sasjxa add CRMI check
 * 02Jul2024 craig  removed decode (moved to api)
 * 18Aug2024 sasjxa add CRMI and RMI New Token to new requests
 * 25Aug2024 craig  clean up
 * 23Sep2024 sasjxa changed href to NavLink
 * 31Oct2024 sasjxa use new component dropdown for brief summary of user registration
 * 01Nov2024 sasjxa add component for displaying active directory/login info
 * 21Nov2024 sasjxa add forgot to user requests to trigger password reset
 \****************************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {useParams} from "react-router-dom";
import {IAdminRequestUser} from "../../model/admin/requests/IAdminRequestUser";
import {Formik, FormikProps} from "formik";
import {
    ACCOUNT_HEADERS,
    APPROVED,
    CRMI,
    DATE_ASC,
    DATE_DESC,
    DECLINED,
    NAME_LAST_ASC,
    NAME_LAST_DESC,
    NEW_MIXED,
    NOT_APPLICABLE,
    RMI_NEW_TOKEN,
    RMI_USER
} from "../../data/formList";
import {
    AdminTableHeader,
    FormLayoutColumn,
    GridRow,
    NavMenuItem,
    PDS_P,
    PDS_P_BOLD,
    UserInfoCol
} from "../../components/styled/StyledComponents";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {AdminUserRequestDetails} from "../../components/admin/AdminIUserRequestDetails";
import {AdminUserLoginsInfo} from "../../components/admin/AdminUserLoginsInfo";
import styled from "styled-components";
import {AdminResetPasswordDialog} from "../../components/dialogs/AdminResetPasswordDialog";
import {IAdminRequestUserSmallUser} from "../../model/admin/requests/IAdminRequestUserSmallUser";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";

const ResetPasswordCol = styled(Col)`
    flex: 1;
    border: 0px;
    padding-left: 0;
    padding-right: 0;
   

    font-size: ${FONT_SIZES.px14};
    color: #34485e;

    a {
        cursor: pointer;
    }
`;

const PDS_P_ERROR_MSG = styled.p`    
    font-weight: 600;
    color: ${COLORS.remove_red};
    margin-bottom: 0;
`;

const PDS_P_SUCCESS_MSG = styled.p`
    font-weight: 600;
    color: ${COLORS.turtle_green};
    margin-bottom: 0;
`;


export const AdminUserRequests: React.FC = () => {
    const {requestType} = useParams();
    const formikRef = useRef<FormikProps<any>>(null);
    const {showBoundary} = useErrorBoundary();
    const [searchParam, setSearchParam] = useState("");
    const url = process.env.REACT_APP_API_URL + "/api/admin/users/requests/" + requestType;
    const searchUrl = process.env.REACT_APP_API_URL + "/api/admin/users/requests/search/" + searchParam;
    const [requestsURL, setRequestsURL] = useState("");
    const [userRequests, setUserRequests] = useState<IAdminRequestUser[]>([]);
    const [sortOrder, setSortOrder] = useState("DATE_DESC");
    const [defaultNameSortOrder, setDefaultNameSortOrder] = useState("NAME_LAST_ASC");
    const [defaultDateSortOrder, setDefaultDateSortOrder] = useState("DATE_DESC");
    const [showPasswordDialog, setShowPasswordDialog] = useState<boolean>(false);
    const [selectedName, setSelectedName] = useState("");
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedUserId, setSelectedUserId] = useState(-1);
    const [message, setMessage] = useState("");
    const [selectedSuccessIds, setSelectedSuccessIds] = useState<number[]>([]);
    const [selectedFailedIds, setSelectedFailedIds] = useState<number[]>([]);

    const RESET_FAILED = "FAILED";
    const RESET_SUCCESS = "SUCCESS";


    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // get data
    const [requestState, getUserRequests] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    // useMemo - called on change to searchParam
    useMemo(() => {
        if (searchParam !== null && searchParam.trim().length > 0) {
            setRequestsURL(searchUrl);
        } else {
            setRequestsURL(url);
        }
    }, [searchParam]);

    // useMemo - called when change to requestsURL
    useMemo(() => {
        if (requestsURL.length > 0) {
            getUserRequests();
        }
    }, [requestsURL]);

    // useMemo - called with change to data
    useMemo(() => {
        if (data !== null) {
            setUserRequests(data);
        }
    }, [data])

    // sort name
    const sortName = (e: any) => {
        e.preventDefault();
        if (defaultNameSortOrder === NAME_LAST_ASC) {
            setDefaultNameSortOrder(NAME_LAST_DESC);
        } else {
            setDefaultNameSortOrder(NAME_LAST_ASC);
        }

        setSortOrder(defaultNameSortOrder);
        setRequestsURL(url + "/" + sortOrder);
    }

    // sort date
    const sortDate = (e: any) => {
        e.preventDefault();
        if (defaultDateSortOrder === DATE_DESC) {
            setDefaultDateSortOrder(DATE_ASC);
        } else {
            setDefaultDateSortOrder(DATE_DESC);
        }

        setSortOrder(defaultDateSortOrder);
        setRequestsURL(url + "/" + sortOrder);
    }

    // handle search change
    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (searchParam !== null) {
            setSearchParam(event.target.value);
        } else {
            setSearchParam("");
        }
    }

    // handle forgot password reset failed .. called by dialog
    const handleForgotPasswordFailedReset = () => {
        setMessage(RESET_FAILED);
        setSelectedFailedIds([...selectedFailedIds, selectedUserId]);
        setShowPasswordDialog(false);
    }

    // handle forgot password reset success .. called by dialog
    const handleForgotPasswordResetSuccess = () => {
        setMessage(RESET_SUCCESS);
        setSelectedSuccessIds([...selectedSuccessIds, selectedUserId]);
        setShowPasswordDialog(false);
    }

    // handle forgot password
    const handleForgotPassword = (user: IAdminRequestUserSmallUser) => {
        setSelectedName(user.userDetails.first + " " + user.userDetails.last);
        setSelectedEmail(user.userDetails.email);
        setSelectedUserId(user.id);
        setShowPasswordDialog(true);
    }


    // toggle reset password dialog
    const toggleResetPasswordDialog = () => {
        setShowPasswordDialog(!showPasswordDialog);
    }

    // check if user has been processed success or fail
    const hasUserPasswordResetProcessed = (user: IAdminRequestUserSmallUser ) => {
        return (selectedSuccessIds.includes(user.id) || selectedFailedIds.includes(user.id)) ? true : false;
    }

    // determine what to show in password field
    const getPasswordItem = (user: IAdminRequestUserSmallUser) => {
        if (hasUserPasswordResetProcessed(user) )    {
            if (selectedSuccessIds.includes(user.id) ) {
                return (
                    <ResetPasswordCol className="">
                        <PDS_P_SUCCESS_MSG>{RESET_SUCCESS}</PDS_P_SUCCESS_MSG>
                    </ResetPasswordCol>
                )
            } else if (selectedFailedIds.includes(user.id) ) {
                return (
                    <ResetPasswordCol className="">
                        <PDS_P_ERROR_MSG>{RESET_FAILED}</PDS_P_ERROR_MSG>
                    </ResetPasswordCol>
                )
            } else  {
                return (
                    <ResetPasswordCol className="">
                        <PDS_P>{NOT_APPLICABLE}</PDS_P>
                    </ResetPasswordCol>
                )
            }

        } else {
            return (
                <ResetPasswordCol className="">
                    <a className="linkstyle  center  "
                       onClick={() => handleForgotPassword(user)}>Forgot</a>
                </ResetPasswordCol>
            )
        }
    }




    // build the open requests headers
    const buildOpenRequestHeaders = () => {
        return (
            <>
                <GridRow className="">
                    <Col className="grid-header grid-entry space-3 ">Name</Col>
                    <Col className="grid-header grid-entry space-3"> Email</Col>
                    <Col className="grid-header grid-entry ">Request Type</Col>
                    <Col className="grid-header grid-entry "> Request Date</Col>
                    <Col className="grid-header grid-entry">Current Status</Col>
                </GridRow>
            </>
        )
    }

    // build open requests
    const buildOpenRequests = (processRequests: IAdminRequestUser[]) => {
        return (
            <>
                {processRequests?.map((request: IAdminRequestUser) => (
                    <AdminUserRequestDetails request={request} requestType={requestType}/>


                ))}
            </>
        )
    }


    // build the approved request headers
    const buildApprovedRequestHeaders = () => {
        return (
            <>
                <GridRow className="">
                    <Col className="grid-header grid-entry space-3 ">Name</Col>
                    <Col className="grid-header grid-entry space-2 ">Email</Col>
                    <Col className="grid-header grid-entry ">Request Type</Col>
                    <Col className="grid-header grid-entry ">Request Date</Col>
                    <Col className="grid-header grid-entry">Current Status</Col>
                    <Col className="grid-header grid-entry">Password</Col>
                </GridRow>
            </>
        )
    }


    // build the approved requests section
    const buildApprovedRequests = () => {
        return (
            <>
                {userRequests?.map((request: IAdminRequestUser) => (

                    <GridRow className="" key={request.requestId}>

                        <UserInfoCol className="grid-entry space-3">
                            <AdminUserLoginsInfo userId={request.user.id}/>
                            <NavMenuItem to={BASE_PATH + "admin/userInfo/" + request.user.id}>
                                {request.user.userDetails?.first + " " + request.user.userDetails?.last}
                            </NavMenuItem>

                        </UserInfoCol>

                        <Col className="grid-entry space-2">{request.user.userDetails.email}</Col>
                        <Col className="grid-entry">{request.requestType}</Col>
                        <Col className="grid-entry">
                            {new Date(request.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>

                        <Col className="grid-entry">
                            <NavMenuItem
                                to={BASE_PATH + "admin/request/accounts/" + request.requestId + "/" + requestType}>
                                {request.requestStatus}
                            </NavMenuItem>
                        </Col>
                        {getPasswordItem(request.user)}
                        {/*<ResetPasswordCol className="">*/}
                        {/*    <a className="linkstyle  center  "*/}
                        {/*       onClick={() => handleForgotPassword(request.user)}>Forgot</a>*/}
                        {/*</ResetPasswordCol>*/}
                    </GridRow>

                ))}
            </>
        )
    }


    // build the declined request headers
    const buildDeclinedRequestHeaders = () => {
        return (
            <>
                <GridRow className="">
                    <Col className="grid-header grid-entry space-3 ">Name</Col>
                    <Col className="grid-header grid-entry space-2 "> Email</Col>
                    <Col className="grid-header grid-entry ">Request Type</Col>
                    <Col className="grid-header grid-entry "> Request Date</Col>
                    <Col className="grid-header grid-entry">Current Status</Col>
                </GridRow>
            </>
        )
    }


// build the declined requests section
    const buildDeclinedRequests = () => {
        return (
            <>
                {userRequests?.map((request: IAdminRequestUser) => (
                    <GridRow className="" key={request.requestId}>
                        <Col
                            className="grid-entry space-3">{request.user.userDetails.first + " " + request.user.userDetails.last}</Col>
                        <Col className="grid-entry space-2">{request.user.userDetails.email}</Col>
                        <Col className="grid-entry">{request.requestType}</Col>
                        <Col className="grid-entry">
                            {new Date(request.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>

                        <Col className="grid-entry">
                            <NavMenuItem
                                to={BASE_PATH + "admin/request/accounts/" + request.requestId + "/" + requestType}>
                                {request.requestStatus}
                            </NavMenuItem>
                        </Col>
                    </GridRow>

                ))}
            </>
        )
    }

    // check for RMI requests
    const hasRMIRequests = (): boolean => {
        const rmiRequests: IAdminRequestUser[] | undefined =
            userRequests.filter((userRequest) => userRequest.requestStatus === RMI_USER);
        return rmiRequests !== undefined && rmiRequests.length > 0;
    }

    // check for new requests
    const hasNewRequests = (): boolean => {
        const newRequests: IAdminRequestUser[] | undefined =
            userRequests.filter((userRequest) => userRequest.requestStatus === NEW_MIXED ||
                userRequest.requestStatus === CRMI || userRequest.requestStatus === RMI_NEW_TOKEN);
        return newRequests !== undefined && newRequests.length > 0;
    }

    // return number of users found
    const getNumberOfUsersFound = (): string => {
        if (userRequests !== null) {
            return "Account Requests (Number of Users Found - " + userRequests.length
        } else {
            return "Account Requests (Number of Users Found - 0"
        }
    }

    // main page section
    const buildSection = () => {
        if (requestType === APPROVED) {
            if (userRequests?.length || searchParam.length > 0) {
                return (
                    <>
                        {buildForm()}
                        <Row><AdminTableHeader className="mt-3">
                            {getNumberOfUsersFound()})</AdminTableHeader></Row>
                        {(userRequests?.length) && (
                            <Col className="mb-3">

                                {buildApprovedRequestHeaders()}
                                {buildApprovedRequests()}

                            </Col>
                        )}
                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>No approved user requests at this time</PDS_P_BOLD>
                )
            }
        } else if (requestType === DECLINED) {
            if (userRequests?.length) {
                return (
                    <>
                        <Row><AdminTableHeader className="mt-3">Declined Accounts</AdminTableHeader></Row>
                        <Col className="mb-3">
                            {buildDeclinedRequestHeaders()}
                            {buildDeclinedRequests()}
                        </Col>

                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>No declined user requests at this time</PDS_P_BOLD>
                )
            }
        } else {
            if (userRequests !== undefined && userRequests?.length > 0) {
                const newRequests: IAdminRequestUser[] | undefined =
                    userRequests.filter((userRequest) =>
                        userRequest.requestStatus === NEW_MIXED || userRequest.requestStatus === CRMI
                        || userRequest.requestStatus === RMI_NEW_TOKEN);
                return (
                    <>

                        {hasNewRequests() && (
                            <>
                                <Row><AdminTableHeader className="mt-3">Pending Account
                                    Requests</AdminTableHeader></Row>
                                <Col className="mb-3 ">
                                    {buildOpenRequestHeaders()}
                                    {buildOpenRequests(newRequests)}
                                </Col>
                            </>
                        )}

                        {!hasNewRequests() && (
                            <>
                                <PDS_P_BOLD>No pending user requests at this time</PDS_P_BOLD>
                            </>
                        )}


                        {hasRMIRequests() && (
                            <>
                                <Row><AdminTableHeader className="mt-5">RMI Account
                                    Requests</AdminTableHeader></Row>
                                <Col className="mb-3">
                                    {buildOpenRequestHeaders()}
                                    {buildOpenRequests(userRequests.filter((userRequest) => userRequest.requestStatus === RMI_USER ||
                                        userRequest.requestStatus === RMI_NEW_TOKEN || userRequest.requestStatus === CRMI))}
                                </Col>
                            </>
                        )}

                    </>
                )
            } else {
                return (
                    <>
                        <PDS_P_BOLD>No pending user requests at this time</PDS_P_BOLD>
                        {hasRMIRequests() && (
                            <>
                                <Row><AdminTableHeader className="mt-5">RMI Account
                                    Requests</AdminTableHeader></Row>
                                <Col className="mb-3">
                                    {buildOpenRequestHeaders()}
                                    {buildOpenRequests(userRequests.filter((userRequest) => userRequest.requestStatus === RMI_USER || userRequest.requestStatus === RMI_NEW_TOKEN
                                    ))}
                                </Col>
                            </>
                        )}
                    </>
                )
            }
        }
    }

// build form
    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                // validationSchema={schema}
                    onSubmit={(values) => {
                        console.log("submitted")
                        // navigate('/SubmissionResponse', { state: { header: HEADER_CONTACT_US, response: RESPONSE_CONTACT_US } });
                    }}
                    initialValues={{
                        actions: '',
                        rmiReason: '',
                        declinedReason: '',
                        declinedOther: '',
                        comments: 'Admin Comment',

                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={3}>
                            <Form.Group as={Col} className="w-100 pe-0 ps-1  "
                                        controlId="">
                                <Form.Label className="" column="sm">Search Accounts </Form.Label>

                                <Form.Control
                                    type="search"
                                    placeholder=""
                                    className="search-box border-search"
                                    aria-label="Search"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeSearch(e)}
                                />
                            </Form.Group>
                        </FormLayoutColumn>
                    </Form>
                )}
            </Formik>
        )
    }

    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3  "}></Row>
            <AdminCountsHeader countsList={ACCOUNT_HEADERS} requestType={requestType}
                               countsUrl={"/api/admin/dashboard/counts/accounts"} isUserAccounts={true}/>
            <AdminResetPasswordDialog name={selectedName} email={selectedEmail} userId={selectedUserId}
                                      show={showPasswordDialog} showHide={toggleResetPasswordDialog}
                                      handleResetSuccess={handleForgotPasswordResetSuccess}
                                      handleResetFailed={handleForgotPasswordFailedReset}
            />
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}
        </Container>
    )
}