/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminResetPasswordDialog
 *
 * Purpose: Dialog for admin reset of user password
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 22Nov2024 sasjxa file created
 \******************************************************************************/
import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {DialogHeader, PDS_P} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {useRequest} from "../../helper/useRequest";


interface DialogProps {
    name: string,
    email: string,
    userId: number,
    show:boolean,
    showHide:any,
    handleResetSuccess: any,
    handleResetFailed: any

}

export const AdminResetPasswordDialog: React.FC<DialogProps> = ({ name,
                                                                        email, userId,
                                                                        show,
                                                                        showHide,handleResetSuccess, handleResetFailed
                                                                    }: DialogProps) => {


    // reset the password
    const [requestPasswordResetState, setPasswordReset] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/admin/users/resetPassword/" + userId,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleResetFailed,
        onSuccess: handleResetSuccess
    });

    const getDialogHeader = () => {
        return (
            name + " " + "(" + email  + ")"
        )
    }

    return (
        <Modal show={show}>
            <Modal.Header closeButton={true} onClick={showHide}>
                <DialogHeader>Reset Password for : {getDialogHeader()}</DialogHeader>
            </Modal.Header>
            <Modal.Body>
               <PDS_P>Do you want to reset the password?</PDS_P>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" className="btn-submit" type="button"
                                 onClick={() => {
                                     setPasswordReset();
                                 }}>
                    Yes
                </Button>
                <Button variant={"secondary"} onClick={showHide}>
                    No
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
