/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: MyAccount
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 14Apr2024 craig  file created
 * 16Apr2024 sasjxa add sections, field validation, and controller call (MPM-5422)
 * 22Apr2024 sasjxa use PasswwordRules component
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 24May2024 craig  Added change password issues (submitting and errors)
 * 21Jan2025 craig  Changed to new PDS API
 \*********************************************************************************/
import React, {useMemo, useState} from "react";
import {IMyAccountUserInfo} from "../../../model/user/IMyAccountUserInfo";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {
    ButtonRow,
    ContentPage,
    FormLayoutColumn,
    PageWrapper,
    PDSSubmitButton, SectionHeader
} from "../../../components/styled/StyledComponents";
import styled from "styled-components";
import {FONT_SIZES} from "../../../components/styled/StyleConstants";
import {ErrorMessage, Formik} from "formik";
import * as Yup from "yup";
import {SigninDropdownOptionsDialog} from "../../../components/dialogs/SigninDropdownOptionsDialog";
import {PasswordRules} from "../../../components/forms/PasswordRules";
import {PdsApi} from "../../../hooks/axiosClient/pdsApi";
import {postPDSData} from "../../../utilities/axiosClient/axiosClientUtility";

const UserInfoRow = styled.div`
    width: 90%;
    display: flex;

    span {
        display: inline;
        font-size: ${FONT_SIZES.px14};
        width: 50%;
    }
`;

const PasswordHeaderColumn = styled(Col)`
    margin: 0 auto;
`;

export const PasswordButton = styled(PDSSubmitButton)`
    width: 200px;
`;

export const MyAccount: React.FC = () => {
    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [changePasswordMsg, setChangePasswordMsg] = useState<string | undefined>("");
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    //get the data from PDS API
    const {data, loading, error} =
        PdsApi<IMyAccountUserInfo>(process.env.REACT_APP_API_URL + "/api/user/myAccount/accountInfo");

    // validation schema
    const schema = Yup.object().shape({
        currentPassword: Yup.string()
            .required("Value is required for Current Password"),
        newPassword: Yup.string()
            .required('New password is required')
            .min(12, 'Password must be at least 12 characters')
            .max(15, 'Password cannot be more than 15 characters')
            .notOneOf(
                [Yup.ref('currentPassword'), null],
                'New Password must be different than Current Password'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('newPassword')], 'Passwords must match')
    });

    // show/hide dialog
    const toggleDialog = () => {
        setShowDialog(!showDialog);
    }

    // format date
    const formatUserInfoDate = (date: Date | undefined) => {
        if (date != null) {
            {
                return (
                    new Date(date).toLocaleDateString('en-us', {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit"
                    }).replace(/\//g, '-')
                )
            }
        }
    }

    const buildForm = () => {
        return (
            <>
                <div className={"form-error-msg"}>{changePasswordMsg}</div>
                <Formik
                    validationSchema={schema}
                    onSubmit={async (values,{resetForm}) => {
                        setIsWaitingForResponse(true);
                        //post data to PDS API
                        const {data, error} = await postPDSData<IMyAccountUserInfo, string>(
                            process.env.REACT_APP_API_URL + "/api/user/myAccount/passwordChange",
                            JSON.stringify({
                                currentPassword: values.currentPassword,
                                newPassword: values.newPassword,
                                confirmNewPassword: values.confirmPassword
                            })
                        );
                        //check what we get back from the API
                        if (data !== null) {
                            setChangePasswordMsg("");
                            setShowDialog(true);
                        } else if (error) {
                            setChangePasswordMsg(error?.response?.data.title);
                        }
                        setIsWaitingForResponse(false);
                        //Reset the form
                        resetForm({
                            values: {
                                currentPassword: '',
                                newPassword: '',
                                confirmPassword: '',
                            },
                            errors:{},
                            touched: {},
                            isSubmitting: false,
                        });
                    }}
                    initialValues={{
                        currentPassword: '',
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                    {({
                          handleSubmit,
                          handleChange,
                          resetForm,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                      }) => (
                        <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                            <PasswordHeaderColumn md={8} lg={10} className="mb-3 ">
                                <PasswordHeaderColumn md={8} lg={10} className=" ">
                                    <PasswordHeaderColumn md={6} lg={7} className="">
                                        <Row className="mt-5"><SectionHeader>Change Password</SectionHeader></Row>
                                    </PasswordHeaderColumn>
                                </PasswordHeaderColumn>
                            </PasswordHeaderColumn>
                            <PasswordHeaderColumn lg={12}>
                                <PasswordHeaderColumn lg={8} className="">

                                    <Row className="justify-content-center  mb-3">
                                        <Form.Group as={Col} lg={7} controlId="">
                                            <Form.Label className="required"
                                                        column="sm">Current Password </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"currentPassword"}
                                                          type="password"
                                                          placeholder=""
                                                          isValid={touched.currentPassword && !errors.currentPassword}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <ErrorMessage name={"currentPassword"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>

                                    <Row className="justify-content-center  mb-3">
                                        <Form.Group as={Col} lg={7} controlId="">
                                            <Form.Label className="required"
                                                        column="sm">New Password </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"newPassword"}
                                                          type="password"
                                                          placeholder=""
                                                          isValid={touched.newPassword && !errors.newPassword}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <ErrorMessage name={"newPassword"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>

                                    <Row className="justify-content-center mb-4">
                                        <Form.Group as={Col} lg={7} controlId="">
                                            <Form.Label className="required"
                                                        column="sm">Confirm Password </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"confirmPassword"}
                                                          type="password"
                                                          placeholder=""
                                                          isValid={touched.confirmPassword && !errors.confirmPassword}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <ErrorMessage name={"confirmPassword"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                </PasswordHeaderColumn>
                            </PasswordHeaderColumn>
                            <ButtonRow>
                                <PasswordButton variant="success" className="btn-submit" type="submit" isSubmitting={isWaitingForResponse}>
                                    Change Password
                                </PasswordButton>
                            </ButtonRow>
                        </Form>

                    )}
                </Formik>
            </>
        )
    }

    const buildSection = () => {
        return (
            <>
                <FormLayoutColumn md={8} lg={10} className="mb-3 ">
                    <FormLayoutColumn md={8} lg={10} className="mb-3 ">
                        <FormLayoutColumn md={6} lg={7} className="mb-3 ">
                            <Row className="mt-3"><SectionHeader>User Information</SectionHeader></Row>
                            <UserInfoRow className=" ">
                                <span className="">User id </span>
                                <span className="">{data?.userId}</span>
                            </UserInfoRow>
                            <UserInfoRow className="">
                                <span className="">Username to access SAS tools</span>
                                <span className="">{data?.shortUsername}</span>
                            </UserInfoRow>
                            <UserInfoRow className="">
                                <span className="">Password Expires</span>
                                <span className="">{formatUserInfoDate(data?.passwordExpires)}</span>
                            </UserInfoRow>
                            <UserInfoRow className="">
                                <span className="">Account Expires</span>
                                <span className="">{formatUserInfoDate(data?.accountExpires)}</span>
                            </UserInfoRow>

                        </FormLayoutColumn>
                    </FormLayoutColumn>

                </FormLayoutColumn>
                <Col className="mt-5 mb-5">
                    <Row className="mt-3"><SectionHeader>Password Rules</SectionHeader></Row>
                    <PasswordRules/>
                </Col>
                {buildForm()}
            </>
        )
    }


    return (
        <Container className="form-container" fluid>
            <SigninDropdownOptionsDialog title={"Change Password"} body={<p>Your password was successfully changed.</p>}
                                         show={showDialog} showHide={toggleDialog}/>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn name={"registrationColumn"}>
                        {loading &&
                            <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {data && buildSection()}

                    </FormLayoutColumn>

                </PageWrapper>
            </ContentPage>
        </Container>
    )
}