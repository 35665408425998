/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminAnnouncements
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Nov2024 sasjxa file created
 * 22Dec2024 sasjxa rework layout for better column alignment
 \****************************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink} from "react-router-dom";
import {IMG_PATH} from "../../data/formList";
import {
    AnnouncementsMenuItem,
    FormSectionTitle,
    GridRow,
    NewAnnouncementButtonRow,
    PDS_P_BOLD
} from "../../components/styled/StyledComponents";
import {BASE_PATH} from "../../constants";
import {IAdminAnnouncement} from "../../model/announcements/IAdminAnnouncement";
import {AdminSendEmailAllDialog} from "../../components/dialogs/AdminSendEmailAllDialog";
import {AdminSendEmailTestDialog} from "../../components/dialogs/AdminSendEmailTestDialog";
import {DeleteAdminSendEmailDialog} from "../../components/dialogs/DeleteAdminSendEmailDialog";


export const AdminAnnouncement: React.FC = () => {
    const [announcements, setAnnouncements] = useState<IAdminAnnouncement[]>([]);
    const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(-1);
    const [showEmailAllDialog, setShowEmailAllDialog] = useState(false);
    const [showEmailTestDialog, setShowEmailTestDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    //handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        console.log(error);
    }

    // get data
    const getAnnouncementsURL = process.env.REACT_APP_API_URL + "/api/admin/announcements/allAnnouncements";
    const [requestState, getAnnouncements] = useRequest({
        url: getAnnouncementsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getAnnouncements();

    }, [])
    useMemo(() => {
        if (data != null) {
            setAnnouncements(data);
        }
    }, [data])

    const toggleSendAllDialog = () => {
         setShowEmailAllDialog(!showEmailAllDialog);
    }

    const toggleSendTestDialog = () => {
        setShowEmailTestDialog(!showEmailTestDialog);
    }

    const toggleDeleteDialog = () => {
        setShowDeleteDialog(!showDeleteDialog);
    }

    const handleSendAction = (id: number) => {
        setSelectedAnnouncementId(id);
        setShowEmailAllDialog(true);

    }

    const handleSendTestAction = (id: number) => {
       setSelectedAnnouncementId(id);
        setShowEmailTestDialog(true);
    }

    const handleDeleteAction = (id: number) => {
        setSelectedAnnouncementId(id);
        setShowDeleteDialog(true);
    }

    const announcementDeleted = () => {
        setSelectedAnnouncementId(-1);
        setShowDeleteDialog(false);
        getAnnouncements();
    }

    const handleUpdateAction = () => {
        setSelectedAnnouncementId(-1);
        setShowEmailAllDialog(false);
        getAnnouncements();
    }

    // create headers common to sent and unsent announcements
    const buildCommonHeaders = () => {
        return (
            <>
                <Col className="grid-header grid-entry space-4 ">Subject</Col>
                <Col className="grid-header grid-entry space-2"> Created By</Col>
                <Col className="grid-header grid-entry space-2">Created On</Col>
            </>
        )
    }

    // create fields common to sent and unsent announcements
    const buildCommonFields = (announcement: IAdminAnnouncement) => {
        return (
            <>
                <Col className="grid-entry space-4 ">
                    <NavLink to={BASE_PATH + "admin/announcements/action/" + announcement.id} className={"linkstyle"}>{announcement.subject}</NavLink></Col>
                <Col className="grid-entry space-2"> {announcement.user?.userDetails?.email}</Col>
                <Col className="grid-entry space-2">
                    {new Date(announcement.createdAt).toLocaleDateString('en-us', {
                        month: "numeric",
                        day: "numeric",
                        year: "numeric"
                    })}</Col>

            </>
        )
    }

    // build unsent and sent announcements
    const buildAnnouncements = (announcements: IAdminAnnouncement[]) => {
        let unsentAnnouncements: IAdminAnnouncement[] | undefined = announcements.filter(s => s.sent== null)
        return (
            <>
                <FormSectionTitle className="mb-1">Unsent Announcements</FormSectionTitle>
                { unsentAnnouncements != null && unsentAnnouncements.length> 0 && (
                    <GridRow className="">
                        {buildCommonHeaders()}
                       <Col className="grid-header grid-entry space-3">

                        <Row className="mt-0  ms-0">
                        <Col className="grid-header grid-entry "> Send</Col>
                        <Col className="grid-header grid-entry">Send Test</Col>
                        <Col className="grid-header grid-entry">Delete</Col>
                        </Row>
                           </Col>
                    </GridRow>
                )}
                {unsentAnnouncements?.map((announcement: IAdminAnnouncement) => (
                    <GridRow className="">
                        {buildCommonFields(announcement)}
                        <Col className=" grid-entry space-3">

                                <Row className="mt-0  ms-0" >
                                    <Col className=" grid-entry">
                                        <img className="mx-auto pe-2"
                                             src={IMG_PATH + "icon-all-testEmail.png"} title="Send"
                                             onClick={() => {
                                                 {
                                                     handleSendAction(announcement.id);
                                                 }
                                             }}/>
                                    </Col>
                                    <Col className=" grid-entry">
                                        <img className="mx-auto pe-2"
                                             src={IMG_PATH + "icon-all-testEmail.png"} title="Send Test"
                                             onClick={() => {
                                                 {
                                                     handleSendTestAction(announcement.id);
                                                 }
                                             }}/>
                                    </Col>
                                    <Col className=" grid-entry">
                                        <img className="mx-auto pe-2"
                                             src={IMG_PATH + "delete.png"} title="Delete"
                                             onClick={() => {
                                                 {
                                                     handleDeleteAction(announcement.id);
                                                 }
                                             }}/>
                                    </Col>
                                </Row>

                        </Col>


                    </GridRow>
                ))}
                {unsentAnnouncements== null || unsentAnnouncements.length== 0  && (
                    <PDS_P_BOLD>No unsent announcements</PDS_P_BOLD>

                )}

                {buildSentAnnouncements(announcements)}
            </>
        )
    }

    const buildSentAnnouncements = (announcements: IAdminAnnouncement[]) => {
        let sentAnnouncements: IAdminAnnouncement[] | undefined = announcements.filter(s => s.sent != null)
        return (
            <>
                <FormSectionTitle className="mb-1 mt-5">Sent Announcements</FormSectionTitle>
                { sentAnnouncements != null && sentAnnouncements.length> 0 && (
                    <GridRow className="">
                        {buildCommonHeaders()}

                        <Col className="grid-header grid-entry space-3">Sent On</Col>

                    </GridRow>
                )}
                {sentAnnouncements?.map((announcement: IAdminAnnouncement) => (
                    <GridRow className="">
                        {buildCommonFields(announcement)}
                        <Col className=" grid-entry space-3">

                                <Row className="mt-0  ms-0" >
                                    <Col className="grid-entry space-3">
                                {new Date(announcement.sent).toLocaleDateString('en-us', {
                                    month: "numeric",
                                    day: "numeric",
                                    year: "numeric"
                                })}
                                    </Col>
                                </Row>

                        </Col>
                    </GridRow>
                ))}
                {sentAnnouncements== null || sentAnnouncements.length== 0  && (
                    <PDS_P_BOLD>No sent announcements</PDS_P_BOLD>
                )}


            </>
        )
    }


    return (
        <Container className={"w-100 "}>

            <AdminSendEmailAllDialog announcementId={selectedAnnouncementId} show={showEmailAllDialog}
                                     showHide={toggleSendAllDialog} handleUpdate={handleUpdateAction}/>
            <AdminSendEmailTestDialog announcementId={selectedAnnouncementId} show={showEmailTestDialog} showHide={toggleSendTestDialog}/>
            <DeleteAdminSendEmailDialog announcementId={selectedAnnouncementId} show={showDeleteDialog}
                                        showHide={toggleDeleteDialog} handleDelete={announcementDeleted}/>
            <NewAnnouncementButtonRow className={"mt-3  "}>
                <Col className="mt-2" md={8} lg={5}>
                    <AnnouncementsMenuItem to={BASE_PATH + "admin/announcements/action"}>Create New Email Message
                        </AnnouncementsMenuItem>
                </Col>
            </NewAnnouncementButtonRow>

            <>

                {isLoading &&
                    <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>}
                {error && <p>Error</p>}
                {announcements && buildAnnouncements(announcements)}
            </>
        </Container>
    )
}