/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminReporting
 *
 * Purpose: Admin Reports
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 26Mar2024 sasjxa file created
 * 09May2024 sasjxa add button for user report (MPM-5472)
 * 31Jan2025 craig  Added gold standard report (comment out for now)
 \******************************************************************************/
import React from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper} from "../../components/styled/StyledComponents";
import axios from "axios";


export const AdminReporting: React.FC = () => {


    /**
     * handle error
     *
     * @param error - error object
     */
    const handleError = (error: any) => {
        console.log("error");
    }

    /**
     * handle success - get the filename and setup the link and "click" it.  A hack, but only way to do this
     *
     * @param response - the blob object from the ajax call
     */
    const handleSuccess = (response: any) => {
        if (response != null) {
            let filename: string = JSON.parse(response.headers.get('Content-Disposition').split('filename=')[1]);
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode !== null) {
                link.parentNode.removeChild(link);
            }
        }
    }

    // get the metadata report
    const metadataRequestURL = process.env.REACT_APP_API_URL + "/api/admin/dashboard/reporting/metadata";
    const getMetadataReport = async () => {
        try {
            const response = await axios({
                url: metadataRequestURL,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }

    // get the user report
    const userRequestURL = process.env.REACT_APP_API_URL + "/api/admin/dashboard/reporting/user";
    const getUserReport = async () => {
        try {
            const response = await axios({
                url: userRequestURL,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }

    // get the gold standard report
    const goldStandardRequestURL = process.env.REACT_APP_API_URL + "/api/admin/dashboard/reporting/goldstandard";
    const getGoldStandardReport = async () => {
        try {
            const response = await axios({
                url: goldStandardRequestURL,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }



    // build the report section
    const buildSection = () => {
        return (
            <>
                <Row>
                    <Col className="grid-header grid-entry space-3"> <Button
                        onClick={() => getMetadataReport()}>Metadata</Button></Col>

                    <Col className="grid-header grid-entry space-3"> <Button
                        onClick={() => getUserReport()}>User</Button></Col>
{/*
                    <Col className="grid-header grid-entry space-3"> <Button
                        onClick={() => getGoldStandardReport()}>Gold Standard</Button></Col>*/}
                </Row>
            </>
        )

    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
