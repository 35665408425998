/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminSiteMessageAction
 *
 * Purpose: Admin Site Message Processing
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 08Dec2024 sasjxa file created
 \****************************************************************************************************/
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper, TextAreaCountRow} from "../../components/styled/StyledComponents";

import {SUBMIT,} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {CancelAndSubmitButtonContainer} from "../../components/buttonContainer/CancelAndSubmitButtonContainer";
import {IAdminSiteMessage} from "../../model/announcements/IAdminSiteMessage";
import {PDSEditor} from "../../components/editor/PDSEditor";

export const AdminSiteMessageAction: React.FC = () => {
    const {siteMessageId} = useParams();
    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
    const [siteMessage, setSiteMessage] = useState<IAdminSiteMessage>();
    const [message, setMessage] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const navigate = useNavigate();
    const editorRef = useRef<any>(null);

    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    const handleError = (error: object) => {
        console.log("Could not get site message data");
    }

    // handle successful save of data
    const handleSuccess = () => {
        navigate(BASE_PATH + 'admin/siteMessages/ACTIVE')
    }

    // handle cancel
    const handleResetAction = () => {
        navigate(BASE_PATH + 'admin/siteMessages/ACTIVE')
    }

    // handle successful retrieve of data
    const handleGetSiteMessage = (siteMessage: IAdminSiteMessage) => {
        if (siteMessage !== null) {
            setMessage(siteMessage.message);
            setSiteMessage(siteMessage);
            setStartDate(new Date(siteMessage.startDate).toISOString().substring(0, 10));;
            setEndDate(new Date(siteMessage.endDate).toISOString().substring(0, 10));;

        }
    }


    //get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/admin/siteMessages/find/" + siteMessageId;
    const [requestState, getSiteMessage] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: false,
        onError: handleError,
        onSuccess: handleGetSiteMessage,

    })
    const {isLoading, data, error} = requestState;

    // save new site message
    const saveSiteMessageURL = process.env.REACT_APP_API_URL + "/api/admin/siteMessages/addOrEditSiteMessage";
    const [newEmailActionState, setSiteMessageEvent] = useRequest({
        url: saveSiteMessageURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if (siteMessageId != null && siteMessage !== null) {
            getSiteMessage();
        }
    }, [])


    // handle submit action.. cannot do in form because of special comment editor
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (siteMessageId != null) {
            setSiteMessageEvent(JSON.stringify({
                id: siteMessageId,
                startDate: startDate,
                endDate: endDate,
                message: getEncodedValue(editorRef?.current?.getData())
            }));
        } else {
            setSiteMessageEvent(JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                message: getEncodedValue(editorRef?.current?.getData())
            }));
        }
    }


    const buildForm = () => {
        return <Form className="form-layout"
                     onSubmit={(event) => handleSubmit(event)} noValidate={true}>
            <FormLayoutColumn className="mx-auto" lg={12}>
                <FormLayoutColumn className="me-5 " lg={8}>
                    <Row className=" mt-3 mb-3 w-100">
                        <Form.Group as={Col} lg={6}
                                    controlId="formAction">
                            <Form.Label className="required" column="sm">Start Date </Form.Label>
                            <Form.Control
                                type = "date"
                                name={"startDate"}
                                value={startDate}
                                onChange={e => {
                                        setStartDate(e.target.value);

                                }}>

                            </Form.Control>
                        </Form.Group>
                    </Row>

                    <Row className=" mt-3 mb-3 w-100">
                        <Form.Group as={Col} lg={6}
                                    controlId="formAction">
                            <Form.Label className="required" column="sm">End Date </Form.Label>
                            <Form.Control
                                type = "date"
                                name={"endDate"}
                                value={endDate}
                                onChange={e => {
                                    setEndDate(e.target.value);

                                }}>
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3 mb-5 ">
                        <Form.Group as={Col} lg={8}
                                    controlId="formMessage">
                            <TextAreaCountRow>
                                <Form.Label className="" column="sm">Message </Form.Label>
                            </TextAreaCountRow>

                            <PDSEditor
                                reference={editorRef}
                                editorLoaded={editorLoaded}
                                disabled={false}
                                name={"message"}
                                value={message}/>
                        </Form.Group>
                    </Row>

                </FormLayoutColumn>
            </FormLayoutColumn>
            <CancelAndSubmitButtonContainer handleReset={handleResetAction} submitButtonText={SUBMIT}/>
        </Form>
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {siteMessageId != null && (
                            <>
                                {isLoading && (
                                    <>
                                        <Spinner className="spinner-center" animation={"border"} variant={"primary"}
                                                 role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </>
                                )}
                                {error && <p>Error</p>}
                                {data && buildForm()}
                            </>

                        )}
                        {siteMessageId  == null && (
                            buildForm()
                        )}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
