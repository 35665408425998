/******************************************************************************\
 * :$
 *
 * Copyright(c) 2025 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: axiosClientUtility
 *
 * Purpose: utility class to make axios calls to PDS API
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 13Jan2025 craig File created.
 \******************************************************************************/
import axiosInstance from "../../managers/axiosClient/axiosInstance";
import {AxiosError} from "axios";
import {IPDSErrorResponse} from "../../model/error/IPDSErrorResponse";


type ApiResponse<T> = {
    data: T | null;
    error?: AxiosError<IPDSErrorResponse>;
};

/**
 * getPDSData - get pds data from the api
 *
 * @param url - url to call
 */
export const getPDSData = async <T>(url: string): Promise<ApiResponse<T>> => {
    try {
        const response = await axiosInstance.get<T, any>(url);
        return { data: response };
    } catch (error: any) {
        return {
            data: null,
            error: error,
        };
    }
};

/**
 * postPDSData - post data to API
 *
 * @param url - url to post to
 * @param payload - data to post
 */
export const postPDSData = async <T, P>(
    url: string,
    payload: P
): Promise<ApiResponse<T>> => {
    try {
        const response = await axiosInstance.post<T, any>(url, payload);
        return { data: response };
    } catch (error: any) {
        return {
            data: null,
            //error: error.response?.data?.detail || 'Something went wrong!',
            error: error,
        };
    }
};