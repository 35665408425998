/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminAnnouncementAction
 *
 * Purpose: Admin Announcement Processing
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 02Dec2024 sasjxa file created
 * 22Dec2024 sasjxa fix issue with new email not launching form
 \****************************************************************************************************/
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper, TextAreaCountRow} from "../../components/styled/StyledComponents";

import {SUBMIT,} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {PDSEditor} from "../../components/editor/PDSEditor";
import {BASE_PATH} from "../../constants";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {IAdminAnnouncement} from "../../model/announcements/IAdminAnnouncement";
import {CancelAndSubmitButtonContainer} from "../../components/buttonContainer/CancelAndSubmitButtonContainer";

export const AdminAnnouncementAction: React.FC = () => {
    const {announcementId} = useParams();
    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
    const [announcement, setAnnouncement] = useState<IAdminAnnouncement>();
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [disabled, setDisabled] = useState(false);
    const navigate = useNavigate();
    const editorRef = useRef<any>(null);

    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    const handleError = (error: object) => {
        console.log("Could not get announcement data");
    }

    // handle successful save of data
    const handleSuccess = () => {
        navigate(BASE_PATH + 'admin/announcements')
    }

    // handle cancel
    const handleResetAction = () => {
        navigate(BASE_PATH + 'admin/announcements')
    }

    // handle successful retrieve of data
    const handleGetAnnouncement = (announcement: IAdminAnnouncement) => {
        if (announcement !== null) {
            setSubject(announcement.subject);
            setMessage(announcement.message);
            setAnnouncement(announcement);
            if (announcement.sent != null) {
                setDisabled(true);
            }
        }
    }

    //get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/admin/announcements/find/" + announcementId;
    const [requestState, getAnnouncement] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: false,
        onError: handleError,
        onSuccess: handleGetAnnouncement
    })
    const {isLoading, data, error} = requestState;

    // save announcement
    const saveAnnouncementURL = process.env.REACT_APP_API_URL + "/api/admin/announcements/addOrEditMessage";
    const [newEmailActionState, setAddOrEditAnnouncement] = useRequest({
        url: saveAnnouncementURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if (announcementId != null && announcement !== null) {
            getAnnouncement();
        }
    }, [])


    // handle submit action.. cannot do in form because of special comment editor
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (announcementId != null) {
            setAddOrEditAnnouncement(JSON.stringify({
                id: announcementId,
                subject: subject, message: getEncodedValue(editorRef?.current?.getData())
            }));
        } else {
            setAddOrEditAnnouncement(JSON.stringify({
                subject: subject, message: getEncodedValue(editorRef?.current?.getData())
            }));
        }

    }

    const buildForm = () => {
        return <Form className="form-layout" onSubmit={(event) => handleSubmit(event)} noValidate={true}>
            <FormLayoutColumn className="mx-auto" lg={12}>
                <FormLayoutColumn className="me-5 " lg={8}>
                    <Row className=" mt-3 mb-3 w-100">
                        <Form.Group as={Col} lg={6}
                                    controlId="formAction">
                            <Form.Label className="required" column="sm">Subject </Form.Label>
                            <Form.Control
                                name={"subject"}
                                value={subject}
                                disabled={disabled}
                                onChange={e => {
                                    setSubject(e.target.value);
                                }}>

                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3 mb-5 ">
                        <Form.Group as={Col} lg={8}
                                    controlId="formMessage">
                            <TextAreaCountRow>
                                <Form.Label className="" column="sm">Message </Form.Label>
                            </TextAreaCountRow>

                            <PDSEditor
                                reference={editorRef}
                                editorLoaded={editorLoaded}
                                disabled={disabled}
                                name={"message"}
                                value={message}/>
                        </Form.Group>
                    </Row>
                </FormLayoutColumn>
            </FormLayoutColumn>
            {!disabled && (
                <CancelAndSubmitButtonContainer handleReset={handleResetAction} submitButtonText={SUBMIT}/>
            )}

        </Form>
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {announcementId != null && (
                            <>
                                {isLoading && (
                                    <>
                                        <Spinner className="spinner-center" animation={"border"} variant={"primary"}
                                                 role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </>
                                )}
                                {error && <p>Error</p>}
                                {data && buildForm()}
                            </>

                        )}
                        {announcementId == null && (
                            buildForm()
                        )}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
