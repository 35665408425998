/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminAdditionalResource
 *
 * Purpose: display additional resources
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05Dec2024 sasjxa file created
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import {NavLink, useParams} from "react-router-dom";

import {ADDITIONAL_RESOURCES_HEADERS,} from "../../data/formList";
import {
    AnnouncementsMenuItem,
    GridRow,
    NewAnnouncementButtonRow,
    PDS_P_BOLD,
} from "../../components/styled/StyledComponents";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {IAdminAdditionalResource} from "../../model/announcements/IAdminAdditionalResource";

export const AdminAdditionalResource: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const {resourceType} = useParams();
    const [additionalResources, setAdditionalResources] = useState<IAdminAdditionalResource[]>([]);

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // get data
    const requestsURL = process.env.REACT_APP_API_URL + "/api/admin/additionalResources/getAdditionalResources/" + resourceType;
    const [requestState, getAdditionalResources] = useRequest({
        url: requestsURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError
    })
    const {isLoading, data, error} = requestState;


    useMemo(() => {
        getAdditionalResources();
    }, [])

    useMemo(() => {
        if (data != null) {
            setAdditionalResources(data);
        }
    }, [data])


    // build additional resources header
    const buildAdditionalResourcesHeaders = () => {
        return (
            <>
                <GridRow className="mt-3">
                    <Col className="grid-header grid-entry space-3 ">Label</Col>
                    <Col className="grid-header grid-entry space-4 ">Description</Col>
                    <Col className="grid-header grid-entry  space-2">Edit</Col>
                </GridRow>
            </>
        )
    }


    // build additional resources
    const buildAdditionalResources = () => {
        return (
            <>
                {additionalResources?.map((additionalResource: IAdminAdditionalResource) => (
                    <GridRow className="mb-3">
                        <Col className="grid-entry space-3">
                            <a href={additionalResource.url} target={"_blank"} >{additionalResource.title}</a>

                        </Col>
                        <Col className="grid-entry space-4">{additionalResource.description}</Col>
                        <Col className="grid-entry space-2">
                            <NavLink to={BASE_PATH + "admin/additionalResources/action/" + additionalResource.id}
                                     className={"linkstyle "}>Edit</NavLink>
                        </Col>
                    </GridRow>
                ))}
            </>
        )
    }

    const buildSection = () => {
        if (additionalResources.length) {
            return (
                <>
                    <Col className="mb-3">
                        {buildAdditionalResourcesHeaders()}
                        {buildAdditionalResources()}
                    </Col>
                </>
            )
        } else {
            return (
                <PDS_P_BOLD>No {resourceType?.toLocaleLowerCase()} resource items at this time</PDS_P_BOLD>
            )
        }
    }


    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={ADDITIONAL_RESOURCES_HEADERS} requestType={resourceType}
                               countsUrl={"/api/admin/dashboard/counts/additionalResource"}/>
            <NewAnnouncementButtonRow className={"mt-3  "}>
                <Col className="mt-2" md={8} lg={5}>
                    <AnnouncementsMenuItem to={BASE_PATH + "admin/additionalResources/action"}>Create New Resource
                        Item</AnnouncementsMenuItem>
                </Col>
            </NewAnnouncementButtonRow>
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}

        </Container>
    )
}