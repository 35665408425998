/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminTicketsAction
 *
 * Purpose: Admin Ticket Processing
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 29Nov2023 sasjxa file created
 * 05Dec2023 sasjxa add methods to access ticket event information
 * 14Dec2023 sasjxa add support for sorting request date
 * 15Dec2023 sasjxa add function for getting user name to handle users not logged in for contact us
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 craig  added base_path
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 22Apr2024 sasjxa use TicketEventsAndContactUs component to display events and contact us (MPM-5469)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 23May2024 sasjxa correction to loading parameter
 * 04Jun2024 craig  Fixed bug with error handling (showBoundary caused ckeditor to flash)
 * 06Jun2024 craig  Fixed onsubmit bug which need preventDefaultd
 * 02Jul2024 craig  removed decode
 * 23Jan2025 craig  changed to new axios API
 \****************************************************************************************************/
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, PageWrapper, TextAreaCountRow} from "../../components/styled/StyledComponents";

import {
    ADMIN_TICKETS_ACTIONS,
    ADMIN_TICKETS_ACTIONS_CLOSED,
    ADMIN_TICKETS_ACTIONS_WITHOUT_PROGRESS,
    CLOSED,
    FormList,
    OPEN,
    SPAM,
    STARTED,
    SUBMIT,
} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {ITicket, ITicketEvent} from "../../model/tickets/ITickets";
import {PDSEditor} from "../../components/editor/PDSEditor";
import {BASE_PATH} from "../../constants";
import {TicketEventsAndContactUs} from "../../components/forms/TicketEventsAndContactUs";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {PdsApi} from "../../hooks/axiosClient/pdsApi";
import {postPDSData} from "../../utilities/axiosClient/axiosClientUtility";

export const AdminTicketAction: React.FC = () => {
    const {ticketId, requestType} = useParams();
    const [editorLoaded, setEditorLoaded] = useState<boolean>(false);
    const requestsURL = process.env.REACT_APP_API_URL + "/api/user/tickets/processTicket/" + ticketId;
    const [ticket, setTicket] = useState<ITicket>();
    const [action, setAction] = useState("");
    const [comment, setComment] = useState("");
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);
    const navigate = useNavigate();
    const SPAM_MESSAGE = "This is spam and will be close without notification or email sent."
    const editorRef = useRef<any>(null);

    const {data, loading, error} = PdsApi<ITicket>(requestsURL);

    /**
     * set the ckeditor as loaded
     */
    useEffect(() => {
        setEditorLoaded(true);
    }, []);

    /**
     * setup the ticket data
     */
    useMemo(() => {
        if (data !== null) {
            if (requestType === OPEN) {
                setAction(STARTED);
            }
            setComment("");
            setTicket(data);
        }
    }, [data]);

    /**
     * build select list based on request type
     */
    const buildSelectList = () => {
        let listType: FormList[];
        if (requestType === OPEN) {
            listType = ADMIN_TICKETS_ACTIONS;
        } else if (requestType === CLOSED) {
            listType = ADMIN_TICKETS_ACTIONS_CLOSED;
        } else {
            listType = ADMIN_TICKETS_ACTIONS_WITHOUT_PROGRESS;
        }

        return listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
    }


    /**
     * display comment for spam if spam selected as option
     *
     * @param value - action value
     */
    const handleActionChanged = (value: string) => {
        if (value === SPAM) {
            setComment(SPAM_MESSAGE);
        } else {
            setComment("");
        }
        setAction(value);
    }

    /**
     * build the section
     */
    const buildSection = () => {
        return (
            <>
                {buildForm()}
                <Col>
                    <TicketEventsAndContactUs ticket={ticket}/>
                </Col>
            </>
        )
    }

    /**
     * handle submit action.. cannot do in form because of special comment editor
     * @param event - form event
     */
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setIsWaitingForResponse(true);
        const saveTicketEventURL = process.env.REACT_APP_API_URL + "/api/admin/tickets/ticketEvent/" + ticketId;
        const {data, error} = await postPDSData<ITicketEvent, string>
        (saveTicketEventURL,
            JSON.stringify({
                event: action, comment: getEncodedValue(editorRef?.current?.getData())
            }));
        if (data !== null) {
            navigate(BASE_PATH + 'admin/tickets/' + requestType)
        } else if (error) {
            console.error(error.response?.data.detail);
        }
        setIsWaitingForResponse(false);
    }

    /**
     * build the form
     */
    const buildForm = () => {
        return <Form className="form-layout" onSubmit={(event) => handleSubmit(event)} noValidate={true}>
            <FormLayoutColumn className="mx-auto" lg={12}>
                <FormLayoutColumn className="me-5 " lg={8}>
                    <Row className=" mt-3 mb-3 w-100">
                        <Form.Group as={Col} lg={6}
                                    controlId="formAction">
                            <Form.Label className="required" column="sm">Actions </Form.Label>
                            <Form.Select size="sm"
                                         name={"action"}
                                         value={action}
                                         onChange={e => {
                                             setAction(e.target.value);
                                             handleActionChanged(e.target.value);
                                         }}>
                                {buildSelectList()}
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mt-3 mb-5 ">
                        <Form.Group as={Col} lg={8}
                                    controlId="formComments">
                            <TextAreaCountRow>
                                <Form.Label className="" column="sm">Comments </Form.Label>
                            </TextAreaCountRow>

                            <PDSEditor
                                reference={editorRef}
                                editorLoaded={editorLoaded}
                                disabled={false}
                                name={"comment"}
                                value={comment}/>
                        </Form.Group>
                    </Row>
                </FormLayoutColumn>
            </FormLayoutColumn>
            <ClearAndSubmitButtonContainer enableClear={false} submitButtonText={SUBMIT}
                                           isSubmitting={isWaitingForResponse}/>
        </Form>
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn>
                        {loading &&
                            <Spinner className="spinner-center" animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>}
                        {error && <p>Error</p>}
                        {ticket && buildSection()}
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
