/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ShareDataSelectFile
 *
 * Purpose: Select files to be uploaded for data sharing
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 20Jun2023 sasjxa file created
 * 29Sep2023 craig  added files as parameter
 * 30Sep2023 craig  get file upload working
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 07Nov2024 sasjxa use styleComponent for roundSize
 \******************************************************************************/
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    ContentPage, FormLayoutColumn, LinkButton, PageWrapper, roundFileSize
} from "../styled/StyledComponents";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {InfoDialog} from "../dialogs/InfoDialog";
import {Formik} from "formik";
import {
    DATA_TYPE_COMPARATOR_RAW,

    FILES_SELECT,
    FormList,
    SelectFilesList,
} from "../../data/formList";
import * as Yup from "yup";
import {IInfoDialog,} from "../../data/infoDialog";
import {useRequest} from "../../helper/useRequest";
import {IFile} from "../../model/donations/IAccessData";
import {ConfirmDialog} from "../dialogs/ConfirmDialog";
import {IShareDataDashboard} from "../../model/share/IShare";
import {BASE_PATH} from "../../constants";

interface ShareDataSelectFilesProps {
    donationId: string | undefined,
    infoDialog: IInfoDialog[],
    label: string,
    files: IFile[],
    dashboard: IShareDataDashboard | undefined,
    fileType: string,
    validFileTypes: string
};

interface IDataFiles {
    file: File,
    fileId: number,
    name: string,
    size: number,
    type: string;

};

export const ShareDataSelectFile: React.FC<ShareDataSelectFilesProps> = ({
                                                                             donationId,
                                                                             infoDialog,
                                                                             dashboard,
                                                                             files,
                                                                             label, fileType, validFileTypes,

                                                                         }: ShareDataSelectFilesProps) => {
    const navigate = useNavigate();
    const [showFileTypes, setShowFileTypes] = useState<boolean>(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [shareDataFiles, setShareDataFiles] = useState<IDataFiles[]>([]);
    const [fileTypeArray, setFileTypeArray] = useState<IInfoDialog[]>([]);
    const inputRefFile = useRef<HTMLInputElement>(null);
    const [uploadFile, setUploadFile] = useState<IDataFiles | null>(null);

    const [allowDelete, setAllowDelete] = useState<boolean>(true);
    const [deleteFileId, setDeleteFileId] = useState<number>(-1);


    /**
     * useMemo - Files - populate files data with "files" parameter
     */
    useMemo(() => {
        if (files !== null && files.length > 0) {
            //shareDataFiles.map((dataFile, index) =>
            files.map((f: IFile) => {
                let dataFiles = {} as IDataFiles;
                dataFiles.fileId = f.id;
                dataFiles.name = f.filename;
                dataFiles.size = f.fileSize;
                dataFiles.type = f.fileType;
                setShareDataFiles(current => [...current, dataFiles]);
            })

            if (dashboard?.submitted) {
                setAllowDelete(false);
            }
        }
    }, [files])

    //-----------------------------------------------------------------------------------------
    // CHANGE FILE TYPE METHODS
    //-----------------------------------------------------------------------------------------

    const buildSelectList = () => {
        // default value
        let listType: FormList[] = DATA_TYPE_COMPARATOR_RAW;

        let searched_obj: SelectFilesList | undefined = FILES_SELECT.find(findSelectList);
        if (searched_obj != null) {
            listType = searched_obj.valueList;
        }

        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const findSelectList = (fileEntry: SelectFilesList) => {
        if (fileEntry.dsaType === dashboard?.dataType && fileEntry.studyContent === dashboard?.studyContent) {
            return true;
        }
        return false;
    }

    const handleChangeFileType = (event: React.ChangeEvent<HTMLSelectElement>, file: any, index: number) => {
        file.fileType = event.target.value;
        let data: FormData = new FormData();
        data.append("fileId", file.fileId);
        data.append("fileType", file.fileType);

        setChangeTypeAction(data);
    }

    // handle change type success
    const handleChangeTypeSuccess = (file: IFile) => {
        const updatedDataFiles = shareDataFiles.map(dataFile => {
            if (dataFile.fileId === file.id) {
                // update file type
                return {
                    ...dataFile,
                    type: file.fileType,
                };

            } else {
                return dataFile;
            }
        });
        // Re-render with the new array
        setShareDataFiles(updatedDataFiles);
        console.log('foundFile');
    }

    // handle change type error
    const handleChangeTypeError = (error: object) => {
        console.log(error);
    }

    const [requestChangeTypeActionState, setChangeTypeAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/share/settype/" + donationId,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleChangeTypeError,
        onSuccess: handleChangeTypeSuccess
    });


    //-----------------------------------------------------------------------------------------
    // DELETE FILE METHODS
    //-----------------------------------------------------------------------------------------

    /**
     * Handle click on delete file icon (sets state variable and opens dialog)
     *
     * @param fileId - file id to delete
     */
    const handleDeleteDialog = (fileId: number) => {
        setDeleteFileId(fileId);
        setShowDelete(true);
    };

    /**
     * toggleDeleteDialog - show/hide the delete file dialog
     */
    const toggleDeleteDialog = () => {
        setShowDelete(!showDelete);
    }


    // handle delete file success
    const handleDeleteFileSuccess = (file: IFile) => {

        //remove item from files list
        //console.log(message);
        //const newFileList = shareDataFiles.filter(item => item.fileId != file.id);
        setShareDataFiles(shareDataFiles.filter(item => item.fileId !== file.id));
    }

    // handle delete file error
    const handleDeleteFileError = (error: object) => {
        console.log(error);
    }

    // request to call API to delete the file
    const [requestDeleteActionState, setDeleteFileAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/share/delete/" + donationId,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleDeleteFileError,
        onSuccess: handleDeleteFileSuccess
    });


    // handle file delete
    const handleFileDelete = (event: any) => {
        toggleDeleteDialog();
        let data: FormData = new FormData();
        data.append("fileId", deleteFileId.toString());

        setDeleteFileAction(data);
    };

    //-----------------------------------------------------------------------------------------
    // UPLOAD FILE STUFF
    //-----------------------------------------------------------------------------------------

    /**
     * handleUploadSuccess - handle a successful file upload
     *
     * @param file - file object returned by api
     */
    const handleUploadSuccess = (file: IFile) => {
        let dataFiles = {} as IDataFiles;
        dataFiles.fileId = file.id;
        dataFiles.name = file.filename;
        dataFiles.size = file.fileSize;
        dataFiles.type = file.fileType;
        setShareDataFiles(current => [...current, dataFiles]);
    }

    // handle upload error
    const handleUploadError = (error: object) => {
        console.log(error);
    }

    /**
     * handleFileSelectChange - event from file selector
     * @param event - event
     * @param fileType - file type
     */
    const handleFileSelectChange = (event: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
        const {files} = event.target;
        const selectedFiles = files as FileList;

        let dataFile = {} as IDataFiles;
        dataFile.name = selectedFiles[0].name;
        dataFile.size = selectedFiles[0].size;
        dataFile.type = fileType;
        dataFile.file = selectedFiles?.[0];

        setUploadFile(dataFile);
    };


    //upload file request
    const [requestActionState, setUpdateFileAction] = useRequest({
        url: process.env.REACT_APP_API_URL + "/api/share/upload/" + donationId,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        headers: {
            'Accept': "application/json",
            'Content-Type': "multipart/form-data",
            'Access-Control_Allow-Origin': "*",
            'Access-Control-Allow-Credentials': "true",
        },
        onError: handleUploadError,
        onSuccess: handleUploadSuccess
    });



    useEffect(() => {
        if (uploadFile) {
            let data: FormData = new FormData();
            data.append("file", uploadFile.file);
            data.append("fileType", uploadFile.type);
            setUpdateFileAction(data);
        }
    }, [uploadFile]);

    const handleDataFilesUpload = () => {
        inputRefFile.current?.click();
    };


    //-----------------------------------------------------------------------------------------
    // MISC METHODS
    //-----------------------------------------------------------------------------------------

    const handleFileTypeDialog = (fileTypes: IInfoDialog[]) => {
        setFileTypeArray(fileTypes);
        setShowFileTypes(true);
    };




    /**
     * toggleFileTypesDialog  - toggle the file type dialog
     */
    const toggleFileTypesDialog = () => {
        setShowFileTypes(!showFileTypes);
    }


    const createLinkButton = (dialog: IInfoDialog[], buttonLabel: string) => {
        return (
            <LinkButton type="button"
                        className="btn btn-link bold-label "
                        onClick={() => {
                            handleFileTypeDialog(dialog);
                        }}>
                {buttonLabel}

            </LinkButton>
        );
    }

    const buildFileSelector = () => {
        if (fileType === "DATA_FILES") {
            return (
                <div className="selector-div">
                    <button onClick={handleDataFilesUpload}
                            className="btn btn-link select-files-link">
                        Click here to upload
                    </button>
                    <input ref={inputRefFile} className="d-none" accept={validFileTypes}
                           onChange={(event) => handleFileSelectChange(event, "UNKNOWN")} type="file"/>
                </div>
            )
        } else {
            return (
                <div className="selector-div">
                    <label
                        className="btn btn-link select-files-link">
                        Click here to upload

                        <input ref={inputRefFile} className="d-none" accept={validFileTypes}
                               onChange={(event) => handleFileSelectChange(event, fileType)} type="file"/>
                    </label>
                </div>
            )
        }
    }

    const buildFileDisplay = () => {
        if (fileType === "DATA_FILES") {
            return (
                <>
                    {buildFileSelector()}
                    {shareDataFiles.map((dataFile, index) =>
                        <div className="selector-div">
                            <div className="select-files">
                                {allowDelete && (
                                    <>

                                        <Form.Label column="sm" className="select-files-background">
                                            {dataFile.name} ({roundFileSize(dataFile.size)} {dataFile.name.split('.').pop()})
                                        </Form.Label>
                                        <Button size="sm" variant="outline-*" className="btn btn-primary-outline"
                                                onClick={() => handleDeleteDialog(dataFile.fileId)}>
                                            <img src={"/images/icons/delete_18.png"} alt="add item" width="18"/>
                                        </Button>
                                    </>
                                )}
                                {!allowDelete && (
                                    <>
                                        <Form.Label column="sm" className="select-files-background">
                                            {dataFile.name} ({roundFileSize(dataFile.size)} {dataFile.name.split('.').pop()})
                                            - {dataFile.type}
                                        </Form.Label>

                                    </>
                                )}

                            </div>

                            {allowDelete && (
                                <div className={"selectorFileType-div"}>
                                    <Form.Label className="required" column="sm">Data Type </Form.Label>


                                    <Form.Select size="sm" required
                                                 name={"fileUploaded"}
                                                 value={dataFile?.type}
                                                 onChange={e => {
                                                     handleChangeFileType(e, dataFile, index);
                                                 }}>
                                        {buildSelectList()}
                                    </Form.Select>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )
        } else {
            return (
                <div className="selector-div">
                    <div className="select-files">
                        <Form.Label column="sm" className="select-files-background">
                            {shareDataFiles[0].name} ({roundFileSize(shareDataFiles[0].size)} {shareDataFiles[0].name.split('.').pop()})
                        </Form.Label>

                        {allowDelete && (
                            <Button size="sm" variant="outline-*"
                                    className="btn btn-primary-outline"
                                    onClick={() => handleDeleteDialog(shareDataFiles[0].fileId)}>
                                <img src={"/images/icons/delete_18.png"} alt="add item"
                                     width="18"/>
                            </Button>
                        )}

                    </div>
                </div>
            )
        }
    }


    const schema = Yup.object().shape({
        fileUploaded: Yup.mixed().required('Please select a file'),
    });


    const buildForm = () => {
        return (
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    console.log("submitted")
                    navigate(BASE_PATH + '/share');
                }}
                initialValues={{
                    initialFileUploaded: null,
                    crfUploaded: null,
                    ddUploaded: null,
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mx-auto">
                                    <Row className="mx-auto mb-3">
                                        <Row className="">
                                            <Form.Group as={Col} lg={12} className="mb-2 "
                                                        controlId="formPUpload">

                                                {createLinkButton(infoDialog, label)}
                                                {shareDataFiles.length === 0 && (
                                                    buildFileSelector()
                                                )}


                                                {shareDataFiles.length > 0 && (
                                                    buildFileDisplay()
                                                )}
                                            </Form.Group>
                                        </Row>
                                    </Row>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                    </Form>
                )}
            </Formik>
        )
    }

    const buildConfirmDialog = () => {
        if (allowDelete) {
            return <ConfirmDialog title={"Delete File"} body={<p>Are you sure that you want to delete the file?</p>}
                                  show={showDelete} showHide={toggleDeleteDialog} submitAction={handleFileDelete}
                                  primaryButtonText={"Yes"}
                                  secondaryButtonText={"No"}/>


        } else {
            return <></>
        }
    }


    return (
        <Container className="form-container" fluid>
            <InfoDialog title={"Valid File Types"} body={<p>pdf</p>} fileTypes={fileTypeArray}
                        show={showFileTypes} showHide={toggleFileTypesDialog}/>
            {buildConfirmDialog()}
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <Col md={10} lg={12} name={"shareDataColumn"}>
                        {buildForm()}
                    </Col>
                </PageWrapper>
            </ContentPage>
        </Container>
    );
}