/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: SecureDownload
 *
 * Purpose: component for building the download link and handling the file download
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 13Nov2023 craig  file created
 * 20Feb2024 sasjxa create styled component for alignment purposes
 * 15Dec2024 craig  Added downloadUrl for NCI zip files
 \******************************************************************************/
import React from "react";
import {IAccessData, IFile} from "../../model/donations/IAccessData";
import axios from "axios";
import styled from "styled-components";
import {COLORS} from "../styled/StyleConstants";

const SecureDownLoadCol = styled.div`                                         
                                                              
  padding: 0;                                                                      
  margin: 0;                                                                       
  text-align: right;

  a {
    font-size: .8125rem;
    text-decoration: underline !important;  
    color:  ${COLORS.cerulean} !important;
  }
`;

interface SecureDownloadProps {
    donation: IAccessData,
    file: IFile
}

export const SecureDownload: React.FC<SecureDownloadProps> = ({
                                                                  donation,
                                                                  file

                                                              }: SecureDownloadProps) => {

    /**
     * handleError - from axios download file request
     * TODO - file out proper error message
     *
     * @param error - error object
     */
    const handleError = (error: any) => {
        //console.log(error);
    }

    /**
     * handleSuccess - we were able to get the file, now simulate the download click (very kludgy, but it seems to be
     * the way to do this in react (at least I could not find another way)
     *
     * @param response - response from axios
     */
    const handleSuccess = (response: any) => {
        //const file = new File(response.data, response.file);
        //const url = window.URL.createObjectURL(new Blob([response]));
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.filename);
        document.body.appendChild(link);
        link.click();
        if (link.parentNode !== null) {
            link.parentNode.removeChild(link);
        }
    }

    /**
     * build the download url based on if this is a file or a "all data" zip file
     */
    let downloadUrl = process.env.REACT_APP_API_URL + "/api/file/download/";
    if (file.id !== null && file.id > 0) {
        downloadUrl += donation.donationId + "/" + file.id;
    } else {
        if (donation.isNci) {
            downloadUrl += "zip/" + donation.donationId + "/"  + file.datasetId + "/" + file.fileType;
        } else {
            downloadUrl += "zip/" + donation.donationId + "/" + file.fileType;
        }
    }

    /**
     * call the url to download the file
     */
    const downloadFile = async () => {
        try {
            const response = await axios({
                url: downloadUrl,
                method: 'GET',
                withCredentials: true,
                responseType: 'blob', // important
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                    'Access-Control_Allow-Origin': "*",
                    'Access-Control-Allow-Credentials': "true"
                }
            })
            handleSuccess(response);
        } catch (error) {
            handleError(error);
        }
    }


    /**
     * buildDownloadLink - build the "download" link
     */
    const buildDownloadLink = () => {
        if (file.downloadable) {
            return (
                <>
                    <SecureDownLoadCol>
                        <a onClick={() => downloadFile()} target="_blank">Download</a>
                    </SecureDownLoadCol>
                </>
            )
        }
    }

    /**
     * return the link
     */
    return (
        <>
            {buildDownloadLink()}
        </>
    )
}