/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDataFieldEditSelect
 *
 * Purpose: Edit dataset fields for components that support one or more values *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 04Oct2023 sasjxa  file created.
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {
    AdminEditableRow,
    AdminEditDisplayCol,
    AdminEditRow,
    ContentPage,
    PageWrapper,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";
import {Col, Container, Form, Row} from "react-bootstrap";


// @ts-ignore
import styled from "styled-components";
import {ErrorMessage, Formik} from "formik";
import {FormList, OTHER_OPTION,} from "../../data/formList";
import * as Yup from "yup";
import {useRequest} from "../../helper/useRequest";

import {AdminDataFieldEditButtonContainer} from "./AdminDataFieldEditButtonContainer";


interface AdminDataFieldEditSelectProps {
    label: string,
    fieldName: string,
    donationId: any,
    initialValues: string[],
    listValues: FormList[],
    otherFieldName?: any,
    otherValue?: string,
    adminEditForm: boolean
};


export const AdminDataFieldEditSelect: React.FC<AdminDataFieldEditSelectProps> = ({
                                                                                      label, fieldName, donationId,
                                                                                      initialValues,
                                                                                      listValues,
                                                                                      otherFieldName,
                                                                                      otherValue, adminEditForm
                                                                                  }: AdminDataFieldEditSelectProps) => {

    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [adminEditable, setAdminEditable] = useState<boolean>(adminEditForm);
    const [commentsCount, setCommentsCount] = useState(0);
    const [multipleValue, setMultipleValue] = useState<string[]>(initialValues);
    const [valueOther, setValueOther] = useState<string>("");
    const [comments, setComments] = useState<string>("");
    const [showValueOther, setShowValueOther] = useState<boolean>(false);
    const maxCommentCount = 1000;

    const handleSuccess = () => {
        setShowEditForm(false);
    }

    const handleError = (error: object) => {
        console.log(error);
    }

    // returns a string with the multiple values and the value of the other field if present
    const getNewValuesForLog = () => {
        if (multipleValue.length > 0) {
            const displayNames = [...multipleValue];
            if (otherValue) {
                displayNames.push(otherValue);

            }
            return (displayNames.join(', '));
        } else {
            return "";
        }
    }

    // create the file log
    const createLogEntry = () => {
        setLogAction(JSON.stringify({
            fieldName: fieldName,
            donationId: donationId,
            oldValue: getNonEditableDisplay(),
            newValue: getNewValuesForLog(),
            comments: comments
        }));
    }

    // save field value
    const saveAdminEditURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/updateFieldValue/" + donationId;
    const [requestActionState, setAdminEdit] = useRequest({
        url: saveAdminEditURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: createLogEntry
    })

    // save log
    const logURL = process.env.REACT_APP_API_URL + "/api/admin/adminEdit/logEntry";
    const [logActionState, setLogAction] = useRequest({
        url: logURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })


    useMemo(() => {
        setAdminEditable(adminEditForm);

        if (initialValues.includes(OTHER_OPTION)) {
            setShowValueOther(true);
            setValueOther(otherValue != null ? otherValue : "");
        }
    }, [adminEditForm])

    const toggleShowEditForm = () => {
        setShowEditForm(!showEditForm);
    }

    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    const handleFieldChanged = (value: string[]) => {
        if (value.includes(OTHER_OPTION)) {
            setShowValueOther(true);
        } else if (showValueOther) {
            setShowValueOther(false);
        }
    }

    const hasOtherField = () => {
        if (multipleValue.includes(OTHER_OPTION) && otherFieldName != null && otherValue != null) {
            return true;
        } else {
            return false;
        }
    }

    const handleOtherFieldChanged = (value: string) => {
        setValueOther(value);
    }

    // returns a string with the multiple values and the value of the other field if present
    const getNonEditableDisplay = () => {
        if (multipleValue.length) {
            return (getNewValuesForLog());
        } else if (initialValues.length > 0) {
            const displayNames = [...initialValues];
            if (otherValue) {
                displayNames.push(otherValue);

            }
            return (displayNames.join(', '));
        } else {
            return "";
        }
    }


    const schema = Yup.object().shape({
        comments: Yup.string().required('Please enter comments'),
    });


    const buildForm = () => {
        return (
            <Formik
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => {
                    setAdminEdit(JSON.stringify({
                        fieldName: fieldName,
                        multipleTypeOther: valueOther,
                        multipleValues: multipleValue,
                        comments: comments,
                    }));
                    toggleShowEditForm();


                }}
                initialValues={{
                    fieldName: fieldName,
                    multipleValues: initialValues,
                    multipleTypeOther: valueOther,
                    otherFieldName: "",
                    comments: "",
                }}

                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                      setFieldValue,
                      setFieldTouched
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <Row className="mt-3 ">
                            {showEditForm && adminEditable && (
                                <>
                                    <AdminEditableRow className="w-100 ps-3 pe-3">
                                        <Form.Group as={Col} lg={6} className="tumorType"
                                                    controlId="">
                                            <Form.Label className="required" column="sm">{label} </Form.Label>
                                            <Form.Select size="sm" required multiple={true}
                                                         name={"multipleValues"}
                                                         value={multipleValue}
                                                         isValid={touched.multipleValues && !errors.multipleValues}
                                                         onChange={e => {
                                                             const options = e.target.options;
                                                             let value = [];
                                                             for (let i = 0, l = options.length; i < l; i++) {
                                                                 if (options[i].selected) {
                                                                     value.push(options[i].value);
                                                                 }
                                                             }
                                                             setMultipleValue(value);
                                                             handleFieldChanged(value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(listValues)}
                                            </Form.Select>
                                            <Form.Text id="tumorTypesHelpBlock" muted>
                                                Ctrl + click to select multiple
                                            </Form.Text>

                                            <ErrorMessage name={"multipleValues"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </AdminEditableRow>

                                    {(hasOtherField() || showValueOther) && (
                                        <AdminEditableRow className="mt-3 mb-3 ">
                                            <Form.Group as={Col} lg={11}
                                                        controlId="">
                                                <Form.Label className="required"
                                                            column="sm">{otherFieldName}
                                                    (multiple
                                                    entries include ',') </Form.Label>
                                                <Form.Control size="sm" required
                                                              name={otherFieldName}
                                                              type="text"
                                                              value={valueOther}
                                                              isValid={touched.otherFieldName && !errors.otherFieldName}
                                                              onChange={e => {
                                                                  handleOtherFieldChanged(e.target.value);
                                                                  handleChange(e);
                                                              }}/>

                                                <Form.Text className="text-muted"></Form.Text>
                                                <ErrorMessage name={otherFieldName}
                                                              render={msg => <div
                                                                  className={"form-error-msg"}>{msg}</div>}/>
                                            </Form.Group>
                                        </AdminEditableRow>
                                    )}

                                    <AdminEditableRow className=" pb-3 ps-3  pe-3">
                                        <Form.Group as={Col} controlId="">
                                            <TextAreaCountRow>
                                                <Form.Label className="required"
                                                            column="sm">Comments </Form.Label>
                                            </TextAreaCountRow>
                                            <Form.Control as="textarea" rows={5} size="sm" required
                                                          name={"comments"}
                                                          type="text"
                                                          value={comments}
                                                          isValid={touched.comments && !errors.comments}
                                                          onChange={e => {
                                                              setComments(e.target.value);
                                                              setCommentsCount(e.target.value.length);
                                                              handleChange(e);
                                                          }}/>
                                            <ErrorMessage name={"comments"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </AdminEditableRow>
                                    <AdminDataFieldEditButtonContainer toggleMethod={toggleShowEditForm}/>
                                </>
                            )}
                            {!showEditForm && (
                                <AdminEditDisplayCol className=" mb-4">
                                    <AdminEditRow>
                                        <span className="bold">{label}</span>
                                        {adminEditable && (
                                            <button onClick={toggleShowEditForm}
                                                    className="btn btn-link select-files-link">
                                                edit
                                            </button>
                                        )}
                                    </AdminEditRow>
                                    <AdminEditRow>
                                        <span>{getNonEditableDisplay()}</span>
                                    </AdminEditRow>
                                </AdminEditDisplayCol>
                            )}
                        </Row>

                    </Form>
                )}
            </Formik>
        )
    }


    return (
        <>
            <Container className="form-container" fluid>
                <ContentPage name={"content-page"}>
                    <PageWrapper name={"page-wrapper"}>
                        <Col md={10} lg={12} name={"shareDataColumn"}>
                            {buildForm()}
                        </Col>
                    </PageWrapper>
                </ContentPage>
            </Container>
        </>
    );

}
