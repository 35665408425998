/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminTickets
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 18Aug2023 sasjxa file created
 * 29Nov2023 sasjxa add link to display user info
 * 07Dec2023 sasjxa use AdminConvertUser componet for users who need to be converted
 * 11Dec2023 sasjxa correct id field
 * 15Dec2023 sasjxa correct url field and add sort capability
 * 10Jan2024 sasjxa use new common component for header
 * 08Mar2024 craig  added base_path
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 23May2024 sasjxa add message for no in-progress tickets to
 * 07Jun2024 sasjxa fix getTicketEventStatusDate to return latest tech support date
 * 02Jul2024 craig  moved decode to api
 * 25Aug2024 craig  changed href to NavLink
 * 25Sep2024 sasjxa remove table border
 * 31Oct2024 sasjxa add component for displaying active directory/login info
 * 26Jan2025 craig  Changed to new axios API and add key to list
 * 08Feb2025 craig  Changed to grid row instead of html table tags
 \************************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {NavLink, useParams} from "react-router-dom";

import {
    ADMIN_TICKETS_STATUS,
    ASC,
    CLOSED,
    DESC,
    FormList,
    IN_PROGRESS,
    OPEN,
    TICKET_HEADERS
} from "../../data/formList";
import {
    AdminTableHeader,
    GridRow,
    NavMenuItem,
    PDS_P_BOLD,
    UserInfoCol
} from "../../components/styled/StyledComponents";
import {ITicket} from "../../model/tickets/ITickets";
import {AdminConvertUser} from "../../components/admin/AdminConvertUser";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {BASE_PATH} from "../../constants";
import {getTicketEventStatusDate, getTicketSubject} from "../../constants/CommonFunctions";
import {AdminUserLoginsInfo} from "../../components/admin/AdminUserLoginsInfo";
import {getPDSData} from "../../utilities/axiosClient/axiosClientUtility";

export const AdminTickets: React.FC = () => {
    const {requestType} = useParams();
    const [tickets, setTickets] = useState<ITicket[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMsg, setErrorMsg] = useState<string | undefined>();
    const OPEN_TICKETS: string = "Open Tickets";
    const CLOSED_TICKETS: string = "Closed Tickets";
    const NO_OPEN_TICKETS: string = "No open tickets at this time";
    const NO_IN_PROGRESS_TICKETS: string = "No tickets in progress at this time";
    const NO_CLOSED_TICKETS: string = "No closed tickets at this time";
    const [sortOrder, setSortOrder] = useState("DESC");
    const url = process.env.REACT_APP_API_URL + "/api/admin/tickets/" + requestType;
    const [requestsURL, setRequestURL] = useState(url + "/" + sortOrder);

    /**
     * get the data from the api
     */
    useMemo(() => {
        /**
         * make the api call
         */
        const getTickets = async () => {
            setLoading(true);
            const {data, error} = await getPDSData<ITicket[]>(requestsURL);
            if (data !== null) {
                setTickets(data);
            } else if (error) {
                setErrorMsg(error.response?.data?.detail);
            }
            setLoading(false);
        }
        getTickets();
    }, [requestsURL]);

    /**
     * sort date
     * @param e
     */
    const sortDate = (e: any) => {
        e.preventDefault();
        let sort:string;
        if (sortOrder === DESC) {
            setSortOrder(ASC);
            sort = ASC;
        } else {
            setSortOrder(DESC);
            sort = DESC
        }
        setRequestURL(url + "/" + sort);
    }

    // return user first and last name
    const getUserName = (ticket: ITicket) => {
        const fullName = ticket.contactUs?.first + " " + ticket.contactUs?.last;
        if (ticket.user?.id != null) {
            return (
                <UserInfoCol className={""}>
                    <AdminUserLoginsInfo userId={ticket.user?.id}/>
                    <NavLink to={BASE_PATH + "admin/userInfo/" + ticket.user?.id}
                             className={"linkstyle"}>{fullName}</NavLink>
                </UserInfoCol>
            )
        } else {
            return (
                <AdminConvertUser ticketId={ticket.id} fullName={fullName}/>
            )
        }
    }

    // return email associated with ticket
    const getTicketEventEmail = (ticket: ITicket) => {
        if (ticket.techSupportEvents != null) {
            return ticket.techSupportEvents[0].user?.userDetails?.email;
        }
    }

    // return email associated with ticket
    const getTicketEventStatus = (ticket: ITicket) => {
        if (ticket.techSupportEvents != null) {
            let event: string = ticket.techSupportEvents[0].event;
            let item: FormList | undefined = ADMIN_TICKETS_STATUS.find(s => s.value === event);
            if (item != undefined) {
                return item?.label;
            }
        }
    }

    // return table header based on request type
    const getTableHeader = (requestType: string) => {
        if (requestType === OPEN) {
            return OPEN_TICKETS;
        } else if (requestType === CLOSED) {
            return CLOSED_TICKETS
        }
        return "";
    }

    // return message about no tickets based on request type
    const getNoTicketsMessage = (requestType: string) => {
        if (requestType === OPEN) {
            return NO_OPEN_TICKETS;
        } else if (requestType === CLOSED) {
            return NO_CLOSED_TICKETS;
        } else if (requestType === IN_PROGRESS) {
            return NO_IN_PROGRESS_TICKETS;
        }
        return "";
    }

    /**
     * build the opened or closed section header
     */
    const buildOpenClosedHeader = () => {
        return (
            <>
                <GridRow className="">
                    <Col className="grid-header grid-entry space-2 ">Name</Col>
                    <Col className="grid-header grid-entry space-2 ">Email</Col>
                    <Col className="grid-header grid-entry ">Subject</Col>
                    <Col className="grid-header grid-entry ">Status</Col>
                    <Col className="grid-header grid-entry "><a className={"convert"} onClick={(e) => sortDate(e)}>Request
                        Date</a></Col>
                </GridRow>
            </>
        )
    }

    /**
     * build the in progress section header
     */
    const buildInProgressHeader = () => {
        return (
            <>
                <GridRow className="">
                    <Col className="grid-header grid-entry space-2 ">Name</Col>
                    <Col className="grid-header grid-entry space-2 ">Email</Col>
                    <Col className="grid-header grid-entry ">Subject</Col>
                    <Col className="grid-header grid-entry ">Status</Col>
                    <Col className="grid-header grid-entry ">Status Date</Col>
                    <Col className="grid-header grid-entry "><a className={"convert"} onClick={(e) => sortDate(e)}>Request
                        Date</a></Col>
                </GridRow>
            </>
        )
    }

    /**
     * build the in progress section
     */
    const buildInProgressBody = () => {
        return (
            <>
                {tickets?.map((ticket: ITicket) => (
                    <GridRow className="" key={ticket.id}>
                        <Col className="grid-entry space-2">
                            {getUserName(ticket)}
                        </Col>
                        <Col className="grid-entry space-2">
                            {ticket.contactUs.email}
                        </Col>
                        <Col className="grid-entry">
                            {getTicketSubject(ticket)}
                        </Col>
                        <Col className="grid-entry">
                            <NavMenuItem
                                to={BASE_PATH + "admin/tickets/processTicket/" + ticket.id + "/" + requestType}>
                                {getTicketEventStatus(ticket)}
                            </NavMenuItem>
                        </Col>
                        <Col className="grid-entry">
                            {getTicketEventEmail(ticket)}
                        </Col>
                        <Col className="grid-entry">
                            {getTicketEventStatusDate(ticket)}
                        </Col>
                        <Col className="grid-entry">
                            {new Date(ticket.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                    </GridRow>
                ))}
            </>
        )
    }

    /**
     * build the opened or closed sections
     */
    const buildOpenCloseBody = () => {
        return (
            <>
                {tickets?.map((ticket: ITicket) => (
                    <GridRow className="" key={ticket.id}>
                        <Col className="grid-entry space-2">
                            {getUserName(ticket)}
                        </Col>
                        <Col className="grid-entry space-2">
                            {ticket.contactUs.email}
                        </Col>
                        <Col className="grid-entry">
                            {getTicketSubject(ticket)}
                        </Col>
                        <Col className="grid-entry">
                            <NavMenuItem
                                to={BASE_PATH + "admin/tickets/processTicket/" + ticket.id + "/" + requestType}>
                                {getTicketEventStatus(ticket)}
                            </NavMenuItem>
                        </Col>
                        <Col className="grid-entry">
                            {new Date(ticket.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                    </GridRow>
                ))}
            </>
        )
    }

    /**
     * build the tickets page
     */
    const buildSection = () => {
        if (requestType === IN_PROGRESS) {
            if (tickets?.length > 0) {
                return (
                    <>
                        <Row><AdminTableHeader className="mt-3">{getTableHeader(requestType)}</AdminTableHeader></Row>
                        {buildInProgressHeader()}
                        {buildInProgressBody()}
                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>{getNoTicketsMessage(requestType)}</PDS_P_BOLD>
                )
            }
        } else if (requestType === OPEN || requestType === CLOSED) {
            if (tickets !== null && tickets?.length > 0) {
                return (
                    <>
                        <Row><AdminTableHeader className="mt-3">{getTableHeader(requestType)}</AdminTableHeader></Row>
                        {buildOpenClosedHeader()}
                        {buildOpenCloseBody()}
                    </>
                )
            } else {
                return (
                    <PDS_P_BOLD>{getNoTicketsMessage(requestType)}</PDS_P_BOLD>
                )
            }
        }

    }

    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={TICKET_HEADERS} requestType={requestType}
                               countsUrl={"/api/admin/dashboard/counts/tickets"}/>
            {loading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {errorMsg && <p>Error</p>}
            {tickets && buildSection()}
        </Container>
    )
}