/******************************************************************************\
 * :$
 *
 * Copyright(c) 2024 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminSystem
 *
 * Purpose: admin system page
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 12Jan2024 craig  File created.
 * 15Feb2024 craig  Added calls for ctGovRefresh, nciDataset, nciRequests
 * 05May2024 craig  Added lsaf check, but can't use for now
 * 08Jul2024 sasjxa additional styling
 * 25Jan2025 craig  Added gold standard feed
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ContentPage, FormLayoutColumn, LinkButton, PageWrapper} from "../../components/styled/StyledComponents";
import {useRequest} from "../../helper/useRequest";
import styled from "styled-components";
import {COLORS} from "../../components/styled/StyleConstants";

function AdminEditButtonRow(props: { children: () => void }) {
    return null;
}

const SystemLinkButton = styled(LinkButton)`
    width: 250px;

    :hover, :active:focus {
        background-color: ${COLORS.cerulean};
    }
`;

export const AdminSystem: React.FC = () => {

    const [systemUrl, setSystemUrl] = useState<string>();
    const baseUrl = process.env.REACT_APP_API_URL + "/api/admin/system/";

    const handleError = (error: object) => {
        console.log("error");
    }

    const handleSuccess = (response: string) => {
        if (response != null) {
            console.log(JSON.stringify(response));
            alert(JSON.stringify(response));
        }
    }

    const [convertUser, makeSystemCall] = useRequest({
        url: systemUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        if (systemUrl?.length !== undefined && systemUrl?.length > 0) {
            makeSystemCall();
        }
    }, [systemUrl]);


    return (
        <Container className={"w-100"} fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper "}>
                    <FormLayoutColumn className="mt-5 mb-5 " lg={12}>
                        <FormLayoutColumn lg={8}>
                            <Row className={"mb-4 "}>
                                <h4>Clinical Trial.gov Section</h4>
                                <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "ctGovRefresh")}>Run CT.gov
                                    Refresh</SystemLinkButton>
                            </Row>
                            <Row>
                                <h4>NCI Section</h4>
                                <Row className={"mb-4 "}>
                                    <Col className={"ps-0"}>
                                        <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "nciDataset")}>Run NCI
                                            Dataset
                                            Feed</SystemLinkButton>
                                    </Col>
                                    <Col>
                                        <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "nciRequests")}>Run NCI
                                            Requests
                                            Feed</SystemLinkButton>
                                    </Col>
                                    <Col>
                                        <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "nciViewRequests")}>View
                                            NCI
                                            Requests (JSON)</SystemLinkButton>
                                    </Col>
                                </Row>
                            </Row>
                            <Row className={"mb-4"}>
                                <h4>Virus Scan Section</h4>
                                <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "virusscan")}>Run Virus
                                    Scanner</SystemLinkButton>
                            </Row>
                            <Row className={"mb-4"}>
                                <h4>Gold Standard Section</h4>
                                <Col>
                                    <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "goldStandard/2022")}>Run Gold Standard Feed (2022)</SystemLinkButton>
                                </Col>
                                <Col>
                                    <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "goldStandard/2023")}>Run Gold Standard Feed (2023)</SystemLinkButton>
                                </Col>
                                <Col>
                                    <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "goldStandard/2024")}>Run Gold Standard Feed (2024)</SystemLinkButton>
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col>
                                    <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "goldStandard/2025")}>Run Gold Standard Feed (2025)</SystemLinkButton>
                                </Col>
                                <Col>
                                    <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "goldStandard")}>Run Gold Standard Feed</SystemLinkButton>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <h4>Python Section</h4>
                                <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "runPython")}>Run
                                    Python</SystemLinkButton>
                            </Row>
                            <Row className={"mb-3"}>
                                <h4>Python Section</h4>
                                <SystemLinkButton onClick={() => setSystemUrl(baseUrl + "testSailpoint")}>Test Sailpoint</SystemLinkButton>
                            </Row>
                            {/*                    <Row>
                        <Button onClick={() => setSystemUrl(baseUrl + "lsafTest")}>Test LSAF</Button>
                    </Row>*/}
                        </FormLayoutColumn>
                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    )
}