/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetRequestAction
 *
 * Purpose: Admin Dataset Approval
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 12Jul2023 sasjxa file
 * 31Oct2023 sasjxa set initial values for validator purposes
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 craig  added basepath
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  switched to react-error-boundary
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 14Aug2024 sasjxa get previous dispositions
 * 20Dec2024 sasjxa use button container with isWaitingForResponse
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row, Spinner} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";
import {
    ContentPage,
    FormLayoutColumn,
    GridRow,
    PageWrapper,
    PDS_H5,
    TextAreaCountRow
} from "../../components/styled/StyledComponents";

import {
    ACCOUNT_DECLINE_REASONS,
    DATASET_APPROVAL,
    DECLINE_USER,
    FormList,
    OTHER_OPTION,
    RMI_CONTRIBUTION_REASONS,
    RMI_USER,
    SUBMIT,
} from "../../data/formList";
import {useNavigate, useParams} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {useRequest} from "../../helper/useRequest";
import {BASE_PATH} from "../../constants";
import {useErrorBoundary} from "react-error-boundary";
import {getEncodedValue} from "../../constants/CommonFunctions";
import {IAdminDispositionUser} from "../../model/admin/requests/IAdminRequestUser";


export const AdminDatasetRequestAction: React.FC = () => {
    const navigate = useNavigate();
    const {donationId} = useParams();
    const { showBoundary } = useErrorBoundary();
    const [actions, setActions] = useState("");
    const [declinedReason, setDeclinedReason] = useState("");
    const [rmiReason, setRmiReason] = useState("");
    const [declinedOther, setDeclinedOther] = useState("");
    const [initialComments, setInitialComments] = useState("");
    const [showRMIReason, setShowRMIReason] = useState(false);
    const [showDeclinedReason, setShowDeclinedReason] = useState(false);
    const [commentsCount, setCommentsCount] = useState(0);
    const [datasetRequest, setDatasetRequest] = useState<IAdminDispositionUser[]>();
    const [initialCommentsLabel, setInitialCommentsLabel] = useState("Comments ");
    const [showDeclinedOther, setShowDeclinedOther] = useState(false);
    const maxCommentsCount = 1000;
    const externalComments = "Comments (External to User)";
    const internalComments = "Comments (Internal Only)";
    const formikRef = useRef<FormikProps<any>>(null);

    //this will disable the submit button while waiting for the response after submit
    const [isWaitingForResponse, setIsWaitingForResponse] = useState<boolean>(false);

    const handleError = (error: object) => {
        setIsWaitingForResponse(false);
        showBoundary(error);
    }

    const handleSuccess = () => {
        setIsWaitingForResponse(false);
        navigate(BASE_PATH + 'admin/request/datasets/PENDING');
    }

    //get data
    const requestURL = process.env.REACT_APP_API_URL + "/api/admin/donations/donationDispositions/" + donationId;
    const [requestState, getDatasetRequest] = useRequest({
        url: requestURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,

    })
    const {isLoading, data, error} = requestState;



    // save data
    const submitUrl = process.env.REACT_APP_API_URL + "/api/admin/donations/requests/" + donationId;
    const [requestActionState, setDatasetStatus] = useRequest({
        url: submitUrl,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleSuccess
    })

    useMemo(() => {
        getDatasetRequest();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDatasetRequest(data);
        }
    }, [data])

    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    // show other field when 'Other" selected from list
    const handleDeclinedChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowDeclinedOther(true);
        } else if (showDeclinedOther) {
            setShowDeclinedOther(false);
        }
    }

    // change label and dropdown depending on selection
    const handleActionsChanged = (value: string) => {
        setInitialComments(value);
        if (value === RMI_USER || value=== DECLINE_USER){
           setInitialCommentsLabel(externalComments);
        }
        else {
            setInitialCommentsLabel(internalComments);
        }

        if (value === RMI_USER) {
            setShowRMIReason(true);
            setShowDeclinedReason(false);


        } else if (value === DECLINE_USER) {
            setShowDeclinedReason(true);
            setShowRMIReason(false);

        }
        else {
            setShowDeclinedReason(false);
            setShowRMIReason(false);
        }
    }

    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setShowDeclinedReason(false);
        setShowRMIReason(false);
    }



    // validation schema
    const schema = Yup.object().shape({
        actions: Yup.string().required("Please select an action."),

        rmiReason: Yup.string().when(['actions'], {
            is: (actions: string) => {
                if (actions === RMI_USER) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a reason for request more information")
        }),

        declinedReason: Yup.string().when(['actions'], {
            is: (actions: string) => {
                if (actions === DECLINE_USER) {
                    return true // validate and go to then function
                } else return false // for all other cases is not required
            },
            then: (schema) =>
                schema
                    .required("Please select a reason for request more information")
        }),
        declinedOther: Yup.string().when(['declinedReason'], {
            is: (declinedReason: string) => {
                if (declinedReason === OTHER_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a reason for declining.")
        }),


        comments: Yup.string().required("Please enter comments"),
    });

    const buildSection = () => {
            return (
<>
                    {buildForm()}
                    {buildDispositions()}
</>
            )

    }

    const buildDispositions = () => {
        if (datasetRequest !== undefined &&  datasetRequest?.length > 0) {
            return (
                <>
                    <PDS_H5 className= "mb-2 ms-4">Previous Dispositions</PDS_H5>
                    {buildPreviousDispositionHeaders ()}
                    {buildPreviousDispositionRows ()}
                </>
            )
        }
    }

    const buildPreviousDispositionHeaders = () => {
        return (
            <>
                <GridRow className="ms-4">
                    <Col  lg={1}className="grid-header grid-entry  ">Status</Col>
                    <Col lg={2}className="grid-header grid-entry  "> User</Col>
                    <Col lg={1}className="grid-header grid-entry ">Date</Col>
                    <Col lg={4}className="grid-header grid-entry "> Reason</Col>
                    <Col lg={4}className="grid-header grid-entry">Comments</Col>
                </GridRow>
            </>
        )
    }

    const buildPreviousDispositionRows = () => {
        return (
            <>
                {datasetRequest?.map((disposition: IAdminDispositionUser) => (
                    <GridRow className="ms-4">
                        <Col  lg={1} className=" grid-entry  ">{disposition.status}</Col>
                        <Col  lg={2} className=" grid-entry  ">{disposition.user?.userDetails?.email}</Col>
                        <Col lg={1} className=" grid-entry">
                            {new Date(disposition.createdAt).toLocaleDateString('en-us', {
                                month: "numeric",
                                day: "numeric",
                                year: "numeric"
                            })}
                        </Col>
                        <Col lg={3} className=" grid-entry ">{disposition.reason} </Col>
                        <Col lg={4} className=" grid-entry">{disposition.comments}</Col>
                    </GridRow>
                ))}
            </>
        )
    }


    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                enableReinitialize
                validationSchema={schema}
                onSubmit={(values) => {
                    setDatasetStatus(JSON.stringify({
                        actions: values.actions,
                        rmiReason: values.rmiReason,
                        declinedReason: values.declinedReason,
                        declinedOther: values.declinedOther,
                        comments: getEncodedValue(values.comments)
                    }))
                }}
                initialValues={{
                    actions: actions,
                    rmiReason: rmiReason,
                    declinedReason: declinedReason,
                    declinedOther:declinedOther,
                    comments: initialComments,
                }}
                validateOnChange={false}
                validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="justify-content-center mt-3 mb-3">
                                    <Form.Group as={Col} lg={8}
                                                controlId="formAction">
                                        <Form.Label  className="required" column="sm">Actions </Form.Label>
                                        <Form.Select size="sm"
                                                     name={"actions"}
                                                    value={actions}
                                                     isValid={touched.actions && !errors.actions}
                                                     onChange={e => {
                                                         setActions(e.target.value);
                                                         handleActionsChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(DATASET_APPROVAL)}
                                        </Form.Select>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select an action.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"actions"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showRMIReason && (
                                    <Row className=" justify-content-center mt-3 mb-3 mx-3">
                                        <Form.Group as={Col} lg={8}
                                                    controlId="formAction">
                                            <Form.Label  className="required" column="sm">RMI Reason </Form.Label>
                                            <Form.Select size="sm"
                                                         name={"rmiReason"}
                                                         value ={rmiReason}
                                                         isValid={touched.rmiReason && !errors.rmiReason}
                                                         onChange={e => {
                                                             setRmiReason(e.target.value);
                                                         }}>
                                                {buildSelectList(RMI_CONTRIBUTION_REASONS)}
                                            </Form.Select>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please select an action
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"actions"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                {showDeclinedReason && (
                                    <Row className=" justify-content-center mt-3 mb-3 mx-3">
                                        <Form.Group as={Col} lg={8}
                                                    controlId="formAction">
                                            <Form.Label  className="required" column="sm">Decline Reason </Form.Label>
                                            <Form.Select size="sm"
                                                         name={"declinedReason"}
                                                         value ={declinedReason}
                                                         isValid={touched.declinedReason && !errors.declinedReason}
                                                         onChange={e => {
                                                             setDeclinedReason(e.target.value);
                                                             handleDeclinedChanged(e.target.value);
                                                             handleChange(e);
                                                         }}>
                                                {buildSelectList(ACCOUNT_DECLINE_REASONS)}
                                            </Form.Select>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please select an action
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"declinedReason"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                {showDeclinedOther && (
                                    <Row className="justify-content-center mt-3 mb-3 mx-4">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={8}
                                                    controlId="formDeclinedOther">
                                            <Form.Label className="required" column="sm">Other Declined </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"declinedOther"}
                                                          type="text"
                                                          value={declinedOther}
                                                          isValid={touched.declinedOther && !errors.declinedOther}
                                                          onChange={e => {
                                                              setDeclinedOther(e.target.value);
                                                          }}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a reason for declining.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"declinedOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3 justify-content-center">
                                    <Form.Group as={Col} lg={8}
                                                controlId="formComments">
                                        <TextAreaCountRow>
                                            <Form.Label className="required" column="sm">{initialCommentsLabel} </Form.Label>
                                            <span>{commentsCount} of {maxCommentsCount} characters</span>
                                        </TextAreaCountRow>

                                        <Form.Control as="textarea" rows={5} size="sm" required
                                                      name={"comments"}
                                                      type="text"
                                                      value = {initialComments}
                                                      isValid={touched.comments && !errors.comments}
                                                      onChange={e => {
                                                            setInitialComments(e.target.value);
                                                          setCommentsCount(e.target.value.length);
                                                          handleChange(e);
                                                      }}/>
                                        <Form.Control.Feedback type="invalid">
                                            Please select an action
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"comments"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>
                            </FormLayoutColumn>
                        </FormLayoutColumn>
                        <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                       isSubmitting={isWaitingForResponse}
                                                       submitButtonText={SUBMIT}/>

                    </Form>

                )}
            </Formik>
        )
    }

    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                <FormLayoutColumn>
                    {isLoading &&
                        <Spinner  className ="spinner-center" animation={"border"} variant={"primary"}  role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>}
                    {error && <p>Error</p>}
                    {data && buildSection()}
                </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
