/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetsRequest
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Sep2023 sasjxa file created
 * 20Oct2023 sasjxa add dynamic method for generating title
 * 02Nov2023 craig  added link to donation info page
 * 14Nov2023 sasjxa add methods for approved datasets to show admin approver and date
 * 10Jan2024 sasjxa use new common component for header
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa encode/decode special chars (MPM-5389)
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 30Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  moved decode to api
 * 16Aug2024 sasjxa correct link for dataset form
 * 25Aug2024 craig  changed href to NavLink
 * 14Nov2024 sasjxa use NOT_APPLICABLE constant
 \**************************************************************************************/
import React, {useMemo, useState} from "react";

import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";

import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {useParams} from "react-router-dom";
import {IShareDataDashboard} from "../../model/share/IShare";
import {IStatus} from "../../model/donations/IDonations";
import {IAdminAccessDataDashboard} from "../../model/admin/requests/IAdminAccessDataDashboard";
import {APPROVED, DATASETS_HEADERS, DECLINED, NOT_APPLICABLE, QUARANTINED} from "../../data/formList";
import {AdminCountsHeader} from "../../components/admin/AdminCountsHeader";
import {NavMenuItem, PDS_P_BOLD} from "../../components/styled/StyledComponents";
import {BASE_PATH} from "../../constants";
import axios, {AxiosError} from "axios";


const AdminTableHeader = styled.h6`
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
`;

const GridRow = styled.div`
    display: flex;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin-top: .5%;
    padding-bottom: .75%;
    font-size: ${FONT_SIZES.px14};
    border-bottom: 2px solid ${COLORS.light_grey};

    a {
        color: ${COLORS.dark_grey_blue};
    }

    a.approval {
        color: ${COLORS.cerulean};
    }

`;

const GridHeaderRow = styled(GridRow)`
    font-weight: bold;
    background-color: ${COLORS.filter_gray};
    padding: .5% 0;
    margin-top: 0;
`;

const GridCol = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-left: 2%;
    padding-right: 0;
    padding-left: 0;
    width: 12%;

    span {
        color: ${COLORS.dark_grey_blue};
    }
`;

const GridColTitle = styled.div`
    display: flex;
    flex-direction: column;
    color: ${COLORS.dark_grey_blue};
    width: 30%;

    span {
        display: block;
    }
`;


export const AdminDatasetsRequests: React.FC = () => {
    const [dashboardMsg, setDashboardMsg] = useState<string>("");
    const {requestType} = useParams();
    const [dashboard, setDashboard] = useState<IAdminAccessDataDashboard[]>([]);


    const IMG_PATH: string = "/images/icons/";
    const DSA: string = "DSA";
    const DATA_SET_FORM: string = "DATA_SET_FORM";
    const FILES: string = "FILES";
    const SUBMISSION: string = "SUBMISSION";
    const APPROVAL: string = "APPROVAL";
    const REAL_WORLD: string = "REAL_WORLD";
    const INCOMPLETE: string = "INCOMPLETE";
    const READY_FOR_ACTION: string = "READY_FOR_ACTION";
    const COMPLETE: string = "COMPLETE";
    const PENDING_VERIFICATION: string = "PENDING_VERIFICATION";

    const buildError = (error: Error | AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            setDashboardMsg(error.response.data.detail);
        } else {
            setDashboardMsg(error.toString);
        }
    }

    const donationURL = process.env.REACT_APP_API_URL + "/api/admin/donations/requests/" + requestType;

    const [requestState, getDonations] = useRequest({
        url: donationURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError

    })

    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getDonations();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDashboard(data);
        }
    }, [data])


    /**
     * set status variable
     *
     * @param statusType - status type
     * @param statuses - statuses for that time
     */
    const getStatus = (statusType: string, statuses: IStatus[]) => {
        let found: IStatus | undefined = statuses.find(s => s.statusType === statusType);
        return found;
    }

    /**
     * build the icon cell
     *
     * @param dashboardData - dashboard data
     * @param statusValue - status of cell
     * @param statusText - text for the cell
     * @param hasURLMethod - link for the cell
     * @param urlMethod - method for click of link
     */
    const buildIconCell = (dashboardData: IShareDataDashboard, statusValue: string, statusText: string, hasURLMethod: boolean,
                           urlMethod?: any) => {

        if (dashboardData?.nci && statusValue === DSA) {
            return (
                <Col className="icon  ">
                    <span className="w-100 icon  share-title text-center">
                    N/A
                </span>
                </Col>
            )
        } else {
            return (
                <Col className="icon  ">

                <span className="w-100 icon bold-label share-title text-center">
                    <img className="mx-auto"
                         src={IMG_PATH + getStatus(statusValue, dashboardData.statuses)?.icon}
                         alt="Waiting icon"
                         width="32" height="32"/>
                </span>
                    {hasURLMethod && statusValue == SUBMISSION && (
                        <span
                            className="w-100 icon share-title text-center mt-1 pb-2"> {urlMethod(dashboardData)}</span>
                    )}
                    {hasURLMethod && statusValue != SUBMISSION && (
                        <span
                            className="w-100 icon share-title text-center mt-1 pb-2 ps-4"> {urlMethod(dashboardData)}</span>
                    )}
                </Col>

            )
        }
    }

    const formatUpdatedDate = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.request?.updatedAt != null) {
            return (
                new Date(dashboardData?.request?.updatedAt).toLocaleDateString('en-us', {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                })
            )
        }
    }

    const getApprover = (dashboardData: IShareDataDashboard) => {
        if (dashboardData?.request?.approver?.userDetails != null) {
            return (
                dashboardData?.request?.approver?.userDetails.first + " " + dashboardData?.request?.approver?.userDetails.last
            )
        }
    }


    /**
     * build the icon cell
     *
     * @param dashboardData - dashboard data
     */
    const buildApprovalCell = (dashboardData: IShareDataDashboard
    ) => {
        return (
            <Col className="icon  ">
                <span
                    className="w-100  icon share-title text-center  pb-1 ps-3"> {buildApprovalUrl(dashboardData)}</span>
                <span className="w-100  icon share-title text-center mt-1  ps-3">
                        {getApprover(dashboardData)}
                </span>
                <span className="w-100  icon share-title text-center  pb-1 ps-3">
                        {formatUpdatedDate(dashboardData)}
                </span>
            </Col>
        )
    }

    /**
     * build the dsa url
     *
     * @param dashboardData - dashboard data
     */
    const buildDSAURL = (dashboardData: IShareDataDashboard) => {

        if (dashboardData.dsaApproved && !dashboardData.nci) {
            return (
                <>
                    <NavMenuItem to={BASE_PATH + "dataShareAgreement/" + dashboardData?.donationId}>
                        Approved
                    </NavMenuItem>
                </>)
        }
    }

    /**
     * build the dataset form url
     *
     * @param dashboardData - dashboard data
     */
    const buildDatasetFormURL = (dashboardData: IShareDataDashboard) => {
        if (!dashboardData.dsaApproved) {
            return "";
        } else {
            let hrefValue: string = BASE_PATH + "admin/request/datasets/donationInfo/" + dashboardData?.donationId;
            if (dashboardData.dataType === REAL_WORLD) {
                hrefValue = BASE_PATH + "admin/request/datasets/donationInfo/ " + dashboardData?.donationId;
            }

            let formInstruction: string = "";
            if (getStatus(DATA_SET_FORM, dashboardData.statuses)?.key === COMPLETE) {
                formInstruction = "Complete"
            } else if (getStatus(DATA_SET_FORM, dashboardData.statuses)?.key === READY_FOR_ACTION) {
                formInstruction = "Enter Data";
            } else if
            (getStatus(FILES, dashboardData.statuses)?.key === INCOMPLETE) {
                formInstruction = "Incomplete"
            }
            if (formInstruction !== "") {
                return (
                    <NavMenuItem to={hrefValue}>
                        {formInstruction}
                    </NavMenuItem>
                )
            }
        }
    }

    /**
     * build the files url
     *
     * @param dashboardData - dashboard data
     */
    const buildFilesUrl = (dashboardData: IShareDataDashboard) => {
        let urlLabel: string = "";
        let hrefValue = BASE_PATH + "admin/request/datasets/fileInfo/" + dashboardData.donationId;
        if (getStatus(FILES, dashboardData.statuses)?.key === COMPLETE) {
            urlLabel = "Complete"
        } else if (getStatus(FILES, dashboardData.statuses)?.key === READY_FOR_ACTION) {
            urlLabel = "Ready for Action"
        } else if
        (getStatus(FILES, dashboardData.statuses)?.key === INCOMPLETE) {
            urlLabel = "Incomplete"
        }
        if (urlLabel !== "") {
            return (
                <NavMenuItem to={hrefValue}>
                    {urlLabel}
                </NavMenuItem>
            )
        }
    }

    /**
     * build Submission url for submitting the contribution
     *
     * param dashboardData
     */
    const buildSubmissionUrl = (dashboardData: IShareDataDashboard) => {
        let urlLabel: string = "";
        if (getStatus(SUBMISSION, dashboardData.statuses)?.key === READY_FOR_ACTION) {
            urlLabel = "Ready for Action"
        } else if (getStatus(SUBMISSION, dashboardData.statuses)?.key === COMPLETE) {
            urlLabel = "Complete"
        }
        if (urlLabel !== "") {
            return urlLabel;
        }
    }

    /**
     * build Submission url for submitting the contribution
     *
     * param dashboardData
     */
    const buildApprovalUrl = (dashboardData: IShareDataDashboard) => {
        let hrefValue = BASE_PATH + "admin/request/datasets/status/" + dashboardData.donationId;
        let urlLabel: string = "";
        if (getStatus(APPROVAL, dashboardData.statuses)?.key === PENDING_VERIFICATION) {
            urlLabel = "Review in Process"
        } else if (dashboardData.nci || (getStatus(APPROVAL, dashboardData.statuses)?.key === APPROVED)) {
            urlLabel = "Approved"
        }

        if (urlLabel !== "") {
            return (

                <NavMenuItem to={hrefValue}>
                    {urlLabel}
                </NavMenuItem>
            )
        } else {
            return "N/A";
        }
    }


    const buildHeaders = (donations: IAdminAccessDataDashboard) => {

        if (donations?.shareDataDashboard?.length > 0) {
            return (
                <>
                    <Row><AdminTableHeader className="mt-3">{donations.title}</AdminTableHeader></Row>
                    <Col className="mb-3 ">
                        <GridHeaderRow className="">
                            <GridColTitle className="">Title</GridColTitle>
                            <GridCol className="">DSA Status</GridCol>
                            <GridCol className="">Dataset Form</GridCol>
                            <GridCol className="pe-4">Files</GridCol>
                            <GridCol className="pe-4">Submitted</GridCol>
                            <GridCol className="pe-2">Approval</GridCol>
                        </GridHeaderRow>
                    </Col>
                </>
            )
        } else if (requestType === QUARANTINED || requestType === DECLINED) {
            return (
                <>
                    <PDS_P_BOLD>No {requestType === QUARANTINED ? "Quarantined" : "Declined"} datasets
                        available</PDS_P_BOLD>
                </>
            )
        } else {
            return (
                <>
                    <Row><AdminTableHeader className="mt-3">{donations.title}</AdminTableHeader></Row>
                    <span>{donations?.subTitle}</span>
                </>
            )
        }
    }

    const getTitle = (donation: IShareDataDashboard) => {
        let hrefValue = BASE_PATH + "admin/request/datasets/donationInfo/" + donation.donationId;
        let urlLabel = donation.title == null ? NOT_APPLICABLE : donation.title;
        return (
            <NavMenuItem to={hrefValue}>
                {urlLabel}
            </NavMenuItem>
        )
    }

    const buildDatasetInfo = (donations: IAdminAccessDataDashboard) => {
        if (donations?.shareDataDashboard?.length > 0) {
            return (
                <>
                    {donations.shareDataDashboard.map((donation: IShareDataDashboard) => {
                        return (
                            <Col className="w-100">
                                <GridRow key={donation.donationId} className="mb-2 mt-1">
                                    <GridColTitle className=" span-title ">
                                        <span>{getTitle(donation)}</span>
                                        <span>Unique Dataset ID: {donation.uniqueId}</span>
                                        <span>Clinical Trial ID(s):{donation.clinicalGovId}</span>
                                    </GridColTitle>
                                    {buildIconCell(donation, DSA, "DSA Status", true, buildDSAURL)}
                                    {buildIconCell(donation, DATA_SET_FORM, "Dataset Form", true, buildDatasetFormURL)}
                                    {buildIconCell(donation, FILES, "Files", true, buildFilesUrl)}
                                    {buildIconCell(donation, SUBMISSION, "Submission", true, buildSubmissionUrl)}
                                    {buildApprovalCell(donation)}
                                </GridRow>
                            </Col>
                        )
                    })}
                </>
            )
        }
    }


    const buildSection = () => {
        return (
            <>
                {dashboard.map((donations: IAdminAccessDataDashboard) => {
                    return (
                        <div>
                            {buildHeaders(donations)}
                            {buildDatasetInfo(donations)}
                        </div>
                    )
                })}
            </>
        )
    }


    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3 "}></Row>
            <AdminCountsHeader countsList={DATASETS_HEADERS} requestType={requestType}
                               countsUrl={"/api/admin/dashboard/counts/datasets"}/>
            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {dashboard && buildSection()}

        </Container>
    );

}
