/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: Registration
 *
 * Purpose: PDS data provider registration page
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 15Jun2023 sasjxa file created
 * 23Feb2024 sasjxa add clear button functionality (MPM-5425)
 * 08Mar2024 sasjxa add BASE_PATH constant to urls (MPM-5430)
 * 12Mar2024 sasjxa add get and post urls (MPM-5432)
 * 30Apr2024 sasjxa switched to react-error-boundary
 * 22May2024 craig  fixed affiliation validation bug
 * 02Jul2024 craig  moved decode to api
 * 21Aug2024 craig  removed unused user session
 * 04Nov2024 sasjxa added yup max to text fields
 \******************************************************************************/
import React, {useMemo, useRef, useState} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import * as Yup from 'yup';
import {ErrorMessage, Formik, FormikProps} from "formik";

import {ContentPage, FormLayoutColumn, PageWrapper, PDS_P_BOLD} from "../../components/styled/StyledComponents";


import "../forms/forms.css";
import {
    AFFILIATION,
    COUNTRY,
    FormList,
    HEADER_PROVIDER,
    OTHER_OPTION,
    RESPONSE_PROVIDER,
    ROLE,
    SUBMIT
} from "../../data/formList";
import {useNavigate} from "react-router-dom";
import {ClearAndSubmitButtonContainer} from "../../components/buttonContainer/ClearAndSubmitButtonContainer";
import {Aside} from "../../components/Aside";
import {BASE_PATH} from "../../constants";
import {useRequest} from "../../helper/useRequest";
import {IFullUserDetails} from "../../model/user/IFullUser";
import {useErrorBoundary} from "react-error-boundary";
import {getEncodedValue, labelIfReadOnly} from "../../constants/CommonFunctions";
import {COLORS} from "../../components/styled/StyleConstants";
import styled from "styled-components";
import axios, {AxiosError} from "axios";

const PDS_P_REGISTRATION_MSG = styled(PDS_P_BOLD)`
    font-weight: 600;
    color: ${COLORS.remove_red};
`;

export const DataProviderRegistration: React.FC = () => {
    const navigate = useNavigate();
    const {showBoundary} = useErrorBoundary();
    const [disabled, setDisabled] = useState(false);
    const [userDetails, setUserDetails] = useState<IFullUserDetails>();
    const [showAffiliationOther, setShowAffiliationOther] = useState(false);
    const [showRoleOther, setShowRoleOther] = useState(false);

    const [registrationMsg, setRegistrationMsg] = useState<string | undefined>("");
    const formikRef = useRef<FormikProps<any>>(null);
    const [showRegistrationMessage, setShowRegistrationMessage] = useState(false);


    const minimumOneCharRegExp = /[A-Za-z]+/;
    const NO_CHARS_ERROR_MSG = "Field must contain at least one character";

    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }

    // handle unsuccessful save of data
    const buildError = (error: AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            setRegistrationMsg(error.response.data.detail);
        } else {
            setRegistrationMsg(error.toString);
        }
        setShowRegistrationMessage(true);
    }

    // handle error concerning RMI
    const handleRmiError = (error: object) => {
        showBoundary(error);
    }

    // handle successful save of data
    const handleSuccess = () => {
        navigate(BASE_PATH + 'SubmissionResponse', {
            state: {
                header: HEADER_PROVIDER,
                response: RESPONSE_PROVIDER
            }
        });
    }


    // initialize fields after successful retrieve of data
    const handleGetRegistration = (userDetails: IFullUserDetails) => {
        if (userDetails !== null) {
            setUserDetails(userDetails);

            if (userDetails.sectorOther != null) {
                setShowAffiliationOther(true);
            }

            if (userDetails.roleOther != null || userDetails.positionOther != null) {
                setShowRoleOther(true);
            }

        }
    }

    // get data
    const dataProviderUrl = process.env.REACT_APP_API_URL + "/api/user/dataProvider/userInfo";
    const [requestState, getUserDetailsData] = useRequest({
        url: dataProviderUrl,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,
        onSuccess: handleGetRegistration
    })
    const {isLoading, data, error} = requestState;


    // save data
    const saveDataProviderURL = process.env.REACT_APP_API_URL + "/api/user/dataProvider/registration";
    const [requestActionState, setDataProviderRegistration] = useRequest({
        url: saveDataProviderURL,
        method: "post",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError,
        onSuccess: handleSuccess
    })

    // memo for page load
    useMemo(() => {
        getUserDetailsData()
    }, []);

    // validation schema without email validation
    const schema = Yup.object().shape({
        affiliation: Yup.string().required("Please select an affiliation."),
        affiliationOther: Yup.string()
            .max(45, "Affiliation should be no longer than 45 characters")
            .when(['affiliation'], {
            is: (affiliation: string) => {
                if (affiliation === OTHER_OPTION) {
                    return true // validate and go to then function
                } else return false
            },
            then: (schema) =>
                schema.required("Please enter an affiliation.")
        }),

        organization: Yup.string().required("Please provide an organization.").matches(minimumOneCharRegExp, NO_CHARS_ERROR_MSG),
        role: Yup.string().required("Please select a role."),
        roleOther: Yup.string().max(45, "Role should be no longer than 45 characters.")
            .when(['role'], {
            is: (role: string) => {
                if (role === OTHER_OPTION) {
                    return true // validate and go to then function
                } else return false // for all other cases proData is not required
            },
            then: (schema) =>
                schema
                    .required("Please enter a role.")
        }),
        department: Yup.string().required("Please provide a department.").max(30, "Department should be no longer than 30 characters."),
        streetAddress: Yup.string().required("Please provide a street address.") .max(200, "Address should be no longer than 200 characters."),
        city: Yup.string().required("Please provide a city.").max(100, "City should be no longer than 100 characters."),
        state: Yup.string().required("Please provide a state.").max(100, "State should be no longer than 100 characters."),
        postalCode: Yup.string().required("Please provide a postal code.").max(12, "Postal code should be no longer than 12 characters."),
        country: Yup.string().required("Please select a country."),


    });


    // build select list
    const buildSelectList = (listType: FormList[]) => {
        let optionTemplate = listType.map(v => (
            <option key={v.id} value={v.value}>{v.label}</option>
        ));
        return optionTemplate;
    }

    // show other field when 'Other" selected from list
    const handleRoleChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowRoleOther(true);
        } else if (showRoleOther) {
            setShowRoleOther(false);
        }
    }

    // show other field when 'Other" selected from list
    const handleAffiliationChanged = (value: string) => {
        if (value === OTHER_OPTION) {
            setShowAffiliationOther(true);
        } else if (showAffiliationOther) {
            setShowAffiliationOther(false);
        }
    }


    // clear form fields
    const handleReset = () => {
        if (formikRef != null && formikRef.current) {
            formikRef.current.resetForm();
            reinitializeValues();
        }
    }

    // re-initialize values after clear
    const reinitializeValues = () => {
        setShowAffiliationOther(false);
        setShowRoleOther(false);
        setShowRegistrationMessage(false);
    }

    const buildForm = () => {
        return (
            <Formik innerRef={formikRef}
                    enableReinitialize
                    validationSchema={schema}
                    onSubmit={async (values) => {
                        setDataProviderRegistration(JSON.stringify({
                            affiliation: values.affiliation,
                            affiliationOther: getEncodedValue(values.affiliationOther),
                            organization: getEncodedValue(values.organization),
                            role: values.role,
                            roleOther: getEncodedValue(values.roleOther),
                            department: getEncodedValue(values.department),
                            linkedInURL: getEncodedValue(values.linkedInURL),
                            streetAddress: getEncodedValue(values.streetAddress),
                            city: getEncodedValue(values.city),
                            state: getEncodedValue(values.state),
                            postalCode: values.postalCode,
                            country: values.country,
                        }));
                    }}
                    initialValues={{
                        affiliation: userDetails?.sector ?? '',
                        affiliationOther: userDetails?.sectorOther ?? '',
                        organization: userDetails?.organization ?? '',
                        role: userDetails?.role ?? '',
                        roleOther: userDetails?.roleOther ?? '',
                        department: userDetails?.department ?? '',
                        linkedInURL: userDetails?.linkedinProfileURL ?? '',
                        streetAddress: userDetails?.address ?? "",
                        city: userDetails?.city ?? "",
                        state: userDetails?.state ?? "",
                        postalCode: userDetails?.postalCode ?? "",
                        country: userDetails?.country ?? "",
                    }}
                    validateOnChange={false}
                    validateOnBlur={false}>
                {({
                      handleSubmit,
                      handleChange,
                      resetForm,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                  }) => (
                    <Form className="form-layout" onSubmit={handleSubmit} noValidate={true}>
                        <FormLayoutColumn className="mt-5 mb-5" lg={12}>
                            <FormLayoutColumn lg={8}>
                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formAffiliation">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Affiliation </Form.Label>

                                        <Form.Select size="sm" required
                                                     name={"affiliation"}
                                                     value={values.affiliation}
                                                     isValid={touched.affiliation && !errors.affiliation}
                                                     onChange={e => {
                                                         handleAffiliationChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(AFFILIATION)}
                                        </Form.Select>

                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select an affiliation.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"affiliation"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showAffiliationOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formAffiliationOther">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Other
                                                Affiliation </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"affiliationOther"}
                                                          type="text"
                                                          value={values.affiliationOther}
                                                          disabled={disabled}
                                                          isValid={touched.affiliationOther && !errors.affiliationOther}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide an affiliation.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"affiliationOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} className="organization"
                                                controlId="formOrganization">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Organization </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"organization"}
                                                      type="text"
                                                      value={values.organization}
                                                      disabled={disabled}
                                                      isValid={touched.organization && !errors.organization}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide an organization name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"organization"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3 ">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formRole">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Role </Form.Label>

                                        <Form.Select size="sm" required
                                                     name={"role"}
                                                     value={values.role}
                                                     isValid={touched.role && !errors.role}
                                                     onChange={e => {
                                                         handleRoleChanged(e.target.value);
                                                         handleChange(e);
                                                     }}>
                                            {buildSelectList(ROLE)}
                                        </Form.Select>

                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a role
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"role"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={6}
                                                controlId="formDepartment">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Department </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"department"}
                                                      type="text"
                                                      value={values.department}
                                                      disabled={disabled}
                                                      isValid={touched.department && !errors.department}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a department name.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"department"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                {showRoleOther && (
                                    <Row className="mt-3 mb-3 ">
                                        <Form.Group as={Col} className={"otherFieldGroup"} lg={6}
                                                    controlId="formRoleOther">
                                            <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Other
                                                Role </Form.Label>
                                            <Form.Control size="sm" required
                                                          name={"roleOther"}
                                                          type="text"
                                                          value={values.roleOther}
                                                          disabled={disabled}
                                                          isValid={touched.roleOther && !errors.roleOther}
                                                          onChange={handleChange}/>
                                            <Form.Text className="text-muted"></Form.Text>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a role.
                                            </Form.Control.Feedback>
                                            <ErrorMessage name={"roleOther"}
                                                          render={msg => <div
                                                              className={"form-error-msg"}>{msg}</div>}/>
                                        </Form.Group>
                                    </Row>
                                )}

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col}
                                                controlId="formStreetAddress">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Street
                                            Address </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"streetAddress"}
                                                      type="text"
                                                      value={values.streetAddress}
                                                      disabled={disabled}
                                                      isValid={touched.streetAddress && !errors.streetAddress}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a street address.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"streetAddress"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={5} controlId="formCity">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">City </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"city"}
                                                      type="text"
                                                      value={values.city}
                                                      disabled={disabled}
                                                      isValid={touched.city && !errors.city}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a city
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"city"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={3} controlId="formState">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">State </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"state"}
                                                      type="text"
                                                      value={values.state}
                                                      disabled={disabled}
                                                      isValid={touched.state && !errors.state}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a state.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"state"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                    <Form.Group as={Col} lg={4} controlId="formPostalCode">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")} column="sm">Postal
                                            Code </Form.Label>
                                        <Form.Control size="sm" required
                                                      name={"postalCode"}
                                                      type="text"
                                                      value={values.postalCode}
                                                      disabled={disabled}
                                                      isValid={touched.postalCode && !errors.postalCode}
                                                      onChange={handleChange}/>
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a postal code.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"postalCode"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                                <Row className="mt-3 mb-3">
                                    <Form.Group as={Col} lg={6}
                                                controlId="formCountry">
                                        <Form.Label className={labelIfReadOnly(disabled, "required")}
                                                    column="sm">Country </Form.Label>
                                        {!disabled && (
                                            <Form.Select size="sm" required
                                                         name={"country"}
                                                         value={values.country}
                                                         isValid={touched.country && !errors.country}
                                                         onChange={handleChange}>
                                                {buildSelectList(COUNTRY)}
                                            </Form.Select>
                                        )}
                                        <Form.Text className="text-muted"></Form.Text>
                                        <Form.Control.Feedback type="invalid">
                                            Please select a country.
                                        </Form.Control.Feedback>
                                        <ErrorMessage name={"country"}
                                                      render={msg => <div
                                                          className={"form-error-msg"}>{msg}</div>}/>
                                    </Form.Group>
                                </Row>

                            </FormLayoutColumn>
                        </FormLayoutColumn>

                        <ClearAndSubmitButtonContainer handleReset={handleReset} enableClear={true}
                                                       submitButtonText={SUBMIT}/>

                    </Form>

                )}
            </Formik>
        )
    }


    return (
        <Container className="form-container" fluid>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"formLayoutColumn"}>

                        {showRegistrationMessage && (
                            <PDS_P_REGISTRATION_MSG>{registrationMsg}</PDS_P_REGISTRATION_MSG>
                        )}
                        {buildForm()}
                    </FormLayoutColumn>
                    {!disabled && (
                        <Col md={4} lg={3}>
                            <Aside needHelp={true} requiredFields={true}/>
                        </Col>
                    )}
                </PageWrapper>
            </ContentPage>

        </Container>
    );

}
