/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetsFormNCI
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 27Sep2023 sasjxa file created
 * 25Apr2024 sasjxa refactoring of methods and styled components
 \******************************************************************************/
import React, {useMemo, useState} from "react";

import {Col, Container, Row} from "react-bootstrap";
import {useRequest} from "../../helper/useRequest";
import styled from "styled-components";
import {COLORS} from "../../components/styled/StyleConstants";
import {IAccessData} from "../../model/donations/IAccessData";
import {
    AdminTableHeader,
    ContentPage,
    FormLayoutColumn,
    GridRow,
    PageWrapper
} from "../../components/styled/StyledComponents";
import axios, {AxiosError} from "axios";




const GridHeaderRow = styled(GridRow)` 
  font-weight: bold;
  background-color:  ${COLORS.filter_gray};
  padding: .5% 0;
  margin-top: 0;
`;

const GridCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  margin-left: 2%;
  padding-right: 0;
  padding-left: 0;
  width: 12%;
  
  
  span {
    color: ${COLORS.dark_grey_blue};
  }
`;

const GridColTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: ${COLORS.dark_grey_blue};
  width: 30%;
  
  span {
    display: block;
  }
`;


export const AdminDatasetsFormNCI: React.FC = () => {
    const [dashboardMsg, setDashboardMsg] = useState<string>("");

    const [donations, setDonations] = useState<IAccessData[]>([]);
    const [numDonations, setNumDonations] = useState<number>(0)
    const ICON_DONE:string= "/images/icons/icon_done.png"
    const NCI_DATA_MSG:string = "All required files have been uploaded"
    const buildError = (error: AxiosError) => {
        if (axios.isAxiosError(error) && error.response !== undefined) {
            setDashboardMsg(error.response.data.detail);
        } else {
            setDashboardMsg(error.toString);
        }
    }

    const dsaURL = process.env.REACT_APP_API_URL + "/api/accessdata/results";
    const [requestState, getDonations] = useRequest({
        url: dsaURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: buildError
    })

    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getDonations();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDonations(data);
        }
    }, [data])

    useMemo(() => {
        console.log('here in memo');
        setNumDonations(donations.length);
    }, [donations])


    const getStatusIcon = (donation:IAccessData) => {
      if (donation.isNci){
         return ICON_DONE;
      }
      else return "";
    }

    const buildHeaders = () => {
        return (
            <>
                <GridHeaderRow className="">
                    <GridColTitle className="">Title</GridColTitle>
                    <GridCol className="">DSA Status</GridCol>
                    <GridCol className="">Dataset Form</GridCol>
                    <GridCol className="">Files</GridCol>
                    <GridCol className="">Submitted</GridCol>
                    <GridCol className="">Approval</GridCol>
                </GridHeaderRow>
            </>
        )
    }

    const buildDatasetInfo = () => {
        if (donations?.length > 0) {
            return (
                <>
                    {donations?.map((donation: IAccessData) =>
                        <Col className="w-100">
                            <GridRow key={donation.donationId} className="mb-2 mt-1">
                                <GridColTitle className=" span-title ">
                                    <span>{donation.donationTitle}</span>
                                    <span>Unique Dataset ID: {donation.uniqueDatasetId}</span>
                                    <span>Clinical Trial ID(s):{donation.subHeader?.clinicalGovId?.join(", ")}</span>
                                </GridColTitle>
                                <GridCol>N/A</GridCol>
                                <GridCol>
                                    <span className="w-100 icon   text-center">
                                                <img className="mx-auto"
                                                     src={getStatusIcon(donation)}
                                                     alt="Waiting icon"
                                                     width="32" height="32"/>
                                                    </span>
                                    <span
                                        className="w-100 icon text-center">{NCI_DATA_MSG}</span>
                                </GridCol>
                                <GridCol><span className="w-100 icon   text-center">
                                                <img className="mx-auto"
                                                     src={getStatusIcon(donation)}
                                                     alt="Waiting icon"
                                                     width="32" height="32"/>
                                                    </span>
                                    <span
                                        className="w-100 icon text-center">{NCI_DATA_MSG}</span>
                                </GridCol>
                                <GridCol><span className="w-100 icon   text-center">
                                                <img className="mx-auto"
                                                     src={getStatusIcon(donation)}
                                                     alt="Waiting icon"
                                                     width="32" height="32"/>
                                                    </span>
                                    <span
                                        className="w-100 icon text-center">{NCI_DATA_MSG}</span>
                                </GridCol>
                                <GridCol>Approved</GridCol>
                            </GridRow>
                        </Col>
                    )}
                </>
            )
        }
    }


    // const buildSection = () => {
    //     return (
    //         <>
    //             <Row><AdminTableHeader className="mt-3">NCI Datasets</AdminTableHeader></Row>
    //             <Col className="mb-3 ">
    //                 {buildHeaders()}
    //                 {buildDatasetInfo()}
    //             </Col>
    //         </>
    //     )
    // }

    return (
        <Container className={"w-100 "}>
            <ContentPage name={"content-page"}>
                <PageWrapper name={"page-wrapper"}>
                    <FormLayoutColumn md={8} lg={9} name={"registrationColumn"}>



                    </FormLayoutColumn>
                </PageWrapper>
            </ContentPage>
        </Container>
    );

}
