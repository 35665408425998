/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: ClearAndSubmitButtonContainer
 *
 * Purpose: Button Container for Clear and Submit Buttons
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 23Jun2023 sasjxa  file created.
 * 21Jul2023 sasjxa  add method for clearing form
 * 11Aug2023 sasjxa  add height dimension to container
 * 31Oct2024 craig   Added spinner to submit button and added isSubmitting to props
 * 29Dec2024 craig   Removed console.log
 \******************************************************************************/
import React from "react";
import {ButtonRow, ClearButton, PDSSubmitButton} from "../styled/StyledComponents";
// @ts-ignore
import styled from "styled-components";
import {SIZES} from "../styled/StyleConstants";
import {Spinner} from "react-bootstrap";


const ButtonContainerWrapper = styled.div`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 2%;


    @media (min-width: 768px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0;
        height: 75px;
        padding-top: 2%;
    }

`;

const ButtonContainerBody = styled.div`
    max-width: ${SIZES.MAX_WIDTH};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 2%;
    padding-left: 2%;
    padding-bottom: 1%;
`;

const ButtonContainerMain = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

interface ClearAndSubmitContainerProps {
    submitButtonText: string,
    handleReset?: any,
    isSubmitting?: boolean,
    enableClear: boolean
}

export const ClearAndSubmitButtonContainer: React.FC<ClearAndSubmitContainerProps> =
    ({submitButtonText, handleReset, isSubmitting, enableClear}: ClearAndSubmitContainerProps) => {
        //console.log("is submitting = " + isSubmitting);
        return (
            <ButtonContainerWrapper className={"footer-wrapper "}>
                <ButtonContainerBody className={"footer-body"}>
                    <ButtonContainerMain className={"footer-main"}>
                        <ButtonRow>
                            {enableClear && (
                                <ClearButton variant="outline-light" className="btn-submit" type="reset"
                                             onClick={() => {
                                                 handleReset()
                                             }}>
                                    Clear
                                </ClearButton>
                            )}
                            <PDSSubmitButton variant="success" className="btn-submit" type="submit"
                                             disabled={isSubmitting}>
                                {isSubmitting &&
                                    <Spinner as={"span"} animation={"border"} size={"sm"} role={"status"}
                                             variant={"warning"}
                                             aria-hidden={"true"}/>
                                }
                                {submitButtonText}
                            </PDSSubmitButton>
                        </ButtonRow>
                    </ButtonContainerMain>
                </ButtonContainerBody>
            </ButtonContainerWrapper>
        )
    }


/**
 * set the default value for isSubmitting to false until we get all forms setup with the property
  */
ClearAndSubmitButtonContainer.defaultProps = {
    isSubmitting: false
};

