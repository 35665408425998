/******************************************************************************\
 * :$
 *
 * Copyright(c) 2022 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: useRequest
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 24Jun2022 craig File created.
 * 24May2024 craig Added PDSErrorResponse to handle messaging better
 * 27Sep2024 craig Added AxiosError to return error.
 \******************************************************************************/
import {useState} from 'react';
import axios, {AxiosError} from 'axios';

export interface PDSErrorResponse {
    title: string;
    detail: string;
}

type RequestConfig = {
    url?: string;
    baseURL?: string;
    initialIsLoading?: boolean;
    withCredentials?: boolean;
    requestType?: string;
    onSuccess?: Function;
    onError?: Function;
    method?: any;
    data?: any;
    headers?: any;
};

type ResponseState = {
    data: any;
    isLoading: boolean | undefined;
    error: object | null;
    settings: object;
};

type RequestFunction = any;
type RequestResult = [ResponseState, RequestFunction];

const defaultConfig: RequestConfig = {
    url: '',
    method: 'get',
    initialIsLoading: false,
    withCredentials: false,
    requestType: '',
    baseURL: process.env.REACT_APP_API_URL,
    onSuccess: Function.prototype,
    onError: Function.prototype,
    headers: {
        'Accept': "application/json",
        'Content-Type': "application/json",
        'Access-Control_Allow-Origin': "*",
        'Access-Control-Allow-Credentials': "true"
    },
};

export const useRequest = (config: Readonly<RequestConfig>): RequestResult => {
    const settings = {...defaultConfig, ...config};
    const {
        url,
        method,
        baseURL,
        initialIsLoading,
        onSuccess,
        onError,
        withCredentials,
        requestType,
        headers,
    } = settings;

    const [isLoading, setIsLoading] = useState<boolean | undefined>(
        initialIsLoading,
    );

    const [error, setError] = useState<AxiosError<PDSErrorResponse> | null>(null);
    const [data, setData] = useState<object | null>(null);

    const handleSuccessRequest = (serverData: any): void => {
        setIsLoading(false);

        if (serverData) {
            setError(null);
            setData(serverData);

            if (onSuccess) {
                onSuccess(serverData);
            }
        } else {
            if (onSuccess) {
                onSuccess();
            }
        }
    };

    const handleErrorRequest = (serverError: AxiosError<PDSErrorResponse>): void => {
        setIsLoading(false);

        if (serverError) {
            setError(serverError);

            if (onError) {
                onError(serverError);
            }
        }
    };

    const request = async (payload: any, options: object): Promise<any> => {
        try {
            setIsLoading(true);

            let requestConfig: RequestConfig = {
                baseURL,
                withCredentials,
                url,
                method,
                headers,
            };

            if (payload) {
                requestConfig = {...requestConfig, data: payload};
            }

            if (options) {
                requestConfig = {...requestConfig, ...options};
            }

            if (requestType) {
                requestConfig = {...requestConfig, requestType: requestType};
            }

            const {data: serverData} = await axios(requestConfig);


            /* TODO - CHANGE THIS SO THAT I CAN HAVE HEADERS FOR AUTH

                const response = await axios(requestConfig);
                const res = (await invokeAxios(lazyData)) as AxiosResponse<Data>;

                if (isMounted.current) {
                  dispatch({ type: 'REQUEST_SUCCESS', payload: res.data });
                }
             */

            handleSuccessRequest(serverData);
        } catch (error) {
            if (axios.isCancel(error)) {
                if (error instanceof Error) {
                    console.error('Request canceled by axios', error.message);
                }
            } else {
                if (axios.isAxiosError(error)) {
                    handleErrorRequest(error);
                }
            }
        }
    };


    const state: ResponseState = {data, isLoading, error, settings};

    return [state, request];
};

