/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: StyledComponents
 *
 * Purpose:
 *
 * Author: craig (Craig.Simpson@sas.com), sasjxa (Jennifer.Appetta@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 14Feb2023 craig   file created.
 * 18May2023 sasjxa  add additional components
 * 23May2023 sasjxa  add component for text area count
 * 15Jun2023 sasjxa  add padding to button row
 * 20Jun2023 sasjxa  add button that appears as link w/o hover color
 * 14Apr2024 craig   add PDS_DIV to fix a couple of errors in the console
 * 08Aug2024 sasjxa  add bottom padding to grid rows
 * 07Nov2024 sasjxa  add roundFileSize
 * 14Nov2024 sasjxa  add logic to roundFile for empty file
 * 06Dec2024 sasjxa  add components for admin edit
 * 07Dec2024 sasjxa  add components for admin site messages
 \******************************************************************************/
import {Button, Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "./StyleConstants";
import {NavLink} from "react-router-dom";

export const StdContainer = styled(Container)`
    max-width: 1440px;
    width: 100%;
`;

export const FullContainer = styled(Container)`
    width: 100%;
    padding: 0 0 1rem 0;
    background-color: white;
`;

export const ContentWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const ContentPage = styled(Col)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;


export const ContentContainer = styled(Row)`
    padding-left: 2%;
    padding-right: 2%;
`;

export const PageWrapper = styled(Col)`
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    padding-left: 2%;
    padding-right: 2%;
    height: 100%;

    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: space-between;
        padding-right: 0;
    }
`;

export const PageContents = styled(Col)`
    flex: 1;
    padding-bottom: 2rem;
    margin-right: 1rem;
`;

export const ButtonRow = styled(Row)`
    justify-content: flex-end;
    padding-bottom: 3rem;
    border-top: 1pt solid ${COLORS.access_data_gray};;
    padding-top: 1.375rem;
`;


export const BasicSubmitButton = styled(Button)` {
    text-align: center;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 0;
    border: 0;
    width: 155px;
    height: 2.25rem;
}
`;

export const ClearButton = styled(BasicSubmitButton)`
    color: ${COLORS.access_data_gray};

    :hover {
        background-color: ${COLORS.white};
        color: ${COLORS.access_data_gray};
    }
`;

export const BackButton = styled(BasicSubmitButton)`
`;


export const LinkButton = styled(Button)`
    :hover, :active:focus {
        background-color: ${COLORS.white};
    }
`;

export const PDSSubmitButton = styled(BasicSubmitButton)`
    color: ${COLORS.white};
    background-color: ${COLORS.turtle_green};

    :hover {
        background-color: ${COLORS.hover_green};
        color: ${COLORS.white};
    }

    @media (min-width: 380px) {
        margin-bottom: 2%;
    }

    @media (min-width: 768px) {
        margin-left: 1%;
        margin-top: 0;
    }
`;

export const ShareDataButtonContainer = styled.div`
    border-top: 1px solid ${COLORS.dark_grey_blue};


    @media (min-width: 380px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 2%;
    }
`;

export const ShareDataButtonGroup = styled.div`
    @media (min-width: 380px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1%;
    }

    @media (min-width: 568px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

    }

    @media (min-width: 1024px) {
        margin-top: 0;
    }
`;

export const UserAgreementTitle = styled.h6`
    font-weight: 600;
    color: ${COLORS.cerulean};
`;

export const UserAgreementRow = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 0;
    padding-right: 0;
`;

export const PDS_Button = styled(Button)`
    font-weight: 700;
    font-size: 14pt !important;
    letter-spacing: 0.25px;
    line-height: 1.2rem;
    padding: 12px 46px;
    min-width: 70px;
    max-width: 475px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    margin: 0px 10px;
    border: 0;
    outline: 0;
    display: inline-block;
`;

export const TextAreaCountRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        font-size: .875rem;
        color: ${COLORS.dark_grey_blue};
    }
`;

export const PDS_DIV = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: ${COLORS.dark_grey_blue};
`;


export const PDS_P = styled.p`
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: ${COLORS.dark_grey_blue};
`;

export const PDS_P_BOLD = styled(PDS_P)`
    font-weight: 600;
`;

export const PDS_SPAN = styled.span`
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5;
    font-weight: 400;
    font-size: 1rem;
    color: ${COLORS.dark_grey_blue};
`;

export const PDS_SPAN_BOLD = styled(PDS_SPAN)`
    font-weight: 600;
`;

export const PDS_H5 = styled.h5`
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: .25rem;
    color: ${COLORS.cerulean};
`;

export const PageHeaderWithBackground = styled.div`
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;

    padding-right: 0;
    padding-left: 0;
    padding-top: 2rem;
    margin-right: 0;

    h2 {
        font-weight: 600;
        font-size: 1.375rem;
        color: ${COLORS.dark_grey_blue};
        margin: 0;
    }
`;

export const FormLayoutColumn = styled(Col)`
    margin: 2% auto;
`;

export const FormSectionTitle = styled.h6`
    font-weight: 600;
    color: ${COLORS.cerulean};
`;

export const AsideWrapper = styled(Col)`
    border-top: 2px solid ${COLORS.light_grey};
    margin-top: 1%;

    @media (min-width: 768px) {
        height: 100%;
        margin-top: 2%;
        border-left: 2px solid ${COLORS.light_grey};
        border-top: 0;
        margin-left: 2px;

    }

    @media (min-width: 1024px) {
        height: 100%;
        margin-left: 2%;
        margin-top: 5%;
    }
`;

export const AsideSection = styled(Row)`
    margin-bottom: 5%;

    h5:first-of-type {
        margin-top: 2%;
    }

    p:first-of-type {
        margin-top: 0.05rem;
    }


    a {
        color: ${COLORS.turtle_green};
    }

    @media (min-width: 768px) {
        margin-bottom: 3%;
        margin-left: 2px;
        h5:first-of-type {
            margin-top: 2%;
        }
    }

    @media (min-width: 1024px) {
        margin-bottom: 7%;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const ResourceItemPage = styled.div`
    margin-bottom: 3%;
    padding: 0;
    margin-top: 2%;
`;

export const DialogContent = styled(PDS_P)`
    font-size: .8125rem;
`;

export const DialogHeader = styled(DialogContent)`
    font-weight: bold;
`;

export const GridRow = styled(Row)`
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: .5%;
    
    width: 100%;

    a {
        color: ${COLORS.cerulean};
        padding-left: 0;
    }
`;

export const AdminTableHeader = styled.h6`
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
`;

export const SectionHeader = styled.h6`
    font-weight: bold;
    color: ${COLORS.cerulean};
`;

export const Ul_disc = styled.ul`
    list-style-type: disc;
`;

export const NavMenuItem = styled(NavLink)`
    //padding-left: 20px;
    padding-right: 20px;
    text-decoration: underline;
    font-size: ${FONT_SIZES.px14};
    font-weight: 400;
    color: ${COLORS.cerulean} !important;   
`;

export const UserInfoCol = styled.div`
    display: inline;   
    
    img {
        padding-right:10px;
        text-align: center;
        margin-bottom: 1px;
    }
`;

export const AdminEditRow = styled.div`
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin-top: 0;

    font-size: .875rem;

    span {
        padding-right: 0;
        padding-bottom: 0;
        padding-top: 0;
    }

    span.bold {
        font-weight: bold;
    }

    button {
        padding-top: 0;
        padding-left: 1%;
        padding-bottom: 0;
        color: ${COLORS.cerulean};
        font-weight: bold;
    }
`


export const AdminEditableRow = styled(AdminEditRow)`
    background-color: ${COLORS.light_grey} !important;
`;

export const AdminEditDisplayCol = styled(Col)` 
     background-color: ${COLORS.white} !important;
     margin: 0;
     padding: 0;
`;

export const AnnouncementsMenuItem = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 700;
    height: 2rem;
    line-height: 2rem;
    text-decoration: none;

    :hover {
        background-color: ${COLORS.hover_green};
        color: ${COLORS.white};
    }

    background-color: ${COLORS.turtle_green};
    color: ${COLORS.white};

`;


export const NewAnnouncementButtonRow = styled(Row)`
    color: ${COLORS.dark_grey_blue};
    margin: 3% 0;
    justify-content: center;
`;



export const roundFileSize = (fileSize: number | undefined) => {
    if (fileSize == null){
        return "0 KB";
    }

    if (fileSize < 1000) {
        return fileSize + ' B'
    } else {
        let dividedFileSize: number = fileSize / 1000;
        let roundedFileSize: number = Math.trunc(Math.round(dividedFileSize));
        return roundedFileSize + ' KB';
    }
}
