/******************************************************************************\
 * :$
 *
 * Copyright(c) 2023 SAS Institute Inc., Cary, NC, USA. All Rights Reserved.
 *
 * Name: AdminDatasetInfo
 *
 * Purpose: display dataset info
 *
 * Author: craig (Craig.Simpson@sas.com)
 *
 * Support: SAS(r) Solutions OnDemand
 *
 * Input:
 *
 * Output:
 *
 * Parameters: (if applicable)
 *
 * Dependencies/Assumptions:
 *
 * Usage:
 *
 * History:
 * ddmmmyyyy userid description (Change Code)
 * 05Nov2024 sasjxa  file created
 * 02Nov2023 craig   Added call to API
 * 12Mar2024 sasjxa  encode/decode special chars (MPM-5389)
 * 04Apr2024 craig  Switched to react-error-boundary
 * 11Apr2024 sasjxa display spinner for loading data (MPM-5467)
 * 25Apr2024 sasjxa refactoring of methods and styled components
 * 02Jul2024 craig  removed decoding (moved to api)
 * 14Nov2024 sasjxa add ability to modify files
 * 22Dec2024 sasjxa add desc value for add action
 \******************************************************************************/
import React, {useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {useRequest} from "../../helper/useRequest";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {roundFileSize,} from "../../components/styled/StyledComponents";
import {useErrorBoundary} from "react-error-boundary";
import styled from "styled-components";
import {COLORS, FONT_SIZES} from "../../components/styled/StyleConstants";
import {IShareDataDashboard} from "../../model/share/IShare";
import {IFile} from "../../model/donations/IAccessData";
import {PDS_Button} from "../../components/styled/Styles";
import {AdminFileEditDialog} from "../../components/dialogs/AdminFileEditDialog";
import {
    DATA_DESC,
    DATA_DICTIONARY_DESC,
    DATA_SUMMARY_DESC,
    IMG_PATH,
    NOT_APPLICABLE,
    ZIP_DESC
} from "../../data/formList";
import {AdminFileDeleteDialog} from "../../components/dialogs/AdminFileDeleteDialog";

const AdminTableHeader = styled.h6`
    font-weight: bold;
    color: ${COLORS.dark_grey_blue};
`;

const GridRow = styled.div`
    display: flex;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    margin-top: .5%;

    font-size: ${FONT_SIZES.px14};


    a {
        color: ${COLORS.dark_grey_blue};
    }

    a.approval {
        color: ${COLORS.cerulean};
    }

`;

const AddFileButton = styled(PDS_Button)`
    font-weight: bold;
    background-color: ${COLORS.turtle_green};

    margin-left: 0;

    :hover, :active:focus {
        background-color: ${COLORS.hover_green};
        color: ${COLORS.white};
    }
`;


export const AdminDatasetFileInfo: React.FC = () => {
    const {showBoundary} = useErrorBoundary();
    const {donationId} = useParams();
    const [showFileDialog, setShowFileDialog] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [title, setTitle] = useState<string>();
    const [filename, setFilename] = useState<string>("");
    const [fileDesc, setFileDesc] = useState<string>("");
    const [fileType, setFileType] = useState<string>("");
    const [fileId, setFileId] = useState<number>(-1);
    const [dashboard, setDashboard] = useState<IShareDataDashboard>();
    // const [selectedFile, setSelectedFile] = useState<IFile>();
    const ADD_FILE = "Add Data File to Contribution";
    const REPLACE_FILE = "Replace File for Contribution";


    const DELETE = "Delete";


    // handle error - unsuccessful retrieve of data
    const handleError = (error: object) => {
        showBoundary(error);
    }


    // get data
    const metadataURL = process.env.REACT_APP_API_URL + "/api/admin/donations/datasets/" + donationId;
    const [requestState, getDonation] = useRequest({
        url: metadataURL,
        method: "get",
        withCredentials: true,
        initialIsLoading: true,
        onError: handleError,

    })
    const {isLoading, data, error} = requestState;

    useMemo(() => {
        getDonation();
    }, [])

    useMemo(() => {
        if (data != null) {
            setDashboard(data);
        }
    }, [data])


    // close dialog, get updated donation
    const handleFileChanged = () => {
        setShowFileDialog(false);
        setShowDeleteDialog(false);
        getDonation();
    }

    const handleReplaceAction = (file: IFile) => {
        setTitle(REPLACE_FILE);
        setFileId(file.id);
        setFilename(file.filename);
        setFileDesc(file.description);
        setFileType(file.fileType);
        setShowFileDialog(!showFileDialog);
    }

    const handleAddAction = () => {
        setTitle(ADD_FILE);
        setFilename("");
        setFileDesc("DATA");
        setFileId(-1);
        setShowFileDialog(!showFileDialog);
    }

    const handleDeleteAction = (file: IFile) => {
        setFileId(file.id);
        setFilename(file.filename);
        setShowDeleteDialog(!showDeleteDialog);
    }

    const toggleFileDialog = () => {
        setShowFileDialog(!showFileDialog);
    }

    const toggleDeleteDialog = () => {
        setShowDeleteDialog(!showDeleteDialog);
    }



    // return boolean indicating whether file can be deleted
    const fileCanBeDeleted = (file: IFile) => {
        if (file?.description === DATA_DICTIONARY_DESC) {
            return NOT_APPLICABLE;
        } else if (file?.description === DATA_DESC || file?.description === DATA_SUMMARY_DESC ||
            file?.description === ZIP_DESC) {
            // must have more than one data file
            let item: IFile[] | undefined = dashboard?.files.filter(s => s.description === DATA_DESC ||
                s.description === DATA_SUMMARY_DESC || s.description === ZIP_DESC);
            if (item?.length && item.length > 1) {
                return (
                    <img className="mx-auto pe-2"
                         src={IMG_PATH + "delete.png"} title="Delete"
                         onClick={() => {
                             {
                                 handleDeleteAction(file);
                             }
                         }}/>
                )
            } else {
                return NOT_APPLICABLE;
            }
        } else {
            return (
                <img className="mx-auto pe-2"
                     src={IMG_PATH + "delete.png"} title="Delete"
                     onClick={() => {
                         {
                             handleDeleteAction(file);
                         }
                     }}/>
            )

        }
    }

    const buildHeaders = () => {
        if (dashboard != null) {
            return (
                <>
                    <AdminTableHeader className="pb-4 w-100 ">{dashboard.title}</AdminTableHeader>
                    <GridRow className=" ">
                        <Col className="grid-header grid-entry space-5">Filename</Col>
                        <Col className="grid-header grid-entry ">Size</Col>
                        <Col className="grid-header grid-entry space-3">File Type</Col>
                        <Col className="grid-header grid-entry space-2">Description</Col>
                        <Col className="grid-header grid-entry">Replace</Col>
                        <Col className="grid-header grid-entry">Delete</Col>
                    </GridRow>
                    {dashboard.files?.map((file: IFile) => (
                        <GridRow className=" ">
                            <Col className=" grid-entry space-5">{file.filename}</Col>
                            <Col className=" grid-entry ">{roundFileSize(file.fileSize)}</Col>
                            <Col className=" grid-entry space-3">{file.fileType}</Col>
                            <Col className=" grid-entry space-2">{file.description}</Col>
                            <Col className=" grid-entry">
                                <img className="mx-auto pe-2"
                                     src={IMG_PATH + "export.png"} title="Replace"
                                     onClick={() => {
                                         {
                                             handleReplaceAction(file);
                                         }
                                     }}/>
                            </Col>
                            <Col className=" grid-entry">{fileCanBeDeleted(file)}</Col>
                        </GridRow>
                    ))}

                    <AddFileButton className={"mt-2 mb-4"}
                                   onClick={() => handleAddAction()}
                    >Add File To Contribution</AddFileButton>


                </>
            )
        }
    }

    const buildSection = () => {
        if (dashboard != null) {
            return (
                <>
                    <Col className=" ">
                        {buildHeaders()}
                    </Col>


                </>
            )
        }
    }


    return (
        <Container className={"w-100 "}>
            <Row className={"mt-3  "}></Row>
            <AdminFileEditDialog donationId={donationId} dashboard={dashboard} title={title}
                                 fileId={fileId} filename={filename} fileDesc={fileDesc} fileType={fileType}
                                 handleFileChange={handleFileChanged} show={showFileDialog}
                                 showHide={toggleFileDialog}/>
            <AdminFileDeleteDialog donationId ={donationId} fileId={fileId} filename={filename}
                                   show={showDeleteDialog} showHide={toggleDeleteDialog} submitAction={handleFileChanged}/>

            {isLoading &&
                <Spinner className="spinner-center" animation={"border"} variant={"primary"} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
            {error && <p>Error</p>}
            {data && buildSection()}
        </Container>
    )
}

